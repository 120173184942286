import { setToken } from '../authentication';
import { axiosClientApi } from '../../axiosClientConfiguration';
import { getRequest, postRequest, putRequest } from '../../axiosFunctions';

const requestData = {
  id: undefined,
  requesterUserId: undefined,
  comment: undefined,
  dateStart: undefined,
  dateEnd: undefined
}

const getSingle = (token, id) => {
  return getRequest(
    axiosClientApi,
    `/Vacations/Get/${id}`,
    setToken(token)
  );
}

const getList = (token, filters) => {
  let config = setToken(token);
  config.params = {
    code: filters.code,
    requesterUserId: filters.requesterUserId,
    statusId: filters.statusId,
  };
  return getRequest(
    axiosClientApi,
    "/Vacations/List",
    config
  );
}

const postSingle = (token, data) => {
  return postRequest(
    axiosClientApi,
    "/Vacations/Register",
    data,
    setToken(token)
  );
}

const putApprove = (token, data) => {
  return putRequest(
    axiosClientApi,
    "/Vacations/Approve",
    data,
    setToken(token)
  );
}

const putReject = (token, data) => {
  return putRequest(
    axiosClientApi,
    "/Vacations/Reject",
    data,
    setToken(token)
  );
}

export const Vacations = {
  requestData,
  getSingle,
  getList,
  postSingle,
  putApprove,
  putReject,
};
