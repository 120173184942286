import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/dist";
import {
    PlusCircle,
    HelpCircle
} from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Book, Edit, Trash2 } from "react-feather";
import CustomPagination from "../../components/customPagination";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setMenuList, setShowRemoteResponseToast } from "../../../core/redux/action";
import { showInternalErrorAlert } from "../../components/customAlert";
import { all_routes } from "../../../Router/all_routes";
import ToolsHead from "../../components/toolsHead";
import { Menus } from "../../../services/internal/menus";
import { MdDeliveryDining, MdPointOfSale } from "react-icons/md";

const MenuGrid = () => {

    const dataSource = useSelector((state) => state.menuList);
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loading);
    const token = useSelector((state) => state.token);
    const [filterName, setFilterName] = useState("");
    const route = all_routes;

    const renderSearchTooltip = (props) => (
        <Tooltip id="search-tooltip" {...props}>
            Ingresa el nombre del menú
        </Tooltip>
    );

    const onChangeName = (e) => {
        setFilterName(e.target.value.trim())
    }

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure(err.response.data));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadMenuList = async () => {

        let filters = {};
        filters.enabled = true;
        if (filterName) {
            filters.name = filterName;
        }

        try {
            dispatch(fetchDataRequest());
            const response = await Menus.getList(token, filters);
            dispatch(setMenuList(response.data.data));
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onDeleteItem = async (id) => {
        try {
            dispatch(fetchDataRequest());
            const response = await Menus.deleteSingle(token, id);
            dispatch(fetchDataSuccess(response.data.success));
            dispatch(setShowRemoteResponseToast(true));
        }
        catch (err) {
            handleException(err);
        }
        await onLoadMenuList();
    }

    const showDeleteItemAlert = async (index, text) => {
        const result = await MySwal.fire({
            title: "¿Estás seguro?",
            html: `Vamos a eliminar ${text}, esta acción es <strong>irreversible</strong>.`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Confirmar",
            customClass: {
                confirmButton: "btn btn-warning",
            },
        });

        if (result.isConfirmed) {
            await onDeleteItem(index.id);
        } else {
            MySwal.close();
        }
    };

    useEffect(() => {
        if (dataSource.length === 0) {
            onLoadMenuList();
        }
    }, []);

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Menús</h4>
                            <h6>Puedes gestionar los menús registrados en el sistema.</h6>
                        </div>
                    </div>
                    <ToolsHead onReload={onLoadMenuList} />
                    <div className="page-btn">
                        <Link
                            className="btn btn-added"
                            to={route.menu}
                        >
                            <PlusCircle className="me-2" />
                            Nuevo Menú
                        </Link>
                    </div>
                </div>
                {/* /product list */}
                <div className="card">
                    <div className="card-body pb-0">
                        <div className="table-top table-top-new">
                            <div className="search-set mb-0">
                                <div className="total-employees">
                                    <h6>
                                        <Book />
                                        Total de menús <span>{dataSource.length}</span>
                                    </h6>
                                </div>
                                <div className="search-input">
                                    <input
                                        type="text"
                                        placeholder="Búsqueda rápida"
                                        className="form-control form-control-sm formsearch"
                                        onChange={e => onChangeName(e)}
                                        onBlur={onLoadMenuList}
                                        disabled={loading}
                                    />
                                    <a className="btn btn-searchset">
                                        <i data-feather="search" className="feather-search" />
                                    </a>
                                </div>
                                <OverlayTrigger placement="top" overlay={renderSearchTooltip}>
                                    <a className="link-primary">
                                        <HelpCircle />
                                    </a>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className="employee-grid-widget">
                            <div className="row">
                                {dataSource.map((item) => (
                                    <div key={item.id} className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                                        <div className="employee-grid-profile">
                                            <div className="row mb-3">
                                                <div className="col-auto">
                                                    <span className={item.enabled ? "badge badge-linesuccess" : "badge badge-linedanger"}>
                                                        {item.enabled ? "Activo" : "Inactivo"}
                                                    </span>
                                                </div>
                                                <div className="col text-end">
                                                    <Link
                                                        className="text-blue me-2"
                                                        to={route.menu + "?code=" + item.id}
                                                    >
                                                        <Edit size={25} />
                                                    </Link>
                                                    <a
                                                        className="text-danger"
                                                        onClick={() => showDeleteItemAlert(item, "el menú")}
                                                    >
                                                        <Trash2 size={25} />
                                                    </a>
                                                </div>
                                            </div>
                                            <Link
                                                to={route.menu + "?code=" + item.id}
                                            >
                                                <div className="profile-info department-profile-info">

                                                    <ImageWithBasePath
                                                        src={item.imagePath ? item.imagePath : "assets/img/products/menu.jpg"}
                                                        alt="menu"
                                                    />
                                                </div>
                                            </Link>
                                            <div className="profile-head">
                                                <div className="container">
                                                    <h3>{item.name}</h3>
                                                </div>
                                                {item.platformId === 1 && <MdPointOfSale size={40} />}
                                                {item.platformId === 2 && <MdDeliveryDining size={40} />}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <CustomPagination totalNumber={dataSource.length} rowsNumber={dataSource.length} onClickPage={() => { }} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MenuGrid;
