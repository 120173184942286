import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Modal, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import ChangeCalculator from "../../../feature-module/components/changeCalculator";
import { BsCash } from "react-icons/bs";
import ImageWithBasePath from "../../img/imagewithbasebath";

const CashModal = ({ id, show = false, onHide, method, totalAmount, onSubmit }) => {

    const loading = useSelector((state) => state.loading);
    const [changeAmount, setChangeAmount] = useState("");

    const onCashChange = (change) => {
        setChangeAmount(change);
    }

    const onModalHide = () => {
        onHide();
    }

    return (
        <Modal show={show} centered onHide={onModalHide} id={id} backdrop="static" keyboard={false}>
            <Modal.Header className="custom-modal-header" closeButton={!loading}>
                <h4>{method?.label}</h4>
            </Modal.Header>
            <Modal.Body className="text-center">
                <Modal.Title className='justify-content-center mb-4'>
                    {method
                        ? <ImageWithBasePath
                            src={method?.image}
                            style={{ width: 200, backgroundColor: method?.backgroundColor, padding: 5 }}
                            alt="Method"
                        />
                        : <BsCash size={88} color="#a5dc86" />
                    }
                </Modal.Title>
                <ChangeCalculator totalAmount={totalAmount} onCashCallback={onCashChange} />
                <p>No olvides entregar todos los productos de la orden.</p>
            </Modal.Body>
            <Modal.Footer className='justify-content-center'>
                <button
                    className="btn btn-cancel me-2"
                    onClick={(e) => onModalHide(e, false)}
                    disabled={loading}
                >
                    Regresar
                </button>
                {
                    loading ?
                        <button disabled={loading} className="btn btn-submit">
                            <Spinner animation="border" role="status" size="sm" />
                        </button>
                        :
                        <button
                            className={method ? method?.className : "btn btn-primary"}
                            onClick={() => onSubmit()}
                            disabled={(changeAmount < 0 || changeAmount === "") || loading}>
                            Procesar Orden
                        </button>
                }

            </Modal.Footer>
        </Modal>
    );
};

CashModal.propTypes = {
    id: PropTypes.string.isRequired,
    show: PropTypes.bool,
    method: PropTypes.object,
    onHide: PropTypes.func,
    onSubmit: PropTypes.func,
    totalAmount: PropTypes.number.isRequired,
};


export default CashModal;
