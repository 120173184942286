import { setToken } from '../authentication';
import { axiosClientApi } from '../../axiosClientConfiguration';
import { deleteRequest, getRequest, postRequest, putRequest } from '../../axiosFunctions';

const requestData = {
  id: undefined,
  name: undefined,
  enabled: undefined,
  imagePath: undefined,
  products: [
    {
      productId: undefined,
      quantity: undefined,
      subtotal: undefined
    }
  ],
  categories: [
    {
      categoryId: undefined,
      maxQuantity: undefined,
      minQuantity: undefined,
      subtotal: undefined
    }
  ]
}

const getSingle = (token, id) => {
  return getRequest(
    axiosClientApi,
    `/Package/Get/${id}`,
    setToken(token)
  );
}

const getList = (token, filters) => {
  let config = setToken(token);
  config.params = {
    id: filters.id,
    name: filters.name,
    enabled: filters.enabled
  };
  return getRequest(
    axiosClientApi,
    "/Package/List",
    config
  );
}

const postSingle = (token, data) => {
  return postRequest(
    axiosClientApi,
    "/Package",
    data,
    setToken(token)
  );
}

const putSingle = (token, data) => {
  return putRequest(
    axiosClientApi,
    "/Package",
    data,
    setToken(token)
  );
}

const deleteSingle = (token, id) => {
  return deleteRequest(
    axiosClientApi,
    `/Package/Delete/${id}`,
    setToken(token)
  );
}

export const Package = {
  requestData,
  getSingle,
  getList,
  postSingle,
  putSingle,
  deleteSingle,
};
