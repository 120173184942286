export const StockTransferData = [
    {
      select: false,
      fromWarehouse: "Lobar Handy",
      toWarehouse: "Selosy",
      noOfProducts: 4,
      quantityTransferred: 14,
      refNumber: "TL145445",
      date: "25 Jul 2023",
      status: "Pendiente",
      cost: 223.00
    },
    {
      select: false,
      fromWarehouse: "Quaint Warehouse",
      toWarehouse: "Logerro",
      noOfProducts: 21,
      quantityTransferred: 10,
      refNumber: "TL135478",
      date: "28 Jul 2023",
      status: "Enviado",
      cost: 1578.00
    },
    {
      select: false,
      fromWarehouse: "Traditional Warehouse",
      toWarehouse: "Vesloo",
      noOfProducts: 15,
      quantityTransferred: 14,
      refNumber: "TL145124",
      date: "24 Jul 2023",
      status: "En transito",
      cost: 667.00
    },
    {
      select: false,
      fromWarehouse: "Cool Warehouse",
      toWarehouse: "Crompy",
      noOfProducts: 14,
      quantityTransferred: 74,
      refNumber: "TL474541",
      date: "15 Jul 2023",
      status: "Entregado",
      cost: 5411.00
    }
  ]
  