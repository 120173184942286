export const unitsdata = [
	{
		"id": 1,
		"unit": "Pieza",
		"shortname": "PZ",
		"noofproducts": "25",
		"createdon": "25 May 2023",
		"status": "Active",
		"name": "Ajuste de Inventario",
		"desc": "Ajuste de Inventario"
	},
	{
		"id": 2,
		"unit": "Kilogramo",
		"shortname": "KG",
		"noofproducts": "15",
		"createdon": "24 Jun 2023",
		"status": "Active",
		"name": "Merma",
		"desc": "Merma"
	},
	{
		"id": 3,
		"unit": "Gramo",
		"shortname": "G",
		"noofproducts": "47",
		"createdon": "23 Jul 2023",
		"status": "Active",
		"name": "Mal Estado",
		"desc": "Mal Estado"
	},
	{
		"id": 4,
		"unit": "Metro",
		"shortname": "M",
		"noofproducts": "28",
		"createdon": "22 Ago 2023",
		"status": "Active",
		"name": "Faltante Rejillas",
		"desc": "Faltante Rejillas"
	},
	{
		"id": 5,
		"unit": "Litro",
		"shortname": "L",
		"noofproducts": "47",
		"createdon": "21 Sep 2023",
		"status": "Active",
		"name": "Anulación",
		"desc": "Anulación"
	},
	{
		"id": 6,
		"unit": "Kilo",
		"shortname": "K",
		"noofproducts": "74",
		"createdon": "20 Sep 2023",
		"status": "Active",
		"name": "Error de Captura",
		"desc": "Error de Captura"
	}
]