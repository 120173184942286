import React from "react";
import { components } from "react-select";
import PropTypes from 'prop-types';
import variables from "../../../style/scss/utils/_variables.scss";
import { Filter } from "react-feather";

const FilterControl = ({ children, ...props }) => {
    return (
        <components.Control {...props}>
            <span style={{ marginLeft: '10px' }}><Filter size={15} color={variables.primaryColor} /></span>
            {children}
        </components.Control>
    );
};

FilterControl.propTypes = {
    children: PropTypes.array
};

export default FilterControl;