import React, { useEffect, useState } from 'react'
import ImageWithBasePath from '../../core/img/imagewithbasebath'
import { useBarcode } from 'next-barcode';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setCategoryOptions, setShowRemoteResponseToast, setUnitOptions } from '../../core/redux/action';
import { showInternalErrorAlert } from '../components/customAlert';
import { Articles } from '../../services/internal/articles';
import { useSearchParams } from 'react-router-dom';
import ToolsHead from '../components/toolsHead';
import { Catalogs } from '../../services/internal/catalogs';
import { formatearMoneda } from '../../core/utils';
import { Carousel } from 'react-bootstrap';
import FormCreatedBy from '../components/createdByForm';

const ProductDetail = () => {

    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const token = useSelector((state) => state.token);
    const categoryOptions = useSelector((state) => state.categoryOptions);
    const unitOptions = useSelector((state) => state.unitOptions);
    const codeParam = searchParams.get('code');
    const [dataItem, setDataItem] = useState(null);
    const { inputRef } = useBarcode({
        value: dataItem?.code,
        options: {
            //format: "EAN13",
        }
    });

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure(err.response.data));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadItem = async () => {
        try {
            let data = Articles.requestData;
            data = {};
            data.id = Number(codeParam);
            if (isNaN(data.id)) {
                return;
            }

            dispatch(fetchDataRequest());

            if (categoryOptions.length === 0) {
                const responseC = await Catalogs.getOptions(token, Catalogs.Name.Categories);
                dispatch(setCategoryOptions(responseC.data.data));
            }
            if (unitOptions.length === 0) {
                const responseU = await Catalogs.getOptions(token, Catalogs.Name.Unmeds);
                dispatch(setUnitOptions(responseU.data.data));
            }

            const response = await Articles.getSingle(token, codeParam);

            setDataItem(response.data.data);
            //console.log(response)
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    useEffect(() => {
        if (codeParam) {
            onLoadItem();
        }
    }, [codeParam]);

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="page-title">
                        <h4>Ficha de Producto</h4>
                        <h6>Información completa del producto.</h6>
                    </div>
                    <ToolsHead showExportData={true} showPrint={true} onReload={onLoadItem} />
                </div>
                {/* /add */}
                <div className="row">
                    <div className="col-lg-8 col-md-12 col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center">
                                        <div className="bar-code-view">
                                            <ImageWithBasePath src="assets/img/logo.png" alt="img" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center">
                                        <div className="bar-code-view">
                                            <svg ref={inputRef} />
                                        </div>
                                    </div>
                                </div>
                                <div className="productdetails mb-4">
                                    <ul className="product-bar">
                                        <li>
                                            <h4>Producto</h4>
                                            <h6 className="text-primary fw-bold">{dataItem?.name} </h6>
                                        </li>
                                        <li>
                                            <h4>Categoría</h4>
                                            <h6 className="text-secondary fw-semibold">{categoryOptions.find((i) => i.value === dataItem?.categoryId)?.label}</h6>
                                        </li>
                                        <li>
                                            <h4>Unidad de medida</h4>
                                            <h6>{unitOptions.find((i) => i.value === dataItem?.unmedId)?.label}</h6>
                                        </li>
                                        <li>
                                            <h4>Código</h4>
                                            <h6 className="text-orange">{dataItem?.code}</h6>
                                        </li>
                                        <li>
                                            <h4>Precio de compra</h4>
                                            <h6 className="text-info">{formatearMoneda(dataItem?.buyPrice)}</h6>
                                        </li>
                                        <li>
                                            <h4>Precio de venta</h4>
                                            <h6 className="text-info">{formatearMoneda(dataItem?.salePrice)}</h6>
                                        </li>
                                        <li>
                                            <h4>Afecta inventario</h4>
                                            <h6>{dataItem?.inventoried ? "Sí" : "No"}</h6>
                                        </li>
                                        <li>
                                            <h4>Resultado de receta</h4>
                                            <h6>{dataItem?.recipeResult ? "Sí" : "No"}</h6>
                                        </li>
                                        <li>
                                            <h4>Descripción</h4>
                                            <h6>{dataItem?.description}</h6>
                                        </li>
                                    </ul>
                                </div>
                                <FormCreatedBy />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <Carousel>
                                    {dataItem?.imageUrls
                                        ? dataItem?.imageUrls.map(
                                            (e, i) =>
                                                <Carousel.Item key={i}>
                                                    <ImageWithBasePath src={e} alt="img" />
                                                </Carousel.Item>
                                        )
                                        : null
                                    }
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductDetail
