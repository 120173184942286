import { setToken } from '../authentication';
import { axiosClientApi } from '../../axiosClientConfiguration';
import { getRequest, postRequest } from '../../axiosFunctions';

const requestData = {
  articleId: null,
  branchId: null,
  quantity: null,
  expiredTypeId: null,
  comment: null,
}

const getList = (token, filters) => {
  let config = setToken(token);
  config.params = {
    branchId: filters.branchId,
    categoryId: filters.categoryId,
    articleName: filters.articleName,
    "range.min": filters["range.min"],
    "range.max": filters["range.max"],
  };
  return getRequest(
    axiosClientApi,
    "/StockManagement/List",
    config
  );
}

const getListOperations = (token, filters) => {
  let config = setToken(token);
  config.params = {
    branchId: filters.branchId,
    categoryId: filters.categoryId,
    articleName: filters.articleName,
    expiredTypeId: filters.expiredTypeId
  };
  return getRequest(
    axiosClientApi,
    "/StockManagement/ListOperations",
    config
  );
}

const postSingle = (token, data) => {
  return postRequest(
    axiosClientApi,
    "/StockManagement",
    data,
    setToken(token)
  );
}

export const StockManagement = {
  requestData,
  getList,
  getListOperations,
  postSingle,
};
