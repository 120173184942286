export const all_routes = {
  home: "/",
  productlist: "/product-list",
  addproduct: "/add-product",
  salesdashboard: "/sales-dashboard",
  brandlist: "/brand-list",
  units: "/units",
  variantyattributes: "/variant-attributes",
  warranty: "/warranty",
  barcode: "/barcode",
  departmentgrid: "/department-grid",
  departmentlist: "/department-list",
  designation: "/designation",
  shift: "/shift",
  attendanceemployee: "/attendance-employee",
  tablebasic: "/tables-basic",
  datatable: "/data-tables",
  basicinput: "/form-basic-inputs",
  checkboxradio: "/form-checkbox-radios",
  inputgroup: "/form-input-groups",
  gridgutters: "/form-grid-gutters",
  formselect: "/form-select",
  fileupload: "/form-fileupload",
  formmask: "/form-mask",
  formhorizontal: "/form-horizontal",
  formvertical: "/form-vertical",
  floatinglabel: "/form-floating-labels",
  formvalidation: "/form-validation",
  select2: "/form-select2",
  apexchart: "/chart-apex",
  chartjs: "/chart-js",
  fontawesome: "/icon-fontawesome",
  feathericon: "/icon-feather",
  ionicicons: "/icon-ionic",
  materialicons: "/icon-material",
  pe7icons: "/icon-pe7",
  simpleline: "/icon-simpleline",
  themifyicons: "/icon-themify",
  iconweather: "/icon-weather",
  typicons: "/icon-typicon",
  flagicons: "/icon-flag",
  chat: "/chat",
  videocall: "/video-call",
  audiocall: "/audio-call",
  email: "/email",
  callhistory: "/call-history",
  todo: "/todo",
  wizard: "/form-wizard",
  expiredproduct: "/expired-products",
  expiredtypes: "/expired-types",
  lowstock: "/low-stocks",
  categorylist: "/category-list",
  subcategories: "/sub-categories",
  productdetails: "/product-details",
  editproduct: "/edit-product",
  expenselist: "/expense-list",
  expensecategory: "/expense-category",
  calendar: "/calendar",
  variantattributes: "/variant-attributes",
  qrcode: "/qrcode",
  purchaselist: "/purchase-list",
  purchaseorderreport: "/purchase-order-report",
  purchasereturn: "/purchase-returns",
  appearance: "/appearance",
  socialauthendication: "/social-authentication",
  languagesettings: "/language-settings",
  invoicesettings: "/invoice-settings",
  printersettings: "/printer-settings",
  possettings: "/pos-settings",
  customfields: "/custom-fields",
  emailsettings: "/email-settings",
  smssettings: "/sms-gateway",
  otpsettings: "/otp-settings",
  gdbrsettings: "/gdpr-settings",
  paymentgateway: "/payment-gateway-settings",
  banksettingslist: "/bank-settings-list",
  banksettingsgrid: "/bank-settings-grid",
  taxrates: "/tax-rates",
  currencysettings: "/currency-settings",
  storagesettings: "/storage-settings",
  banipaddress: "/ban-ip-address",
  generalsettings: "/general-settings",
  securitysettings: "/security-settings",
  notification: "/notification",
  connectedapps: "/connected-apps",
  systemsettings: "/system-settings",
  companysettings: "/company-settings",
  localizationsettings: "/localization-settings",
  prefixes: "/prefixes",
  preference: "/preference",
  saleslist: "/sales-list",
  invoicereport: "/invoice-report",
  salesreturn: "/sales-returns",
  quotationlist: "/quotation-list",
  pos: "/pos",
  attendanceadmin: "attendance-admin",
  payslip: "/payslip",
  holidays: "/holidays",
  customers: "/customers",
  suppliers: "/suppliers",
  storelist: "/store-list",
  managestock: "/manage-stocks",
  stockadjustment: "/stock-adjustment",
  stocktransfer: "/stock-transfer",
  salesreport: "/sales-report",
  purchasereport: "/purchase-report",
  inventoryreport: "/inventory-report",
  supplierreport: "/supplier-report",
  customerreport: "/customer-report",
  expensereport: "/expense-report",
  incomereport: "/income-report",
  taxreport: "/tax-report",
  profitloss: "/profit-loss-report",
  notes: "/notes",
  filemanager: "/file-manager",
  profile: "/profile",
  signin: "/signin",
  signout: "/signout",
  register: "/register",
  forgotPassword: "/forgot-password",
  resetpassword: "/reset-password",
  error404: "/error-404",
  error500: "/error-500",
  users: "/users",
  rolespermission: "/roles-permissions",
  permissions: "/permissions",
  deleteaccount: "/delete-account",
  employeegrid: "/employees-grid",
  addemployee: "/add-employee",
  editemployee: "/edit-employee",
  leavesadmin: "leaves-admin",
  leavesemployee: "/leaves-employee",
  leavestype: "/leave-types",
  warehouses: "/warehouse",
  coupons: "/coupons",
  addcustomer: "/add-customer",
  editcustomer: "/edit-customer",
  addsupplier: "/add-supplier",
  editsupplier: "/edit-supplier",
  cashbreak: "/cash-break",
  recipegrid: "/recipe-grid",
  recipebook: "/recipe-book",
  recipe: "/recipe",
  purchaseProductList: "/purchase-product-list",
  menugrid: "/menu-grid",
  menu: "/menu",
  cashoutflowlist: "/cash-outflow-list",
  purchasedetails: "/purchase-details",
  transferdetails: "/transfer-details",
  packagegrid: "/package-grid",
  package: "/package",
  simplepos: "/pos/simple",
  saleDetails: "/sale-details",
  leaveEmployeeDetails: "/leave-employee-details",
  cashBreakDetails: "/cash-break-details",
};
