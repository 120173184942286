import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import { fechaHoraFormat } from "../../utils";
import DatePicker from "react-datepicker";
import es from 'date-fns/locale/es';
import { Calendar } from "react-feather";
import variables from "../../../style/scss/utils/_variables.scss";

const NoteModal = ({ id, note, show = false, onHide }) => {

  const loading = useSelector(state => state.loading);

  const onModalHide = (e, refresh) => {
    e?.preventDefault();
    onHide(refresh);
  }

  return (
    <Modal show={show} centered onHide={onModalHide} id={id}>
      <Modal.Header className="custom-modal-header" closeButton={!loading}>
        <Modal.Title>
          <h4>Notas</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-lg-6 col-sm-6 col-12 mb-3">
            <label className="form-label">Usuario</label>
            <input type="text" className="form-control" defaultValue={note.user} disabled />
          </div>
          <div className="col-lg-6 col-sm-6 col-12 mb-3">
            <label className="form-label">Fecha y hora</label>
            <div className="input-group">
              <span className="input-group-text"><Calendar size={15} color={variables.primaryColor} /></span>
              <DatePicker
                selected={new Date(note.date)}
                dateFormat={fechaHoraFormat}
                locale={es}
                wrapperClassName="form-control"
                className="form-control"
                disabled
              />
            </div>
          </div>
          <div className="col-lg-12 col-sm-12 col-12 mb-3">
            <label className="form-label">Comentario</label>
            <textarea
              rows={2}
              maxLength={200}
              className="form-control"
              value={note.comment}
              disabled
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='justify-content-center'>
        <button
          className="btn btn-cancel me-2"
          onClick={(e) => onModalHide(e, false)}
          disabled={loading}
        >
          Regresar
        </button>
      </Modal.Footer>
    </Modal >
  );
};

NoteModal.propTypes = {
  id: PropTypes.string.isRequired,
  note: PropTypes.object.isRequired,
  show: PropTypes.bool,
  onHide: PropTypes.func
};


export default NoteModal;
