
import React from "react";
import { BrowserRouter } from 'react-router-dom';
//import ReactDOM from 'react-dom';
import ReactDOMClient from 'react-dom/client';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import { base_path } from "./environment.jsx";
import '../src/style/css/feather.css'
import '../src/style/css/line-awesome.min.css'
import "../src/style/scss/main.scss";
import '../src/style/icons/fontawesome/css/fontawesome.min.css'
import '../src/style/icons/fontawesome/css/all.min.css'


import { Provider } from "react-redux";
import store from "./core/redux/store.jsx";
import AllRoutes from "./Router/router.jsx";
import { ConfigProvider } from "antd";
import esES from 'antd/locale/es_ES';
import { loadToken } from "./core/redux/action.jsx";

const rootElement = document.getElementById('root');
store.dispatch(loadToken())

if (rootElement) {
  const root = ReactDOMClient.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <Provider store={store} >
        <ConfigProvider
          locale={esES}
          theme={{
            token: {
              colorPrimary: "#001f60",
            },
            components: {
              Switch: {
                colorPrimary: "#28C76F"
              },
              Input: {
                fontFamily: "'Nunito', sans-serif;",
              },
              Select: {
                fontFamily: "'Nunito', sans-serif;",
                zIndexPopup: 1070
              },
              Table: {
                fontFamily: "'Nunito', sans-serif;",
                bodySortBg: "#FAFBFE",
                rowHoverBg: "#FAFBFE",
                headerSortActiveBg: "#FAFBFE",
                headerSortHoverBg: "#FAFBFE",
              },
            },
          }}
        >
          <BrowserRouter basename={base_path}>
            <AllRoutes />
          </BrowserRouter>
        </ConfigProvider>
      </Provider>
    </React.StrictMode>
  );
} else {
  console.error("Element with id 'root' not found.");
}
