import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Minus, Plus } from "react-feather";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import SearchProduct from "../../../feature-module/components/searchProduct";
import { generarCodigo, quantityRegex } from "../../utils";

const AddIngredient = ({ id, show = false, showQuantity = true, onHide, isResult = null, onAddItem }) => {

    const loading = useSelector(state => state.loading);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [results, setResults] = useState([]);
    const [isValidQty, setIsValidQty] = useState(true);

    const onSelectProduct = (selectedProduct) => {
        if (!selectedProduct) {
            setCurrentProduct(null);
            return;
        }
        setQuantity(1);
        let item = results.find(f => f.id === selectedProduct.value);
        setCurrentProduct(item);
    }

    const onChangeQty = (e) => {
        setQuantity(e?.target.value);
    }

    const onValidateQty = (e) => {
        e?.preventDefault();
        let qty = Number(e?.target.value);
        const regex = new RegExp(quantityRegex);

        const result = regex.test(qty);
        if (!result) {
            setIsValidQty(false);
            return;
        }

        if (qty <= 0 || isNaN(qty)) {
            qty = 1;
        }
        if (qty > 9999) {
            qty = 9999;
        }
        setQuantity(qty);
        setIsValidQty(true);
    }

    const onPlusQty = () => {
        let qty = Number(quantity);
        if (qty >= 9999) {
            qty = 9999;
        } else {
            qty += 1;
        }
        setQuantity(qty);
    }

    const onMinusQty = () => {
        let qty = Number(quantity) - 1;
        if (qty <= 0) {
            qty = 1;
        }
        setQuantity(qty)
    }

    const onAdd = (e) => {
        e?.preventDefault();
        currentProduct["key"] = generarCodigo();
        currentProduct["quantity"] = quantity;
        let product = currentProduct;
        clearData();
        onAddItem(product);
    }

    const clearData = () => {
        setQuantity(1);
        setCurrentProduct('');
    }

    const onModalHide = (e, refresh) => {
        e?.preventDefault();
        onHide(refresh);
    }

    return (
        <Modal show={show} centered onHide={onModalHide} id={id} backdrop="static" keyboard={false}>
            <Modal.Header className="custom-modal-header" closeButton={!loading}>
                <Modal.Title>
                    <h4>{isResult === null ? "Añadir Producto" : (isResult ? "Añadir Resultado" : "Añadir Ingrediente")}</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <SearchProduct
                        productSelectedCallback={onSelectProduct}
                        errorCallback={() => { }}
                        resultProductsCallback={setResults} />
                </form>
                {currentProduct && showQuantity ?
                    <div className="row">
                        <div className="col-7 text-center">
                            <label className="form-label text-center">Cantidad</label>
                            <div className="input-group">
                                <button className="btn btn-outline-primary me-2" onClick={onPlusQty}>
                                    <Plus />
                                </button>
                                <input
                                    type="text"
                                    className={!isValidQty ? "form-control text-center is-invalid me-2" : "form-control text-center me-2"}
                                    value={quantity}
                                    maxLength={14}
                                    onChange={e => onChangeQty(e)}
                                    onBlur={e => onValidateQty(e)}
                                />

                                <button
                                    className="btn btn-outline-secondary"
                                    onClick={onMinusQty}
                                    disabled={quantity <= 1}>
                                    <Minus />
                                </button>
                            </div>
                            {
                                !isValidQty &&
                                <div className="text-danger small">
                                    Cantidad inválida
                                </div>
                            }
                        </div>
                        <div className="col-5">
                            <label className="form-label text-center">Unidad</label>
                            <input type="text" className="form-control" defaultValue={currentProduct?.unmed?.name} disabled />
                        </div>
                    </div>
                    : null}
            </Modal.Body>
            <Modal.Footer className='justify-content-center'>
                <button
                    className="btn btn-cancel me-2"
                    onClick={(e) => onModalHide(e, false)}
                    disabled={loading}
                >
                    Regresar
                </button>
                <button
                    className="btn btn-primary"
                    onClick={(e) => onAdd(e)}
                    disabled={!currentProduct || (currentProduct && !isValidQty) || loading}>
                    Añadir
                </button>
            </Modal.Footer>
        </Modal>
    );
};

AddIngredient.propTypes = {
    id: PropTypes.string.isRequired,
    show: PropTypes.bool,
    showQuantity: PropTypes.bool,
    onHide: PropTypes.func,
    isResult: PropTypes.bool,
    onAddItem: PropTypes.func
};


export default AddIngredient;
