import React, { useEffect, useState } from "react";
import { Eye, Filter, HelpCircle, MessageSquare, X } from "react-feather";
import Select from "react-select";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { all_routes } from "../../Router/all_routes";
import NotesEditModal from '../../core/modals/applications/notesEditModal';
import ToolsHead from "../components/toolsHead";
import { fechaHoraFormat, formatearFecha, noOptionsText } from "../../core/utils";
import { Table } from "antd";
import { Transfers } from "../../services/internal/transfers";
import { Catalogs } from "../../services/internal/catalogs";
import FilterControl from "../components/filterControl";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setNotes, setProviderOptions, setShowRemoteResponseToast, setStoreOptions, setTransferList } from "../../core/redux/action";
import { showInternalErrorAlert } from "../components/customAlert";
import NotesModal from "../../core/modals/applications/notesModal";
import { Statuses } from "../../services/internal/statuses";

const StockTransfer = () => {
  const dataSource = useSelector((state) => state.transferList);
  const storeOptions = useSelector((state) => state.storeOptions);
  const providerOptions = useSelector((state) => state.providerOptions);
  const notes = useSelector((state) => state.notes);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading);
  const token = useSelector((state) => state.token);
  const [filterCode, setFilterCode] = useState("");
  const [filterBranchDestination, setBranchDestination] = useState("");
  const [filterProvider, setFilterProvider] = useState("");
  const [filterBranchOrigin, setFilterBranchOrigin] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showEditNotesModal, setShowEditNotesModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const route = all_routes;
  const statusOptions = [
    Statuses.Values.Approved,
    Statuses.Values.Pending,
    Statuses.Values.Delivered,
    Statuses.Values.Rejected,
    Statuses.Values.Canceled,
  ];

  const onChangeName = (e) => {
    setFilterCode(e.target.value.trim())
  }

  const handleException = (err) => {
    console.log(err);
    if (err.response) {
      dispatch(fetchDataFailure(err.response.data));
      if (err.response.status >= 400 && err.response.status < 500) {
        dispatch(fetchDataErrors(err.response.data.errors));
        dispatch(setShowRemoteResponseToast(true));
        return;
      } else {
        showInternalErrorAlert(false, err.response.statusText);
        return;
      }
    }
    dispatch(fetchDataFailure(err));
    showInternalErrorAlert(true, err.code);
  }

  const onLoadTranfers = async () => {

    let filters = {};
    filters.enabled = true;

    if (filterCode) {
      filters.code = filterCode;
    }
    if (filterBranchDestination) {
      filters.destinyBranchId = filterBranchDestination.value;
    }
    if (filterProvider) {
      filters.originPrivider = filterProvider.value;
    }
    if (filterBranchOrigin) {
      filters.originBranchId = filterBranchOrigin.value;
    }
    if (filterStatus) {
      filters.statusId = filterStatus.value;
    }

    try {
      dispatch(fetchDataRequest());

      if (storeOptions.length === 0) {
        const responseB = await Catalogs.getOptions(token, Catalogs.Name.Branches);
        dispatch(setStoreOptions(responseB.data.data));
      }
      if (providerOptions.length === 0) {
        const responseP = await Catalogs.getOptions(token, Catalogs.Name.Providers);
        dispatch(setProviderOptions(responseP.data.data));
      }
      /*if (statusOptions.length === 0) {
        const responseS = await Catalogs.getOptions(token, Catalogs.Name.Statuses);
        dispatch(setStatusOptions(responseS.data.data));
      }*/

      const response = await Transfers.getList(token, filters);
      //console.log(response)
      dispatch(setTransferList(response.data.data));
      dispatch(fetchDataSuccess(response.data.success));
    }
    catch (err) {
      handleException(err);
    }
  }

  const onSaveNotes = async () => {
    try {
      if (!notes) {
        return;
      }

      let data = Transfers.requestData;
      data = {};
      data.comentary = JSON.stringify(cleanNotes(notes));
      data.id = currentItem.id;
      data.statusId = currentItem.status?.id;
      dispatch(fetchDataRequest());

      const response = await Transfers.putSingle(token, data);
      //console.log(response)
      dispatch(setShowRemoteResponseToast(true));
      dispatch(setNotes(null));
      dispatch(fetchDataSuccess(response.data.success));

      await onLoadTranfers();
    }
    catch (err) {
      handleException(err);
    }
  }

  const onShowNotesModal = (record) => {
    setCurrentItem(record);
    let notes = record.comentary;
    if (!notes) {
      notes = "[]";
    }
    dispatch(setNotes(JSON.parse(notes)))
    setShowNotesModal(true);
  }

  const onShowNotesEditModal = (record) => {
    setCurrentItem(record);
    let notes = record.comentary;
    if (!notes) {
      notes = "[]";
    }
    dispatch(setNotes(JSON.parse(notes)))
    setShowEditNotesModal(true);
  }

  const onHideNotesModal = () => {
    setCurrentItem(null);
    dispatch(setNotes(null));
    setShowNotesModal(false);
  }

  const onHideEditNotesModal = async (notesChanged) => {
    if (notesChanged) {
      await onSaveNotes();
    }
    setCurrentItem(null);
    dispatch(setNotes(null));
    setShowEditNotesModal(false);
  }

  const cleanNotes = () => {
    const cleanedNotes = notes.map((obj) => {
      const newObj = {};
      for (const prop in obj) {
        if (prop !== 'key') {
          newObj[prop] = obj[prop];
        }
      }
      return newObj;
    });
    return cleanedNotes;
  }

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
    onLoadTranfers();
  };

  const renderSearchTooltip = (props) => (
    <Tooltip id="search-tooltip" {...props}>
      Ingresa el código del traslado
    </Tooltip>
  );

  const columns = [
    {
      title: "Fecha y Hora",
      dataIndex: "registerDate",
      align: "center",
      render: (text, record) => (
        <span>{formatearFecha(record.registerDate, fechaHoraFormat)}</span>
      ),
      sorter: (a, b) => a.registerDate?.localeCompare(b.registerDate),
      defaultSortOrder: "descend"
    },
    {
      title: "Código de traslado",
      dataIndex: "code",
      align: "center",
      render: (text, record) => (
        <span>
          <Link
            to={`${route.transferdetails}?code=${record.id}`}
            className="text-orange"
          >
            {record.code}
          </Link>
        </span>
      ),
      sorter: (a, b) => a.code?.localeCompare(b.code)
    },
    {
      title: "Proveedor ó Sucursal origen",
      dataIndex: "originProvider.name",
      render: (text, record) => (
        <span className="text-primary-emphasis fw-semibold">{record.originProvider?.name ? record.originProvider?.name : record.originBranchId?.name}</span>
      ),
      sorter: (a, b) => a.originProvider?.name?.localeCompare(b.originProvider?.name)
    },
    {
      title: "Sucursal Destino",
      dataIndex: "destinyBranch.name",
      render: (text, record) => (
        <span className="text-secondary fw-semibold">{record.destinyBranchId?.name}</span>
      ),
      sorter: (a, b) => a.destinyBranchId?.name?.localeCompare(b.destinyBranchId?.name)
    },
    {
      title: "Estatus",
      //align: "center",
      dataIndex: "status.name",
      render: (text, record) => (
        <>
          {record.status?.name === "Aprobado" && (
            <span className="badge badges-success">{record.status?.name}</span>
          )}
          {record.status?.name === "Rechazado" && (
            <span className="badge badges-danger">{record.status?.name}</span>
          )}
          {record.status?.name === "Cancelado" && (
            <span className="badge badges-danger">{record.status?.name}</span>
          )}
          {record.status?.name === "Pendiente" && (
            <span className="badge badges-warning">{record.status?.name}</span>
          )}
          {record.status?.name === "Entregado" && (
            <span className="badge badges-purple">{record.status?.name}</span>
          )}
        </>
      ),
      sorter: (a, b) => a.status?.name?.localeCompare(b.status?.name)
    },
    {
      title: "Orden de compra",
      align: "center",
      dataIndex: "purchase.code",
      render: (text, record) => (
        <Link
          to={route.purchasedetails + "?code=" + record?.purchase?.id}
          className="text-primary fw-bold">
          {"Ir a la orden"}
        </Link>
      ),
      sorter: (a, b) => a.purchase?.code?.localeCompare(b.purchase?.code)
    },
    {
      title: "Notas",
      align: "center",
      render: (text, record) => (
        record.comentary
          ? <a className="text-blue fw-semibold" onClick={() => onShowNotesModal(record)}>Ver Notas</a>
          : <span>Ninguna</span>
      ),
    },
    {
      title: "Acción",
      align: "center",
      dataIndex: "action",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link
              className="me-2 p-2"
              to={`${route.transferdetails}?code=${record.id}`}
            >
              <Eye />
            </Link>
            {(record.status?.name === "Pendiente" || record.status?.name === "Aprobado")
              &&
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-minus">Comentar</Tooltip>}
              >
                <a
                  className="confirm-text me-2 p-2"
                  onClick={() => onShowNotesEditModal(record)} >
                  <MessageSquare className="feather-edit" />
                </a>
              </OverlayTrigger>
            }
          </div>
        </div>
      )
    },
  ];

  useEffect(() => {
    if (dataSource.length === 0) {
      onLoadTranfers();
    }
  }, []);

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Traslados</h4>
              <h6>Puedes consultar el estatus de los traslados generados a partir de una orden  de compra.</h6>
            </div>
          </div>
          <ToolsHead showExportData={true} showExcel={true} onReload={onLoadTranfers} />
        </div>
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Búsqueda rápida"
                    className="form-control form-control-sm formsearch"
                    onChange={e => onChangeName(e)}
                    onBlur={onLoadTranfers}
                    disabled={loading}
                  />
                  <a className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </a>
                </div>
                <OverlayTrigger placement="top" overlay={renderSearchTooltip}>
                  <a className="link-primary">
                    <HelpCircle />
                  </a>
                </OverlayTrigger>
              </div>
              <div className="search-path">
                <div
                  className={`btn btn-filter ${isFilterVisible ? "setclose" : ""}`}
                  id="filter_search"
                  onClick={toggleFilterVisibility}
                >
                  <Filter className="filter-icon"
                  />
                  <span>
                    <X className="filter-icon" />
                  </span>
                </div>
              </div>
            </div>
            {/* /Filter */}
            <div
              className={`card${isFilterVisible ? " visible" : ""}`}
              id="filter_inputs"
              style={{ display: isFilterVisible ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-3 col-sm-3 col-12 mb-3">
                    <Select
                      options={providerOptions}
                      onChange={(value) => setFilterProvider(value)}
                      noOptionsMessage={() => noOptionsText}
                      isClearable
                      placeholder="Elige un proveedor"
                      components={{ Control: FilterControl }}
                      isDisabled={loading}
                    />
                  </div>
                  <div className="col-lg-3 col-sm-3 col-12 mb-3">
                    <Select
                      options={storeOptions}
                      onChange={(value) => setFilterBranchOrigin(value)}
                      noOptionsMessage={() => noOptionsText}
                      isClearable
                      placeholder="Elige una sucursal origen"
                      components={{ Control: FilterControl }}
                      isDisabled={loading}
                    />
                  </div>
                  <div className="col-lg-3 col-sm-3 col-12 mb-3">
                    <Select
                      options={storeOptions}
                      placeholder="Elige una sucursal de destino"
                      onChange={(value) => setBranchDestination(value)}
                      noOptionsMessage={() => noOptionsText}
                      components={{ Control: FilterControl }}
                      isDisabled={loading}
                      isClearable
                    //isMulti
                    />
                  </div>
                  <div className="col-lg-3 col-sm-3 col-12 mb-3">
                    <Select
                      options={statusOptions}
                      onChange={(value) => setFilterStatus(value)}
                      noOptionsMessage={() => noOptionsText}
                      isClearable
                      placeholder="Elige un estatus"
                      components={{ Control: FilterControl }}
                      isDisabled={loading}
                    />
                  </div>
                  <div className="col-lg-3 col-sm-3 col-12 mb-3">
                    <button
                      className="btn btn-outline-primary w-100"
                      onClick={() => onLoadTranfers()}
                      disabled={loading}
                    >
                      <span data-feather="check" className="feather-check" />Aplicar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* /Filter */}
            <div className="table-responsive">
              <Table
                className="table"
                rowKey={(record) => record?.id}
                columns={columns}
                loading={loading}
                dataSource={dataSource}
                size="small"
                pagination={
                  {
                    position: ["topRight"],
                    total: dataSource.length,
                    showTotal: (total) => `Total: ${total}`,
                    showSizeChanger: true
                  }
                }
              />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
      {showEditNotesModal
        ? <NotesEditModal id="edit-notes" show={showEditNotesModal} onHide={onHideEditNotesModal} />
        : null
      }
      {showNotesModal
        ? <NotesModal id="notes" show={showNotesModal} onHide={onHideNotesModal} />
        : null
      }
    </div>
  );
};

export default StockTransfer;
