import { Table } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { fechaHoraFormat, formatearFecha } from "../../../core/utils";

const NotesTable = () => {

    const dataSource = useSelector(state => state.notes);

    const columns = [
        {
            title: "Fecha y hora",
            dataIndex: "date",
            align: "center",
            render: (text, record) => (
                <span>{formatearFecha(record.date, fechaHoraFormat)}</span>
            ),
        },
        {
            title: "Usuario",
            dataIndex: "user",
            render: (text) => (
                <span className="text-primary fw-semibold">{text}</span>
            ),
        },
        {
            title: "Comentario",
            dataIndex: "comment",
        },
    ];

    return (
        <div className="table-responsive mb-3">
            <Table columns={columns} dataSource={dataSource} footer={false} pagination={false} />
        </div>
    );
};


export default NotesTable;
