import { setToken } from '../authentication';
import { axiosClientApi } from '../../axiosClientConfiguration';
import { deleteRequest, getRequest, postRequest, putRequest } from '../../axiosFunctions';

const requestData = {
  id: undefined,
  name: undefined,
  lastName: undefined,
  seccondLastName: undefined,
  email: undefined,
  phone: undefined,
  address: undefined,
  neigborhood: undefined,
  town: undefined,
  stateId: undefined,
  zipCode: undefined,
  taxId: undefined,
  businessName: undefined,
  taxZipCode: undefined,
  imagePath: undefined,
  enabled: undefined
}

const getSingle = (token, id) => {
  return getRequest(
    axiosClientApi,
    `/Client/Get/${id}`,
    setToken(token)
  );
}

const getList = (token, filters) => {
  let config = setToken(token);
  config.params = {
    name: filters.name,
    lastName: filters.lastName,
    seccondLastName: filters.seccondLastName,
    email: filters.email,
    zipCode: filters.zipCode,
    taxId: filters.taxId,
    enabled: filters.enabled
  };
  return getRequest(
    axiosClientApi,
    "/Client/List",
    config
  );
}

const postSingle = (token, data) => {
  return postRequest(
    axiosClientApi,
    "/Client",
    data,
    setToken(token)
  );
}

const putSingle = (token, data) => {
  return putRequest(
    axiosClientApi,
    "/Client",
    data,
    setToken(token)
  );
}

const deleteSingle = (token, id) => {
  return deleteRequest(
    axiosClientApi,
    `/Client/Delete/${id}`,
    setToken(token)
  );
}

export const Client = {
  requestData,
  getSingle,
  getList,
  postSingle,
  putSingle,
  deleteSingle,
};
