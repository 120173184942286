import { setToken } from '../authentication';
import { axiosClientApi } from '../../axiosClientConfiguration';
import { getRequest, postRequest } from '../../axiosFunctions';

const requestData = {
  clientId: undefined,
  branchId: undefined,
  code: undefined,
  total: undefined,
  terminal: undefined,
  cashId: undefined,
  paymentMethod: undefined,
  salePackages: [
    {
      packageId: undefined,
      quantity: undefined,
      subtotal: undefined
    }
  ],
  saleArticles: [
    {
      articleId: undefined,
      quantity: undefined,
      subtotal: undefined
    }
  ]
};

const requestBreakData = {
  branchId: undefined,
  terminal: undefined,
  code: undefined,
};

const requestCashoutComesData = {
  conceptId: undefined,
  ammount: undefined,
  comment: undefined
};

const getSingle = (token, id) => {
  return getRequest(
    axiosClientApi,
    `/Sales/Get/${id}`,
    setToken(token)
  );
}

const getList = (token, filters) => {
  let config = setToken(token);
  config.params = {
    code: filters.code,
    branchId: filters.branchId,
    saleUserId: filters.saleUserId,
    clientId: filters.clientId,
    paymentMethod: filters.paymentMethod,
  };
  return getRequest(
    axiosClientApi,
    "/Sales/List",
    config
  );
}

const postSingle = (token, data) => {
  return postRequest(
    axiosClientApi,
    "/Sales",
    data,
    setToken(token)
  );
}

const getBreakSingle = (token, id) => {
  return getRequest(
    axiosClientApi,
    `/Sales/Breaks/Get/${id}`,
    setToken(token)
  );
}

const getBreakList = (token, filters) => {
  let config = setToken(token);
  config.params = {
    code: filters.code,
    branchId: filters.branchId,
    saleUserId: filters.saleUserId,
    clientId: filters.clientId,
    paymentMethod: filters.paymentMethod,
  };
  return getRequest(
    axiosClientApi,
    "/Sales/Breaks/List",
    config
  );
}

const postBreakRegister = (token, data) => {
  return postRequest(
    axiosClientApi,
    "/Sales/Breaks/Register",
    data,
    setToken(token)
  );
}

const postCashoutComesCreate = (token, data) => {
  return postRequest(
    axiosClientApi,
    "/Sales/CashOutcomes/Create",
    data,
    setToken(token)
  );
}

export const Sales = {
  requestData,
  requestBreakData,
  requestCashoutComesData,
  getSingle,
  getList,
  postSingle,
  getBreakSingle,
  getBreakList,
  postBreakRegister,
  postCashoutComesCreate
};
