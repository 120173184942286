import React from 'react';

import * as Icon from 'react-feather';
import { all_routes } from '../../Router/all_routes';

const route = all_routes;

export const SidebarData = [

  {
    label: "Inicio",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Inicio",
    submenuItems: [
      /*{
        label: "Dashboard",
        icon: <Icon.Grid />,
        submenu: true,
        showSubRoute: false,
        submenuItems: [
          { label: "General", link: "/" },
          { label: "Ventas", link: route.salesdashboard }
        ]
      },*/

      { label: "Sucursales", link: route.storelist, icon: <Icon.Home />, showSubRoute: false, submenu: false },
    ]
  },
  {
    label: "Inventario",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Inventario",

    submenuItems: [
      { label: "Productos", link: route.productlist, icon: <Icon.Box />, showSubRoute: false, submenu: false },
      { label: "Registrar producto", link: route.addproduct, icon: <Icon.PlusSquare />, showSubRoute: false, submenu: false },
      { label: "Categorías", link: route.categorylist, icon: <Icon.Codepen />, showSubRoute: false, submenu: false },
      { label: "Unidades de medida", link: route.units, icon: <Icon.Hash />, showSubRoute: false, submenu: false },
      { label: "Existencias", link: route.managestock, icon: <Icon.Package />, showSubRoute: false, submenu: false },
      { label: "Operaciones de inventario", link: route.stockadjustment, icon: <Icon.Codesandbox />, showSubRoute: false, submenu: false },
      { label: "Tipos de operación", link: route.expiredtypes, icon: <Icon.Repeat />, showSubRoute: false, submenu: false }
    ]
  },
  {
    label: "Compras",
    submenuOpen: true,
    submenuHdr: "Compras",
    showSubRoute: false,
    submenuItems: [
      { label: "Órdenes de compra", link: route.purchaselist, icon: <Icon.ShoppingBag />, showSubRoute: false, submenu: false },
      { label: "Traslados", link: route.stocktransfer, icon: <Icon.Truck />, showSubRoute: false, submenu: false },
      { label: "Pedidos", link: route.purchaseProductList, icon: <Icon.Clipboard />, showSubRoute: false, submenu: false },
      { label: "Proveedores", link: route.suppliers, icon: <Icon.Users />, showSubRoute: false, submenu: false },
    ]
  },
  /*{
    label: "Producción",
    submenuOpen: true,
    submenuHdr: "Producción",
    submenu: true,
    showSubRoute: false,
    submenuItems: [
      { label: "Recetarios", link: route.recipebook, icon: <Icon.Book />, showSubRoute: false, submenu: false },
      { label: "Paquetes", link: route.packagegrid, icon: <Icon.Layers />, showSubRoute: false, submenu: false },
      { label: "Menús", link: route.menugrid, icon: <Icon.BookOpen />, showSubRoute: false, submenu: false },
    ]
  },*/
  {
    label: "Ventas",
    submenuOpen: true,
    submenuHdr: "Ventas",
    submenu: false,
    showSubRoute: false,
    submenuItems: [
      { label: "Ventas", link: route.saleslist, icon: <Icon.ShoppingCart />, showSubRoute: false, submenu: false },
      { label: "Cortes de caja", link: route.cashbreak, icon: <Icon.Inbox />, showSubRoute: false, submenu: false },
      { label: "Salidas de efectivo", link: route.cashoutflowlist, icon: <Icon.CornerUpRight />, showSubRoute: false, submenu: false },
      { label: "Clientes", link: route.customers, icon: <Icon.Users />, showSubRoute: false, submenu: false },
      { label: "Punto de venta", link: route.pos, icon: <Icon.HardDrive />, showSubRoute: false, submenu: false },
    ]
  },
  {
    label: "Personal",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Personal",
    submenuItems: [
      { label: "Áreas", link: route.departmentgrid, icon: <Icon.Flag />, showSubRoute: false },
      { label: "Puestos", link: route.designation, icon: <Icon.User />, showSubRoute: false },
      { label: "Usuarios", link: route.employeegrid, icon: <Icon.Users />, showSubRoute: false },
      { label: "Permisos", link: route.permissions, icon: <Icon.Shield />, showSubRoute: false },
      { label: "Roles", link: route.rolespermission, icon: <Icon.UserCheck />, showSubRoute: false },
      {
        label: "Asistencia", link: "#", icon: <Icon.Calendar />, showSubRoute: false, submenu: true,
        submenuItems: [
          { label: "Asistencia", link: route.attendanceemployee },
          { label: "Vacaciones", link: route.leavesemployee },
          { label: "Tipos de justificación", link: route.leavestype },
          { label: "Horarios", link: route.shift },
          { label: "Días festivos", link: route.holidays }
        ]
      },
    ],
  },
  /*{
    label: "Reportes",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Reportes",
    submenuItems: [
      { label: "Reporte de ventas", link: route.salesreport, icon: <Icon.BarChart />, showSubRoute: false },
      { label: "Reporte de ingresos", link: route.purchasereport, icon: <Icon.BarChart2 />, showSubRoute: false },
      { label: "Reporte de gastos", link: route.inventoryreport, icon: <Icon.PieChart />, showSubRoute: false },
      { label: "Reporte salida de inventario", link: route.invoicereport, icon: <Icon.BarChart />, showSubRoute: false },
      { label: "Reporte de producción por sucursal", link: route.supplierreport, icon: <Icon.BarChart2 />, showSubRoute: false },
      { label: "Reporte de producción por artículo", link: route.customerreport, icon: <Icon.PieChart />, showSubRoute: false },
      { label: "Reporte de cuentas por pagar", link: route.expensereport, icon: <Icon.BarChart />, showSubRoute: false }
    ],
  },*/
  {
    label: "Configuración",
    submenu: true,
    showSubRoute: false,
    submenuHdr: "Configuración",
    submenuItems: [
      /*{
        label: "General",
        submenu: true,
        showSubRoute: false,
        icon: <Icon.Settings />,
        submenuItems: [
          { label: "Seguridad", link: route.securitysettings },
          { label: "Notificaciones", link: route.notification },
          { label: "Integración con Apps", link: route.connectedapps }
        ]
      },
      {
        label: "Punto de venta", submenu: true,

        showSubRoute: false,
        icon: <Icon.Smartphone />,
        submenuItems: [
          { label: "Recibos", link: route.invoicesettings, showSubRoute: false },
          { label: "Impresión", link: route.printersettings, showSubRoute: false },
          { label: "Metodos de pago", link: route.paymentgateway, showSubRoute: false },
          { label: "Tarifas de impuestos", link: route.taxrates, showSubRoute: false }
        ]
      },
      {
        label: "Sistema", submenu: true,
        showSubRoute: false,
        icon: <Icon.Monitor />,
        submenuItems: [
          { label: "Email", link: route.emailsettings, showSubRoute: false },
          { label: "SMS", link: route.smssettings, showSubRoute: false },
          { label: "OTP", link: route.otpsettings, showSubRoute: false },
          { label: "Bloqueo de IP", link: route.banipaddress, showSubRoute: false }
        ]
      },*/
      { label: "Cerrar sesión", link: route.signout, icon: <Icon.LogOut />, showSubRoute: false }
    ]
  }
]
