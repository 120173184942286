import { axiosClientApi } from '../../axiosClientConfiguration';
import { postRequest } from '../../axiosFunctions';

const abortController = new AbortController();

export const baseConfig = {
  signal: abortController.signal,
  headers: {
    "Accept": 'application/json',
  }
};

export const setToken = (token) => {
  let config = baseConfig;
  config.headers["Authorization"] = `Bearer ${token}`;
  return config;
}

const requestData = {
  user: undefined,
  pass: undefined
}

const responseData = {
  data: undefined,
  message: undefined,
}

const postLogin = (data) => {
  return postRequest(
    axiosClientApi,
    "/Authentication/Login",
    data,
    baseConfig
  );
}

const postRefresh = (data) => {
  return postRequest(
    axiosClientApi,
    "/Authentication/Refresh",
    data,
    baseConfig
  );
}

const saveToken = (token) => {
  localStorage.setItem('token', token);
}

export const Authentication = {
  requestData,
  responseData,
  saveToken,
  postLogin,
  postRefresh
};