import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddCatalog from "../../core/modals/inventory/addcatalog";
import Swal from "sweetalert2";
import {
  PlusCircle,
  HelpCircle
} from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Edit, Trash2 } from "react-feather";
import ToolsHead from "../components/toolsHead";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setShowRemoteResponseToast, setUnitList } from "../../core/redux/action";
import { showInternalErrorAlert, showSuccessAlert } from "../components/customAlert";
import { Unmed } from "../../services/internal/unmed";
import { Table } from "antd";

export const Units = () => {
  const dataSource = useSelector((state) => state.unitList);
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading);
  const token = useSelector((state) => state.token);
  const [filterName, setFilterName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [newItem, setNewItem] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  const renderSearchTooltip = (props) => (
    <Tooltip id="search-tooltip" {...props}>
      Ingresa el nombre de la unidad de medida
    </Tooltip>
  );

  const onChangeName = (e) => {
    setFilterName(e.target.value.trim())
  }

  const handleException = (err) => {
    console.log(err);
    if (err.response) {
      dispatch(fetchDataFailure(err.response.data));
      if (err.response.status >= 400 && err.response.status < 500) {
        dispatch(fetchDataErrors(err.response.data.errors));
        dispatch(setShowRemoteResponseToast(true));
        return;
      } else {
        showInternalErrorAlert(false, err.response.statusText);
        return;
      }
    }
    dispatch(fetchDataFailure(err));
    showInternalErrorAlert(true, err.code);
  }

  const onLoadUnits = async () => {

    let filters = {};
    filters.enabled = true;
    if (filterName) {
      filters.name = filterName;
    }

    try {
      dispatch(fetchDataRequest());
      const response = await Unmed.getList(token, filters);
      //console.log(response)
      dispatch(setUnitList(response.data.data));
      dispatch(fetchDataSuccess(response.data.success));
    }
    catch (err) {
      handleException(err);
    }
  }

  const onDeleteItem = async (id) => {
    try {
      dispatch(fetchDataRequest());
      const response = await Unmed.deleteSingle(token, id);
      //console.log(response)
      dispatch(fetchDataSuccess(response.data.success));
      showSuccessAlert("Hemos eliminado la unidad de medida.");
    }
    catch (err) {
      handleException(err);
    }
    await onLoadUnits();
  }


  const showDeleteItemAlert = async (index, text) => {
    const result = await MySwal.fire({
      title: "¿Estás seguro?",
      html: `Vamos a eliminar ${text}, esta acción es <strong>irreversible</strong>.`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
      customClass: {
        confirmButton: "btn btn-warning",
      },
    });

    if (result.isConfirmed) {
      await onDeleteItem(index.id);
    } else {
      MySwal.close();
    }
  };

  const onNewItem = () => {
    setCurrentId(null);
    setNewItem(true);
    setShowModal(true);
  }

  const onEditItem = (index) => {
    setCurrentId(index.id);
    setNewItem(false);
    setShowModal(true);
  }

  const onHideModal = async (refresh) => {
    setCurrentId(null);
    setShowModal(false);
    if (refresh) {
      await onLoadUnits();
    }
  }

  useEffect(() => {
    if (dataSource.length === 0) {
      onLoadUnits();
    }
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id
    },
    {
      title: "Unidad",
      dataIndex: "name",
      render: (text) => (
        <span className="text-secondary fw-semibold">{text}</span>
      ),
      sorter: (a, b) => a.name?.localeCompare(b.name)
    },
    {
      title: "Nombre corto",
      dataIndex: "abbreviation",
      align: "center",
      sorter: (a, b) => a.name?.localeCompare(b.name)
    },
    {
      title: "Cantidad de productos",
      align: "center",
      dataIndex: "productsCount",
      render: (text) => (
        <span className="badge badges-info">{text}</span>
      ),
      sorter: (a, b) => a.productsCount - b.productsCount
    },
    {
      title: "Acción",
      align: "center",
      dataIndex: "actions",
      key: "actions",
      render: (record, index) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <a className="me-2 p-2" onClick={() => onEditItem(index)}>
              <Edit className="feather-edit" />
            </a>
            <a className="confirm-text p-2" onClick={() => showDeleteItemAlert(index, "la unidad de medida")} >
              <Trash2 className="feather-trash-2" />
            </a>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Unidades de medida</h4>
              <h6>Puedes gestionar la información de las unidades de medida del sistema.</h6>
            </div>
          </div>
          <ToolsHead showExportData={true} showExcel={true} onReload={onLoadUnits} />
          <div className="page-btn">
            <a
              className="btn btn-added"
              onClick={onNewItem}
            >
              <PlusCircle className="me-2" />
              Nueva Unidad
            </a>
          </div>
        </div>
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Búsqueda rápida"
                    className="form-control form-control-sm formsearch"
                    onChange={e => onChangeName(e)}
                    onBlur={onLoadUnits}
                    disabled={loading}
                  />
                  <a className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </a>
                </div>
                <OverlayTrigger placement="top" overlay={renderSearchTooltip}>
                  <a className="link-primary">
                    <HelpCircle />
                  </a>
                </OverlayTrigger>
              </div>
            </div>
            <div className="table-responsive">
              <Table
                className="table"
                rowKey={(record) => record?.id}
                columns={columns}
                loading={loading}
                dataSource={dataSource}
                size="small"
                pagination={
                  {
                    position: ["topRight"],
                    total: dataSource.length,
                    showTotal: (total) => `Total: ${total}`,
                    showSizeChanger: true
                  }
                }
              />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
      {newItem
        ?
        showModal
          ? <AddCatalog id="add-unit" show={showModal} onHide={onHideModal} type='unit' isNew={true} />
          : null
        :
        showModal
          ? <AddCatalog id="edit-unit" show={showModal} onHide={onHideModal} type='unit' currentId={currentId} />
          : null
      }
    </div>
  );
};

