import React, { useState } from "react";
import "./styles.scss";
import {
    subMonths,
    addMonths,
    startOfWeek,
    addDays,
    isSameDay,
    lastDayOfWeek,
    getWeek,
    addWeeks,
    subWeeks
} from "date-fns";
import PropTypes from 'prop-types';
import FeatherIcon from "feather-icons-react";
import { formatearFecha } from "../../../core/utils";

const Attendance = ({ showDetailsHandle }) => {
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
    const [selectedDate, setSelectedDate] = useState(new Date());

    const changeMonthHandle = (btnType) => {
        if (btnType === "prev") {
            setCurrentMonth(subMonths(currentMonth, 1));
        }
        if (btnType === "next") {
            setCurrentMonth(addMonths(currentMonth, 1));
        }

        setCurrentWeek(getWeek(currentMonth));
    };

    const changeWeekHandle = (btnType) => {
        //console.log("current week", currentWeek);
        if (btnType === "prev") {
            //console.log(subWeeks(currentMonth, 1));
            setCurrentMonth(subWeeks(currentMonth, 1));
        }
        if (btnType === "next") {
            //console.log(addWeeks(currentMonth, 1));
            setCurrentMonth(addWeeks(currentMonth, 1));
        }

        setCurrentWeek(getWeek(currentMonth));
    };

    const onDateClickHandle = (day, dayStr) => {
        setSelectedDate(day);
        showDetailsHandle(dayStr);
    };

    const renderHeader = () => {
        const dateFormat = "MMMM yyyy";
        // console.log("selected day", selectedDate);
        return (
            <>
                <div className="row mb-3">
                    <div className="col">
                        <div className="ingress">
                            <FeatherIcon icon="arrow-down-circle" />
                            <span>Entrada</span>
                        </div>
                    </div>
                    <div className="col">
                        <div className="egress">
                            <FeatherIcon icon="arrow-up-circle" />
                            <span>Salida</span>
                        </div>
                    </div>
                    <div className="col">
                        <div className="vacation">
                            <FeatherIcon icon="arrow-right-circle" />
                            <span>Vacaciones</span>
                        </div>
                    </div>
                    <div className="col">
                        <div className="comment">
                            <FeatherIcon icon="message-circle" />
                            <span>Justificación</span>
                        </div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col col-start">
                        <button
                            className="btn btn-primary"
                            type="button"
                            data-container="body"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Mes anterior"
                            onClick={() => changeMonthHandle("prev")}>
                            <FeatherIcon icon="chevrons-left" />
                        </button>
                    </div>
                    <div className="col col-center">
                        <span>{formatearFecha(currentMonth.toISOString(), dateFormat)}</span>
                    </div>
                    <div className="col col-end">
                        <button
                            className="btn btn-primary"
                            type="button"
                            data-container="body"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Mes siguiente" onClick={() => changeMonthHandle("next")}>
                            <FeatherIcon icon="chevrons-right" />
                        </button>
                    </div>
                </div>
            </>
        );
    };

    const renderDays = () => {
        const dateFormat = "EEE";
        const days = [
            <div className="col-2 col-center" key={"user"}>
                Empleados
            </div>
        ];
        let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="col col-center" key={i}>
                    {formatearFecha(addDays(startDate, i).toISOString(), dateFormat)}
                </div>
            );
        }
        return <div className="row days">{days}</div>;
    };

    const renderCells = () => {
        const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
        const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
        const dateFormat = "d";
        const rows = [];
        let days = [
            <div className="col-2 cell" key={1}>
                <FeatherIcon className="number" icon="users" size={16} />
                <div className="row users"><span>Admin</span></div>
                <div className="row users"><span>Mauricio Gonzalez</span></div>
                <div className="row users"><span>Jesus Santos</span></div>
            </div>
        ];
        let day = startDate;
        let formattedDate = "";
        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = formatearFecha(day.toISOString(), dateFormat);
                const cloneDay = day;
                days.push(
                    <div
                        className={`col cell ${isSameDay(day, new Date())
                            ? "today"
                            : isSameDay(day, selectedDate)
                                ? "selected"
                                : ""
                            }`}
                        key={day}
                        onClick={() => {
                            const dayStr = formatearFecha(cloneDay.toISOString(), "dd MMM yy ccc");
                            onDateClickHandle(cloneDay, dayStr);
                        }}
                    >
                        <span className="number">{formattedDate}</span>
                        <span className="bg">{formattedDate}</span>
                        <div className="row hours">
                            <div className="col-xl-6 col-lg-12 col-md-4 ingress">
                                <FeatherIcon icon="arrow-down-circle" />
                                <span>07:00am</span>
                            </div>
                            <div className="col-xl-6 col-lg-12 col-md-4 egress">
                                <FeatherIcon icon="arrow-up-circle" />
                                <span>05:35pm</span>
                            </div>
                        </div>
                        <div className="row hours">
                            <div className="col-6 ingress">
                                <FeatherIcon icon="arrow-down-circle" />
                                <span>07:00am</span>
                            </div>
                            <div className="col-6 egress">
                                <FeatherIcon icon="arrow-up-circle" />
                                <span>05:35pm</span>
                            </div>
                        </div>
                        <div className="row hours">
                            <div className="col-6 ingress">
                                <FeatherIcon icon="arrow-down-circle" />
                                <span>07:00am</span>
                            </div>
                            <div className="col-6 egress">
                                <FeatherIcon icon="arrow-up-circle" />
                                <span>05:35pm</span>
                            </div>
                        </div>
                    </div>
                );
                day = addDays(day, 1);
            }

            rows.push(
                <div className="row" key={day}>
                    {days}
                </div>
            );
            days = [];
        }
        return <div className="body mb-3">{rows}</div>;
    };
    const renderFooter = () => {
        return (
            <>
                <div className="col col-start">
                    <button
                        type="button"
                        className="btn btn-outline-primary me-1 mb-1"
                        data-container="body"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Semana anterior"
                        onClick={() => changeWeekHandle("prev")}>
                        <FeatherIcon icon="chevron-left" />
                    </button>
                </div>
                <div className="col col-center">
                    {"semana " + currentWeek}
                </div>
                <div className="col col-end">
                    <button
                        type="button"
                        className="btn btn-outline-primary me-1 mb-1"
                        data-container="body"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Semana siguiente"
                        onClick={() => changeWeekHandle("next")}>
                        <FeatherIcon icon="chevron-right" />
                    </button>
                </div>
            </>
        );
    };
    return (
        <div className="calendar-attendance">
            {renderHeader()}
            {renderDays()}
            {renderCells()}
            {renderFooter()}
        </div>
    );
};


Attendance.propTypes = {
    showDetailsHandle: PropTypes.func
};

export default Attendance;
