import React, { useEffect, useState } from 'react'
import ImageWithBasePath from '../../core/img/imagewithbasebath';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Filter from 'feather-icons-react/build/IconComponents/Filter';
import ToolsHead from '../components/toolsHead';
import Attendance from '../components/calendar';
import { formatearFecha, noOptionsText } from '../../core/utils';
import { useDispatch, useSelector } from 'react-redux';
import { all_routes } from '../../Router/all_routes';
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setHolidayList, setShowRemoteResponseToast } from '../../core/redux/action';
import { showInternalErrorAlert } from '../components/customAlert';
import { Holidays } from '../../services/internal/holidays';
import { Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { HelpCircle, X } from 'react-feather';
import FilterControl from '../components/filterControl';
import CheckInModal from '../../core/modals/hrm/checkIn';

const AttendanceEmployee = () => {

    const userDetails = useSelector((state) => state.userDetails);
    const holidayList = useSelector((state) => state.holidayList);
    const loading = useSelector((state) => state.loading);
    const token = useSelector((state) => state.token);
    const dispatch = useDispatch();
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [showCheckInStartModal, setShowCheckInStartModal] = useState(false);
    const [showCheckInEndModal, setShowCheckInEndModal] = useState(false);
    const [hora, setHora] = useState(new Date());
    const route = all_routes;

    const renderSearchTooltip = (props) => (
        <Tooltip id="search-tooltip" {...props}>
            Ingresa el nombre del usuario
        </Tooltip>
    );

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure(err.response.data));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadHolidays = async () => {
        try {

            let filters = {};
            filters.enabled = true
            dispatch(fetchDataRequest());
            const response = await Holidays.getList(token, filters);
            //console.log(response)
            dispatch(fetchDataSuccess(response.data.success));
            dispatch(setHolidayList(response.data.data));
        }
        catch (err) {
            handleException(err);
        }
    }

    const toggleFilterVisibility = () => {
        setIsFilterVisible((prevVisibility) => !prevVisibility);
    };

    const obtenerDiasDelMesActual = () => {
        const fechaActual = new Date();
        const año = fechaActual.getFullYear();
        const mes = fechaActual.getMonth() + 1;
        return new Date(año, mes, 0).getDate();
    }

    const filtrarFechas = () => {
        const date = new Date();
        const mesFiltro = date.getMonth() + 1;
        const anioFiltro = date.getFullYear();

        return holidayList.filter(fechaObj => {
            const fechaInicio = new Date(fechaObj.start); // Convertimos fechaInicio a Date
            const fechaFin = new Date(fechaObj.end);       // Convertimos fechaFin a Date

            const mesFechaInicio = fechaInicio.getMonth() + 1;
            const anioFechaInicio = fechaInicio.getFullYear();

            const mesFechaFin = fechaFin.getMonth() + 1;
            const anioFechaFin = fechaFin.getFullYear();

            return (mesFechaInicio === mesFiltro && anioFechaInicio === anioFiltro) ||
                (mesFechaFin === mesFiltro && anioFechaFin === anioFiltro);
        });
    };

    const calculateName = () => {
        if (!userDetails) {
            return "";
        }

        let fullName = `${userDetails.name ? userDetails.name : ''} ${userDetails.surname ? userDetails.surname : ''}`;
        return fullName.trim() ? fullName : userDetails.alias;
    }

    useEffect(() => {
        const temporizador = setInterval(() => {
            setHora(new Date());
        }, 1000);

        if (holidayList.length === 0) {
            onLoadHolidays();
        }

        return () => {
            clearInterval(temporizador);
        };
    }, []);

    return (
        <div>
            <div className="page-wrapper">
                <div className="content">
                    <div className="attendance-header">
                        <div className="attendance-content">
                            <ImageWithBasePath
                                src="./assets/img/icons/hand01.svg"
                                className="hand-img"
                                alt="img"
                            />
                            <h3>
                                Hola, <span>{calculateName()}</span>
                            </h3>
                        </div>
                    </div>
                    <div className="attendance-widget">
                        <div className="row">
                            <div className="col-xl-4 col-lg-12 col-md-4 d-flex">
                                <div className="card w-100">
                                    <div className="card-body">
                                        <h5>
                                            Asistencia<span>{formatearFecha(hora.toISOString(), "dd-MMMM-yyyy")}</span>
                                        </h5>
                                        <div className="card attendance">
                                            <div>
                                                <ImageWithBasePath src="./assets/img/icons/time-big.svg" alt="time-img" />
                                            </div>
                                            <div>
                                                <h2>{formatearFecha(hora.toISOString(), "hh:mm:ss aa")}</h2>
                                                <p>Hora actual</p>
                                            </div>
                                        </div>
                                        <div className="modal-attendance-btn flex-column flex-lg-row">
                                            <button
                                                className="btn btn-primary w-100"
                                                onClick={() => { setShowCheckInStartModal(true) }}
                                                disabled={loading}
                                            >
                                                Check In
                                            </button>
                                            <button
                                                className="btn btn-secondary w-100"
                                                onClick={() => { setShowCheckInEndModal(true) }}
                                                disabled={loading}
                                            >
                                                Check Out
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-12 col-md-8 d-flex">
                                <div className="card w-100">
                                    <div className="card-body">
                                        <h5>Resumen del mes</h5>
                                        <ul className="widget-attend">
                                            <li className="box-attend">
                                                <div className="warming-card">
                                                    <h4>{obtenerDiasDelMesActual()}</h4>
                                                    <h6>Días</h6>
                                                </div>
                                            </li>
                                            <li className="box-attend">
                                                <div className="danger-card">
                                                    <h4>{obtenerDiasDelMesActual() - filtrarFechas().length}</h4>
                                                    <h6>Días laborales</h6>
                                                </div>
                                            </li>
                                            <li className="box-attend">
                                                <div className="light-card">
                                                    <h4>{loading ? <Spinner size='sm' /> : filtrarFechas().length}</h4>
                                                    <Link to={route.holidays}><h6>Días festivos</h6></Link>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Control de Asistencia</h4>
                            </div>
                        </div>
                        <ToolsHead showExportData={true} showExcel={true} />
                    </div>
                    {/* /product list */}
                    <div className="card table-list-card">
                        <div className="card-body pb-0">
                            <div className="table-top">
                                <div className="search-set">
                                    <div className="search-input">
                                        <input
                                            type="text"
                                            placeholder="Búsqueda rápida"
                                            className="form-control form-control-sm formsearch"
                                            //onChange={e => onChangeName(e)}
                                            //onBlur={onLoadArticles}
                                            disabled={loading}
                                        />
                                        <a className="btn btn-searchset">
                                            <i data-feather="search" className="feather-search" />
                                        </a>
                                    </div>
                                    <OverlayTrigger placement="top" overlay={renderSearchTooltip}>
                                        <a className="link-primary">
                                            <HelpCircle />
                                        </a>
                                    </OverlayTrigger>
                                </div>
                                <div className="search-path">
                                    <div
                                        className={`btn btn-filter ${isFilterVisible ? "setclose" : ""}`}
                                        id="filter_search"
                                        onClick={toggleFilterVisibility}
                                    >
                                        <Filter className="filter-icon"
                                        />
                                        <span>
                                            <X className="filter-icon" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/* /Filter */}
                            <div
                                className={`card${isFilterVisible ? " visible" : ""}`}
                                id="filter_inputs"
                                style={{ display: isFilterVisible ? "block" : "none" }}
                            >
                                <div className="card-body pb-0">
                                    <div className="row">
                                        <div className="col-lg-4 col-sm-4 col-12 mb-3">
                                            <Select
                                                options={[]}
                                                placeholder="Elige una categoría"
                                                //onChange={(value) => setFilterCategory(value)}
                                                noOptionsMessage={() => noOptionsText}
                                                components={{ Control: FilterControl }}
                                                isDisabled={loading}
                                                isClearable
                                            //isMulti
                                            />
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-3">
                                            <Select
                                                options={[]}
                                                //onChange={(value) => setFilterUnit(value)}
                                                noOptionsMessage={() => noOptionsText}
                                                isClearable
                                                placeholder="Elige una unidad de medida"
                                                components={{ Control: FilterControl }}
                                                isDisabled={loading}
                                            />
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-3">
                                            <button
                                                className="btn btn-outline-primary w-100"
                                                //onClick={() => onLoadArticles()}
                                                disabled={loading}
                                            >
                                                <span data-feather="check" className="feather-check" />Aplicar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Filter */}
                            <div className="table-responsive pt-3">
                                <Attendance showDetailsHandle={() => { }} />
                            </div>
                        </div>
                    </div>
                    {/* /product list */}
                </div>
            </div>
            {
                showCheckInStartModal &&
                <CheckInModal id="checkin-start-modal" show={showCheckInStartModal} onHide={() => setShowCheckInStartModal(false)} />
            }
            {
                showCheckInEndModal &&
                <CheckInModal id="checkin-end-modal" show={showCheckInEndModal} onHide={() => setShowCheckInEndModal(false)} isCheckOut={true} />
            }
        </div>
    )
}

export default AttendanceEmployee
