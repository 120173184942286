import React, { useState } from "react";
import PropTypes from "prop-types";

const CustomPagination = ({ totalNumber = 1, rowsNumber = 1, onClickPage }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const pageNumber = rowsNumber > 0
        ? (totalNumber % rowsNumber === 0 ? totalNumber / rowsNumber : Math.floor(totalNumber / rowsNumber) + 1)
        : 1;
    const items = new Array(pageNumber).fill(0);
    const onClickItem = (index) => {
        onClickPage(index);
        setCurrentIndex(index)
    }

    const onClickNext = () => {
        let index = currentIndex >= pageNumber - 1 ? pageNumber - 1 : currentIndex + 1
        onClickPage(index);
        setCurrentIndex(index);
    }

    const onClickBack = () => {
        let index = currentIndex <= 0 ? 0 : currentIndex - 1

        onClickPage(index);
        setCurrentIndex(index);
    }

    return (
        <div className="container-fluid">
            <div className="row custom-pagination">
                <div className="col-md-12">
                    <div className="paginations d-flex justify-content-end mb-3">
                        <a>
                            <span>
                                <i className="fas fa-chevron-left" onClick={onClickBack} />
                            </span>
                        </a>
                        <ul className="d-flex align-items-center page-wrap">
                            {items.map((ii, i) =>
                            (
                                <li key={"page-" + i} >
                                    <a
                                        className={currentIndex === i ? "active" : ""}
                                        onClick={() => onClickItem(i)}>
                                        {i + 1}
                                    </a>
                                </li>
                            )
                            )}
                        </ul>
                        <a>
                            <span>
                                <i className="fas fa-chevron-right" onClick={onClickNext} />
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

CustomPagination.propTypes = {
    totalNumber: PropTypes.number.isRequired,
    rowsNumber: PropTypes.number.isRequired,
    onClickPage: PropTypes.func.isRequired,
};

export default CustomPagination;
