import { axiosClientMp } from '../../axiosClientConfiguration';
import { postRequest } from '../../axiosFunctions';

const abortController = new AbortController();

const baseConfig = {
  signal: abortController.signal,
  headers: {
    "Accept": 'application/json',
  }
};

export const setToken = (token) => {
  let config = baseConfig;
  config.headers["Authorization"] = `Bearer ${token}`;
  return config;
}

const requestDataToken = {
  client_id: undefined,
  client_secret: undefined,
  code: undefined,
  code_verifier: undefined,
  grant_type: undefined,
  redirect_uri: undefined,
  refresh_token: undefined,
  test_token: undefined
}

const getToken = (data) => {
  return postRequest(
    axiosClientMp,
    `/oauth/token`,
    data,
    baseConfig
  );
}

export const MpOauth = {
  requestDataToken,
  getToken,
};
