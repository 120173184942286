import React from "react";
import PropTypes from 'prop-types';
import { Toast, ToastContainer } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataErrors, setShowRemoteResponseToast } from "../../../core/redux/action";

const RemoteReponseToast = ({ position = "top-center", delay = 3000, autohide = true }) => {
    const serverErrors = useSelector(state => state.serverErrors);
    const errorData = useSelector(state => state.errorData);
    const result = useSelector(state => state.result);
    const showToast = useSelector(state => state.showRemoteResponseToast);
    const dispatch = useDispatch();

    const onShowToast = (show) => {
        if (show === false) {
            dispatch(fetchDataErrors([]));
        }
        dispatch(setShowRemoteResponseToast(show));
    }

    const calculateError = () => {
        if (result === "OK") {
            return "Operación exitosa";
        }

        if (serverErrors?.length) {
            if (serverErrors.length === 1) {
                return serverErrors[0];
            }

            if (serverErrors.status) {
                if (serverErrors.status.length > 0) {
                    return serverErrors.status[0];
                }
            }

            return "Fallo de operación";
        }

        if (errorData) {
            if (errorData.statusText) {
                return errorData.statusText;
            }

            return errorData.message;
        }

        return "Error desconocido.";
    }

    return (
        <ToastContainer
            className="position-fixed p-3"
            position={position}
        >
            <Toast
                show={showToast}
                onClose={() => onShowToast(false)}
                id="liveToast"
                className={
                    result === "OK"
                        ? "toast alert alert-success custom-alert-icon"
                        : "toast alert alert-warning custom-alert-icon"}
                role="alert"
                delay={delay}
                autohide={autohide}>
                <Toast.Header>
                    <i
                        className={
                            result === "OK"
                                ? "feather-check-circle link-success flex-shrink-0 me-2"
                                : "feather-alert-triangle link-warning flex-shrink-0 me-2"}
                    />
                    <h6 className="me-auto">{calculateError()}</h6>
                </Toast.Header>
                {
                    serverErrors?.length > 1
                        ?
                        <Toast.Body>
                            {serverErrors?.map((err) => {
                                <li>{err}</li>
                            })}
                        </Toast.Body>
                        : null
                }
            </Toast>
        </ToastContainer>
    );
};

RemoteReponseToast.propTypes = {
    position: PropTypes.string,
    delay: PropTypes.number,
    autohide: PropTypes.bool
};


export default RemoteReponseToast;