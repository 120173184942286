import React from "react";
import PropTypes from "prop-types";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";



const ExporData = ({ showPdf = false, showPrint = false, showExcel = false, onPrint }) => {
    const renderTooltip = (props) => (
        <Tooltip id="pdf-tooltip" {...props}>
            Exportar a Pdf
        </Tooltip>
    );
    const renderExcelTooltip = (props) => (
        <Tooltip id="excel-tooltip" {...props}>
            Exportar a Excel
        </Tooltip>
    );
    const renderPrinterTooltip = (props) => (
        <Tooltip id="printer-tooltip" {...props}>
            Imprimir
        </Tooltip>
    );
    return (
        <>
            {showPdf ?
                <li>
                    <OverlayTrigger placement="top" overlay={renderTooltip}>
                        <a>
                            <ImageWithBasePath src="assets/img/icons/pdf.svg" alt="img" />
                        </a>
                    </OverlayTrigger>
                </li>
                : null}
            {showExcel ?
                <li>
                    <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                        <a>
                            <ImageWithBasePath
                                src="assets/img/icons/excel.svg"
                                alt="img"
                            />
                        </a>
                    </OverlayTrigger>
                </li>
                : null}
            {showPrint ?
                <li>
                    <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>
                        <a onClick={onPrint}>
                            <i data-feather="printer" className="feather-printer" />
                        </a>
                    </OverlayTrigger>
                </li>
                : null}
        </>
    );
};

ExporData.propTypes = {
    showPdf: PropTypes.bool,
    showPrint: PropTypes.bool,
    showExcel: PropTypes.bool,
    onPrint: PropTypes.func,
};

export default ExporData;
