import React, { useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { all_routes } from "../../Router/all_routes";
import { useState } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import {
  Edit,
  Grid,
  List,
  MoreVertical,
  PlusCircle,
  HelpCircle,
  Filter,
  ExternalLink,
} from "react-feather";
import * as Icon from 'react-feather'
import Select from "react-select";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ToolsHead from "../components/toolsHead";
import { Table } from "antd";
import CustomPagination from "../components/customPagination";
import { fechaFormat, formatearFecha, noOptionsText } from "../../core/utils";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setDepartmentOptions, setDesignationOptions, setShowRemoteResponseToast, setStoreOptions, setUserList } from "../../core/redux/action";
import { useDispatch, useSelector } from "react-redux";
import { showInternalErrorAlert, showSuccessAlert } from "../components/customAlert";
import FilterControl from "../components/filterControl";
import { Users } from "../../services/internal/users";
import Loader from "../loader/loader";
import { Catalogs } from "../../services/internal/catalogs";


const EmployeesGrid = () => {
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading);
  const token = useSelector((state) => state.token);
  const dataSource = useSelector((state) => state.userList);
  const designationOptions = useSelector((state) => state.designationOptions);
  const departmentOptions = useSelector((state) => state.departmentOptions);
  const storeOptions = useSelector((state) => state.storeOptions);
  const [gridActive, setGridActive] = useState(true);
  const [filterName, setFilterName] = useState("");
  const [filterStore, setFilterStore] = useState("");
  const [filterDepartment, setFilterDepartment] = useState("");
  const [filterEstatus, setFilterEstatus] = useState(null);
  const [filterDesignation, setFilterDesignation] = useState("");
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const route = all_routes;
  const estatusOptions = [{ label: "Activo", value: true }, { label: "Inactivo", value: false }];

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const renderSearchTooltip = (props) => (
    <Tooltip id="search-tooltip" {...props}>
      Ingresa el nombre del empleado a su número
    </Tooltip>
  );

  const setChangeView = (e, active) => {
    e?.preventDefault();
    setGridActive(active)
  };

  const onChangeName = (e) => {
    setFilterName(e.target.value.trim())
  }

  const handleException = (err) => {
    console.log(err);
    if (err.response) {
      dispatch(fetchDataFailure(err.response.data));
      if (err.response.status >= 400 && err.response.status < 500) {
        dispatch(fetchDataErrors(err.response.data.errors));
        dispatch(setShowRemoteResponseToast(true));
        return;
      } else {
        showInternalErrorAlert(false, err.response.statusText);
        return;
      }
    }
    dispatch(fetchDataFailure(err));
    showInternalErrorAlert(true, err.code);
  }

  const onLoadUsers = async () => {

    let filters = {};

    if (filterName) {
      filters.name = filterName;
    }
    if (filterStore) {
      filters.branchId = filterStore.value;
    }
    if (filterDepartment) {
      filters.areaId = filterDepartment.value;
    }
    if (filterDesignation) {
      filters.designationId = filterDesignation.value;
    }

    try {
      dispatch(fetchDataRequest());
      if (storeOptions.length === 0) {
        const responseBranches = await Catalogs.getOptions(token, Catalogs.Name.Branches);
        dispatch(setStoreOptions(responseBranches.data.data));
      }

      if (departmentOptions.length === 0) {
        const responseAreas = await Catalogs.getOptions(token, Catalogs.Name.Areas);
        dispatch(setDepartmentOptions(responseAreas.data.data));
      }

      if (designationOptions.length === 0) {
        const responseDesignations = await Catalogs.getOptions(token, Catalogs.Name.Designations);
        dispatch(setDesignationOptions(responseDesignations.data.data));
      }

      if (filterEstatus) {
        filters.enabled = filterEstatus.value;
      }
      //console.log(response)
      const responseUsers = await Users.getList(token, filters);
      dispatch(setUserList(responseUsers.data.data));
      dispatch(fetchDataSuccess(responseUsers.data.success));
    }
    catch (err) {
      handleException(err);
    }
  }

  const onDeleteItem = async (id, enabled) => {
    try {
      dispatch(fetchDataRequest());
      const response = await Users.toggleSingle(token, id, !enabled);
      //console.log(response)
      dispatch(fetchDataSuccess(response.data.success));
      showSuccessAlert(enabled ? "Hemos desactivado al usuario." : "Hemos activado al usuario.");
    }
    catch (err) {
      handleException(err);
    }
    await onLoadUsers();
  }

  const showDeleteItemAlert = async (index, text) => {
    const result = await MySwal.fire({
      title: "¿Estás seguro?",
      html: `Vamos a desactivar ${text}.`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
      customClass: {
        confirmButton: "btn btn-warning",
      },
    });

    if (result.isConfirmed) {
      await onDeleteItem(index.id, index.enabled);
    } else {
      MySwal.close();
    }
  };

  const calculateName = (index) => {
    let item = dataSource.find(i => i.id === index);
    let fullName = `${item.name ? item.name : ''} ${item.surname ? item.surname : ''} ${item.lastname ? item.lastname : ''}`;
    return fullName.trim() ? fullName : item.alias;
  }

  const calculateImagePath = (index) => {
    let item = dataSource.find(i => i.id === index);
    return item?.imagePath ? item?.imagePath : "assets/img/profiles/profile.png";
  }

  useEffect(() => {
    if (dataSource.length === 0) {
      onLoadUsers();
    }
  }, []);

  const columns = [
    {
      title: "Empleado",
      dataIndex: "name",
      render: (text, record) => (
        <span className="productimgname">
          <Link key={record.id} to={`${route.profile}?code=${record.id}`} className="product-img stock-img">
            <ImageWithBasePath alt="avatar" src={calculateImagePath(record.id)} />
          </Link>
          <Link to={`${route.profile}?code=${record.id}`} className="text-primary fw-semibold">{calculateName(record.id)}</Link>
        </span>
      ),
      sorter: (a, b) => a.name?.localeCompare(b.name)
    },
    {
      title: "Alias",
      dataIndex: "alias",
      render: (text) => (
        <span className="text-orange fw-semibold">{text}</span>
      ),
      sorter: (a, b) => a.designation?.localeCompare(b.designation)
    },
    {
      title: "Sucursal",
      dataIndex: "branch",
      render: (text, record) => (
        <span className="text-primary-emphasis fw-semibold">{record.area?.name ?? "Sin asignar"}</span>
      ),
      sorter: (a, b) => a.area?.localeCompare(b.area)
    },
    {
      title: "Área",
      dataIndex: "area",
      render: (text, record) => (
        <span className="text-secondary fw-semibold">{record.area?.name ?? "Sin asignar"}</span>
      ),
      sorter: (a, b) => a.area?.localeCompare(b.area)
    },
    {
      title: "Puesto",
      dataIndex: "designation",
      align: "center",
      render: (text, record) => (
        <span className="text-info fw-semibold">{record.designation?.name ?? "Sin asignar"}</span>
      ),
      sorter: (a, b) => a.designation?.localeCompare(b.designation)
    },
    {
      title: "Correo electrónico",
      dataIndex: "email",
      align: "center",
      sorter: (a, b) => a.designation?.localeCompare(b.designation)
    },
    {
      title: "Estatus",
      align: "center",
      dataIndex: "enabled",
      render: (record) => (
        <span
          className={`badge ${record ? "badge-linesuccess" : "badge-linedanger"
            }`}
        >
          {record ? "Activo" : "Inactivo"}
        </span>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Acción",
      align: "center",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link className="me-2 p-2" to={`${route.profile}?code=${record.id}`}>
              <Icon.Eye />
            </Link>
            <Link className="me-2 p-2" to={`${route.editemployee}?code=${record.id}`}>
              <Edit className="feather-edit" />
            </Link>
            <a
              className="dropdown-item confirm-text mb-0"
              onClick={() => showDeleteItemAlert(record, "un usuario")}
            >
              {record.enabled ? <Icon.ToggleLeft className="feather-toggle-left" /> : <Icon.ToggleRight className="feather-toggle-right" />}
            </a>
          </div>
        </div>
      )
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Usuarios</h4>
              <h6>Puedes gestionar la información de los usuarios registrados en el sistema.</h6>
            </div>
          </div>
          <ToolsHead showExportData={true} showExcel={true} onReload={onLoadUsers} />
          <div className="page-btn">
            <Link to={route.addemployee} className="btn btn-added">
              <PlusCircle className="me-2" />
              Nuevo Usuario
            </Link>
          </div>
        </div>
        {/* /product list */}
        <div className="card">
          <div className="card-body pb-0">
            <div className="table-top table-top-new">
              <div className="search-set mb-0">
                <div className="total-employees">
                  <h6>
                    <Icon.Users />
                    Total de Usuarios  <span>{dataSource.length}</span>
                  </h6>
                </div>
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Búsqueda rápida"
                    className="form-control form-control-sm formsearch"
                    onChange={e => onChangeName(e)}
                    onBlur={onLoadUsers}
                    disabled={loading}
                  />
                  <a className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </a>
                </div>
                <OverlayTrigger placement="top" overlay={renderSearchTooltip}>
                  <a className="link-primary">
                    <HelpCircle />
                  </a>
                </OverlayTrigger>
              </div>
              <div className="search-path d-flex align-items-center search-path-new">
                <div className="d-flex">
                  <div
                    className={`btn btn-filter ${isFilterVisible ? "setclose" : ""}`}
                    id="filter_search"
                    onClick={toggleFilterVisibility}
                  >
                    <Filter className="filter-icon"
                    />
                    <span>
                      <Icon.X className="filter-icon" />
                    </span>
                  </div>
                  <a className={!gridActive ? "btn-list active" : "btn-list"} onClick={e => setChangeView(e, false)}>
                    <List />
                  </a>
                  <a className={gridActive ? "btn-grid active" : "btn-grid"} onClick={e => setChangeView(e, true)}>
                    <Grid />
                  </a>
                </div>
              </div>
            </div>
            {/* /Filter */}
            <div
              className={`card${isFilterVisible ? " visible" : ""}`}
              id="filter_inputs"
              style={{ display: isFilterVisible ? "block" : "none" }}
            >
              <div className="row">
                <div className="col-lg-3 col-sm-6 col-12 mb-3">
                  <Select
                    options={storeOptions}
                    placeholder="Elige una sucursal"
                    onChange={(value) => setFilterStore(value)}
                    noOptionsMessage={() => noOptionsText}
                    components={{ Control: FilterControl }}
                    isDisabled={loading}
                    isClearable
                  />
                </div>
                <div className="col-lg-3 col-sm-6 col-12 mb-3">
                  <Select
                    options={departmentOptions}
                    placeholder="Elige una área"
                    onChange={(value) => setFilterDepartment(value)}
                    noOptionsMessage={() => noOptionsText}
                    components={{ Control: FilterControl }}
                    isDisabled={loading}
                    isClearable
                  />
                </div>
                <div className="col-lg-3 col-sm-6 col-12 mb-3">
                  <Select
                    options={designationOptions}
                    placeholder="Elige un puesto"
                    onChange={(value) => setFilterDesignation(value)}
                    noOptionsMessage={() => noOptionsText}
                    components={{ Control: FilterControl }}
                    isDisabled={loading}
                    isClearable
                  />
                </div>
                <div className="col-lg-3 col-sm-6 col-12 mb-3">
                  <Select
                    options={estatusOptions}
                    placeholder="Elige un estatus"
                    onChange={(value) => setFilterEstatus(value)}
                    noOptionsMessage={() => noOptionsText}
                    components={{ Control: FilterControl }}
                    isDisabled={loading}
                    isClearable
                  />
                </div>
                <div className="col-lg-3 col-sm-6 col-12 mb-3">
                  <button
                    className="btn btn-outline-primary w-100"
                    onClick={() => onLoadUsers()}
                    disabled={loading}
                  >
                    <span data-feather="check" className="feather-check" />Aplicar
                  </button>
                </div>
              </div>
            </div>
            {/* /Filter */}

            {/* /product list */}
            {gridActive
              ?
              (loading
                ? <Loader />
                : <>
                  <div className="employee-grid-widget">
                    <div className="row">
                      {dataSource.map((item) => (
                        <div key={item.id} className="col-xxl-4 col-xl-4 col-lg-6 col-md-6">
                          <div className="employee-grid-profile">
                            <div className="profile-head">
                              <h5 className="text-info fw-bold">{item.branch?.name ?? "Sucursal sin asignar"}</h5>
                              <div className="profile-head-action">
                                <span className={item.enabled ? "badge badge-linesuccess text-center w-auto me-1" : "badge badge-linedanger text-center w-auto me-1"}>
                                  {item.enabled ? "Activo" : "Inactivo"}
                                </span>
                                <div className="dropdown profile-action">
                                  <a
                                    className="action-icon dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <MoreVertical />
                                  </a>
                                  <ul className="dropdown-menu">
                                    <li>
                                      <Link
                                        to={`${route.profile}?code=${item.id}`}
                                        className="dropdown-item"
                                      >
                                        <ExternalLink />
                                        Ver
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to={`${route.editemployee}?code=${item.id}`}
                                        className="dropdown-item"
                                      >
                                        <Edit />
                                        Editar
                                      </Link>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item confirm-text mb-0"
                                        onClick={() => showDeleteItemAlert(item, "un usuario")}
                                      >
                                        {item.enabled ? <Icon.ToggleLeft className="info-img" /> : <Icon.ToggleRight className="info-img" />}
                                        {item.enabled ? "Desactivar" : "Activar"}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="profile-info">
                              <div className="profile-pic active-profile">
                                <ImageWithBasePath
                                  src={calculateImagePath(item.id)}
                                  alt="avatar"
                                />
                              </div>
                              <h5>Núm. de empleado : {item.employeeId ?? 'Sin asignar'}</h5>
                              <h4>{calculateName(item.id)}</h4>
                              <span className="text-secondary">{item.designation?.name ?? 'Sin puesto'}</span>
                            </div>
                            <ul className="department">
                              <li>
                                Fecha de ingreso
                                <span>{formatearFecha(item.joinDate, fechaFormat)}</span>
                              </li>
                              <li>
                                Área
                                <span>{item.area?.name ?? 'Sin asignar'}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <CustomPagination totalNumber={dataSource.length} rowsNumber={10} onClickPage={() => { }} />
                </>
              )
              :
              <div className="table-responsive">
                <Table
                  className="table"
                  rowKey={(record) => record?.id}
                  columns={columns}
                  loading={loading}
                  dataSource={dataSource}
                  size="small"
                  pagination={
                    {
                      position: ["topRight"],
                      total: dataSource.length,
                      showTotal: (total) => `Total: ${total}`,
                      showSizeChanger: true
                    }
                  }
                />
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeesGrid;
