import React from "react";
import StockAjustment from "../stock/stockAdjustment";

const ExpiredProduct = () => {

    return (
        <StockAjustment />
    );
};

export default ExpiredProduct;
