import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Edit, HelpCircle, PlusCircle, Trash2 } from 'react-feather';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import ToolsHead from '../components/toolsHead/index.jsx';
import { Table } from 'antd';
import AddCatalog from '../../core/modals/inventory/addcatalog.jsx';
import { LeaveTypes } from '../../services/internal/leaveTypes/index.js';
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setLeaveTypeList, setShowRemoteResponseToast } from '../../core/redux/action.jsx';
import { showInternalErrorAlert, showSuccessAlert } from '../components/customAlert/index.jsx';

const UserLeaveTypes = () => {
    const dataSource = useSelector((state) => state.leaveTypeList);
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loading);
    const token = useSelector((state) => state.token);
    const [filterName, setFilterName] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [newItem, setNewItem] = useState(false);
    const [currentId, setCurrentId] = useState(null);

    const onChangeName = (e) => {
        setFilterName(e.target.value.trim())
    }

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure(err.response.data));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadLeaveTypes = async () => {

        let filters = {};
        filters.enabled = true;
        if (filterName) {
            filters.name = filterName;
        }

        try {
            dispatch(fetchDataRequest());
            const response = await LeaveTypes.getList(token, filters);
            //console.log(response)
            dispatch(fetchDataSuccess(response.data.success));
            dispatch(setLeaveTypeList(response.data.data));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onDeleteItem = async (id) => {
        try {
            dispatch(fetchDataRequest());
            const response = await LeaveTypes.deleteSingle(token, id);
            //console.log(response)
            dispatch(fetchDataSuccess(response.data.success));
            showSuccessAlert("Hemos eliminado el tipo de justificación.");
        }
        catch (err) {
            handleException(err);
        }
        await onLoadLeaveTypes();
    }


    const showDeleteItemAlert = async (index, text) => {
        const result = await MySwal.fire({
            title: "¿Estás seguro?",
            html: `Vamos a eliminar ${text}, esta acción es <strong>irreversible</strong>.`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Confirmar",
            customClass: {
                confirmButton: "btn btn-warning",
            },
        });

        if (result.isConfirmed) {
            await onDeleteItem(index.id);
        } else {
            MySwal.close();
        }
    };

    const onNewItem = () => {
        setCurrentId(null);
        setNewItem(true);
        setShowModal(true);
    }

    const onEditItem = (index) => {
        setCurrentId(index.id);
        setNewItem(false);
        setShowModal(true);
    }

    const onHideModal = async (refresh) => {
        setCurrentId(null);
        setShowModal(false);
        if (refresh) {
            await onLoadLeaveTypes();
        }
    }

    useEffect(() => {
        if (dataSource.length === 0) {
            onLoadLeaveTypes();
        }
    }, []);

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            sorter: (a, b) => a.id - b.id
        },
        {
            title: "Justificación",
            dataIndex: "name",
            render: (text) => (
                <span className="text-secondary fw-semibold">{text}</span>
            ),
            sorter: (a, b) => a.name?.localeCompare(b.name)
        },
        {
            title: "Descripción",
            dataIndex: "description",
            sorter: (a, b) => a.description?.localeCompare(b.description)
        },
        {
            title: "Acción",
            dataIndex: "actions",
            align: "center",
            key: "actions",
            render: (record, index) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        <a className="me-2 p-2" onClick={() => onEditItem(index)}>
                            <Edit className="feather-edit" />
                        </a>
                        <a className="confirm-text p-2" onClick={() => showDeleteItemAlert(index, "un rol")} >
                            <Trash2 className="feather-trash-2" />
                        </a>
                    </div>
                </div>
            ),
        },
    ];

    const renderSearchTooltip = (props) => (
        <Tooltip id="search-tooltip" {...props}>
            Ingresa el nombre de la justificación
        </Tooltip>
    );

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Tipos de justificación</h4>
                            <h6>Puedes gestionar los tipos de justificación para saber el motivo cuando  un empleado no ha  realizado su check de asistencia.</h6>
                        </div>
                    </div>
                    <ToolsHead showExportData={true} showExcel={true} onReload={onLoadLeaveTypes} />
                    <div className="page-btn">
                        <a
                            className="btn btn-added"
                            onClick={onNewItem}
                        >
                            <PlusCircle className="me-2" />
                            Nuevo tipo
                        </a>
                    </div>
                </div>
                {/* /product list */}
                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input
                                        type="text"
                                        placeholder="Búsqueda rápida"
                                        className="form-control form-control-sm formsearch"
                                        onChange={e => onChangeName(e)}
                                        onBlur={onLoadLeaveTypes}
                                        disabled={loading}
                                    />
                                    <a className="btn btn-searchset">
                                        <i data-feather="search" className="feather-search" />
                                    </a>
                                </div>
                                <OverlayTrigger placement="top" overlay={renderSearchTooltip}>
                                    <a className="link-primary">
                                        <HelpCircle />
                                    </a>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <Table
                                className="table"
                                rowKey={(record) => record?.id}
                                columns={columns}
                                loading={loading}
                                dataSource={dataSource}
                                size="small"
                                pagination={
                                    {
                                        position: ["topRight"],
                                        total: dataSource.length,
                                        showTotal: (total) => `Total: ${total}`,
                                        showSizeChanger: true
                                    }
                                }
                            />
                        </div>
                    </div>
                </div>
                {/* /product list */}
            </div>

            {newItem
                ?
                showModal
                    ? <AddCatalog id="add-leave" show={showModal} onHide={onHideModal} type='leave' isNew={true} />
                    : null
                :
                showModal
                    ? <AddCatalog id="edit-leave" show={showModal} onHide={onHideModal} type='leave' currentId={currentId} />
                    : null
            }
        </div>
    )
}

export default UserLeaveTypes;