import { setToken } from '../authentication';
import { axiosClientApi } from '../../axiosClientConfiguration';
import { getRequest, postRequest, putRequest } from '../../axiosFunctions';

const requestData = {
  id: null,
  area: {
    id: null,
    name: null,
    description: null,
    enabled: true,
    idResponsible: null,
    usersCount: null
  },
  designation: {
    id: null,
    name: null,
    description: null,
    enabled: true,
    usersCount: null
  },
  branch: {
    id: null,
    name: null,
    workshiftId: null,
    location: null,
    email: null,
    phone: null,
    enabled: true,
    code: null
  },
  name: null,
  surname: null,
  lastname: null,
  joinDate: null,
  employeeId: null,
  email: null,
  phone: null,
  curp: null,
  alias: null,
  imagePath: null,
  address: null,
  neigborhood: null,
  town: null,
  state: {
    id: null,
    name: null,
    code: null,
    enabled: true
  },
  zipCode: null,
  taxId: null,
  businessName: null,
  taxZipCode: null,
  enabled: true,
  role: {
    id: null,
    name: null,
    description: null,
    enabled: true
  }
}

const getSingle = (token, id) => {
  return getRequest(
    axiosClientApi,
    `/Users/Get/${id}`,
    setToken(token)
  );
}

const getList = (token, filters) => {
  let config = setToken(token);
  config.params = {
    areaId: filters.areaId,
    designationId: filters.designationId,
    branchId: filters.branchId,
    name: filters.name,
    surname: filters.surname,
    lastName: filters.lastName,
    joinDate: filters.joinDate,
    employeeId: filters.employeeId,
    email: filters.email,
    alias: filters.alias,
    taxId: filters.taxId,
    enabled: filters.enabled
  };
  return getRequest(
    axiosClientApi,
    "/Users/List",
    config
  );
}

const postSingle = (token, data) => {
  return postRequest(
    axiosClientApi,
    "/Users",
    data,
    setToken(token)
  );
}

const putSingle = (token, data) => {
  return putRequest(
    axiosClientApi,
    "/Users",
    data,
    setToken(token)
  );
}

const toggleSingle = (token, id, enabled) => {
  return putRequest(
    axiosClientApi,
    `/Users/ToggleEnabled/${id}/${enabled}`,
    setToken(token)
  );
}

export const Users = {
  requestData,
  getSingle,
  getList,
  postSingle,
  putSingle,
  toggleSingle,
};
