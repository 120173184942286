export const leavetypedata = [
	{
		"id": 1,
		"name": "Enfermedad",
		"leavequota": "05",
		"createdon": "02 Ago 2023",
		"status": true,

	},
	{
		"id": 2,
		"name": "Maternidad",
		"leavequota": "05",
		"createdon": "03 Ago 2023",
		"status": true,

	},
	{
		"id": 3,
		"name": "Paternidad",
		"leavequota": "05",
		"createdon": "04 Ago 2023",
		"status": true,

	},
	{
		"id": 4,
		"name": "Permiso sin goce de sueldo",
		"leavequota": "05",
		"createdon": "07 Ago 2023",
		"status": false,

	},
	{
		"id": 5,
		"name": "Emergencia",
		"leavequota": "05",
		"createdon": "08 Ago 2023",
		"status": true,

	},
	{
		"id": 6,
		"name": "Vacaciones",
		"leavequota": "05",
		"createdon": "10 Ago 2023",
		"status": true,

	}
]