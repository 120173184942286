import { setToken } from '../authentication';
import { axiosClientApi } from '../../axiosClientConfiguration';
import { deleteRequest, getRequest, postRequest, putRequest } from '../../axiosFunctions';

const requestData = {
  id: undefined,
  name: undefined,
  description: undefined,
  enabled: undefined
}

const getSingle = (token, id) => {
  return getRequest(
    axiosClientApi,
    `/ExpiredTypes/Get/${id}`,
    setToken(token)
  );
}

const getList = (token, filters) => {
  let config = setToken(token);
  config.params = {
    name: filters.name,
    enabled: filters.enabled,
  };
  return getRequest(
    axiosClientApi,
    "/ExpiredTypes/List",
    config
  );
}

const postSingle = (token, data) => {
  return postRequest(
    axiosClientApi,
    "/ExpiredTypes",
    data,
    setToken(token)
  );
}

const putSingle = (token, data) => {
  return putRequest(
    axiosClientApi,
    "/ExpiredTypes",
    data,
    setToken(token)
  );
}

const deleteSingle = (token, id) => {
  return deleteRequest(
    axiosClientApi,
    `/ExpiredTypes/Delete/${id}`,
    setToken(token)
  );
}

export const ExpiredTypes = {
  requestData,
  getSingle,
  getList,
  postSingle,
  putSingle,
  deleteSingle,
};
