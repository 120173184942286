import React from "react";
import PropTypes from 'prop-types';
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { fechaHoraFormat, formatearFecha, formatearMoneda, IVA } from "../../../core/utils";
import QRCode from "react-qr-code";

const Receipt = ({ innerRef, saleData }) => {

    return (
        <div
            id="print-receipt"
            className="card m-3"
        >
            <div className="row modal-body m-1"
                id="print-receipt-content"
                ref={innerRef}
            >
                <div className="icon-head text-center">
                    <a>
                        <ImageWithBasePath
                            src="assets/img/logo.png"
                            width={100}
                            height={30}
                            alt="Receipt Logo"
                        />
                    </a>
                </div>
                <div className="text-center">
                    <h6>Grupo Rodos SA. de CV.</h6>
                    <p className="mb-0">Telefono: +52 1 5656665656</p>
                    <p className="mb-0">
                        Email: <Link to="mailto:example@gmail.com">contacto@gruporodos.mx</Link>
                    </p>
                </div>
                <div className="tax-invoice">
                    <h6 className="text-center">Recibo</h6>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="invoice-user-name">
                                <span>Le atendió: </span>
                                <span>{saleData?.user?.alias}</span>
                            </div>
                            <div className="invoice-user-name">
                                <span>Código: </span>
                                <span>{saleData?.code}</span>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="invoice-user-name">
                                <span>Cliente: </span>
                                <span>{saleData?.client?.name}</span>
                            </div>
                            <div className="invoice-user-name">
                                <span>Fecha: </span>
                                <span>{formatearFecha(saleData?.registered, fechaHoraFormat)}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <table className="table-borderless w-100 table-fit">
                    <thead>
                        <tr>
                            <th className="text-center">Cant.</th>
                            <th className="text-center">Unidad</th>
                            <th>Producto</th>
                            <th className="text-end">P.U.</th>
                            <th className="text-end">Subtotal</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            saleData?.packages?.map(p => (
                                <tr key={p?.id}>
                                    <td className="text-center">{p?.quantity}</td>
                                    <td className="text-center">Paq.</td>
                                    <td>{p?.package?.name}</td>
                                    <td className="text-end">{formatearMoneda(p?.package?.unitPrice)}</td>
                                    <td className="text-end">{formatearMoneda(p?.package.unitPrice * p?.quantity)}</td>
                                </tr>
                            ))
                        }
                        {
                            saleData?.articles?.map((a) => {
                                if (a?.subtotal != 0) {
                                    return (
                                        <tr key={a?.id}>
                                            <td className="text-center">{a?.quantity}</td>
                                            <td className="text-center">{a?.article?.unmed?.abbreviation}</td>
                                            <td>{a?.article?.name}</td>
                                            <td className="text-end">{formatearMoneda(a?.subtotal / a?.quantity)}</td>
                                            <td className="text-end">{formatearMoneda(a?.subtotal)}</td>
                                        </tr>
                                    );
                                }
                            })
                        }
                        <tr>
                            <td colSpan={5}>
                                <table className="table-borderless w-100 table-fit">
                                    <tbody>
                                        <tr>
                                            <td>Subtotal :</td>
                                            <td className="text-end">{formatearMoneda(saleData?.total)}</td>
                                        </tr>
                                        <tr>
                                            <td>IVA {IVA > 0 ? `(${IVA * 100}%)` : ""} :</td>
                                            <td className="text-end">{IVA > 0 ? formatearMoneda(saleData?.total * IVA) : "Incluido"} { }</td>
                                        </tr>
                                        <tr>
                                            <td>Total :</td>
                                            <td className="text-end">{formatearMoneda(saleData?.total)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="text-center invoice-bar">
                    <p>Método de pago: {saleData?.paymentMethod?.name}</p>
                    <p><QRCode size={100} value={`https://gruporodos.mx/?saleCode=${saleData?.id}`} /></p>
                    <p>Gracias por su compra, siempre es un placer atenderle.</p>
                </div>
            </div>
        </div>
    );
};

Receipt.propTypes = {
    innerRef: PropTypes.object.isRequired,
    saleData: PropTypes.object.isRequired
};

export default Receipt;
