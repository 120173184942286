import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { all_routes } from "../../../Router/all_routes";

const MySwal = withReactContent(Swal);

export const showInternalErrorAlert = async (sentError, code) => {
    const word = sentError ? 'envío' : 'procesamiento';
    const htmlMessage = `<p>Occurió un error durante el ${word} de la información.</p><p><small>Contacta al Administrador y propociona este código: <strong>${code}</strong></small></p><p><strong>Lo resolveremos a la brevedad posible.<strong></p>`;

    const result = await MySwal.fire({
        title: "Algo salió mal... ",
        html: htmlMessage,
        icon: "error",
        confirmButtonText: "Regresar",
        customClass: {
            confirmButton: "btn btn-danger",
        },
    });

    if (result.isConfirmed) {
        MySwal.close();
    }
};


export const showForgotPasswordAlert = async (navigate) => {
    const htmlMessage = `<p>Te hemos enviado un correo electrónico con la información para reestablecer tu contraseña.</p>`;

    const result = await MySwal.fire({
        title: "Ya casi...",
        html: htmlMessage,
        icon: "success",
        confirmButtonText: "De acuerdo",
        customClass: {
            confirmButton: "btn btn-success",
        },
    });

    if (result.isConfirmed) {
        MySwal.close();
        navigate(all_routes.signin)
    }
};

export const showResetPasswordAlert = async (navigate) => {
    const htmlMessage = `<p>Hemos actualizado tu contraseña exitosamente.</p>`;

    const result = await MySwal.fire({
        title: "Bien hecho!",
        html: htmlMessage,
        icon: "success",
        confirmButtonText: "Iniciar sesion",
        customClass: {
            confirmButton: "btn btn-success",
        },
    });

    if (result.isConfirmed) {
        MySwal.close();
        navigate(all_routes.signin)
    }
};

export const showSuccessAlert = async (text) => {
    const result = await MySwal.fire({
        title: "¡Genial!",
        icon: "success",
        text: text,
        confirmButtonText: "Continuar",
        customClass: {
            confirmButton: "btn btn-success",
        },
    });

    if (result.isConfirmed) {
        MySwal.close();
    }
};