import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PlusCircle, HelpCircle, Edit, Shield, Trash2 } from "react-feather";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { all_routes } from "../../Router/all_routes";
import ToolsHead from "../components/toolsHead";
import AddCatalog from "../../core/modals/inventory/addcatalog";
import { Roles } from "../../services/internal/roles";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setRoleList, setShowRemoteResponseToast } from "../../core/redux/action";
import { showInternalErrorAlert, showSuccessAlert } from "../components/customAlert";
import { Table } from "antd";

const RolesPermissions = () => {
  const dataSource = useSelector((state) => state.roleList);
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading);
  const token = useSelector((state) => state.token);
  const [filterName, setFilterName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [newItem, setNewItem] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const route = all_routes;

  const onChangeName = (e) => {
    setFilterName(e.target.value.trim())
  }

  const handleException = (err) => {
    console.log(err);
    if (err.response) {
      dispatch(fetchDataFailure(err.response.data));
      if (err.response.status >= 400 && err.response.status < 500) {
        dispatch(fetchDataErrors(err.response.data.errors));
        dispatch(setShowRemoteResponseToast(true));
        return;
      } else {
        showInternalErrorAlert(false, err.response.statusText);
        return;
      }
    }
    dispatch(fetchDataFailure(err));
    showInternalErrorAlert(true, err.code);
  }

  const onLoadRoles = async () => {

    let filters = {};
    filters.enabled = true;
    if (filterName) {
      filters.name = filterName;
    }

    try {
      dispatch(fetchDataRequest());
      const response = await Roles.getList(token, filters);
      //console.log(response)
      dispatch(fetchDataSuccess(response.data.success));
      dispatch(setRoleList(response.data.data));
    }
    catch (err) {
      handleException(err);
    }
  }

  const onDeleteItem = async (id) => {
    try {
      dispatch(fetchDataRequest());
      const response = await Roles.deleteSingle(token, id);
      //console.log(response)
      dispatch(fetchDataSuccess(response.data.success));
      showSuccessAlert("Hemos eliminado el rol.");
    }
    catch (err) {
      handleException(err);
    }
    await onLoadRoles();
  }


  const showDeleteItemAlert = async (index, text) => {
    const result = await MySwal.fire({
      title: "¿Estás seguro?",
      html: `Vamos a eliminar ${text}, esta acción es <strong>irreversible</strong>.`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
      customClass: {
        confirmButton: "btn btn-warning",
      },
    });

    if (result.isConfirmed) {
      await onDeleteItem(index.id);
    } else {
      MySwal.close();
    }
  };

  const onNewItem = () => {
    setCurrentId(null);
    setNewItem(true);
    setShowModal(true);
  }

  const onEditItem = (index) => {
    setCurrentId(index.id);
    setNewItem(false);
    setShowModal(true);
  }

  const onHideModal = async (refresh) => {
    setCurrentId(null);
    setShowModal(false);
    if (refresh) {
      await onLoadRoles();
    }
  }

  useEffect(() => {
    if (dataSource.length === 0) {
      onLoadRoles();
    }
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id
    },
    {
      title: "Rol",
      dataIndex: "name",
      render: (text) => (
        <span className="text-secondary fw-semibold">{text}</span>
      ),
      sorter: (a, b) => a.name?.localeCompare(b.name)
    },
    {
      title: "Descripción",
      dataIndex: "description",
      sorter: (a, b) => a.description?.localeCompare(b.description)
    },
    {
      title: "Cantidad de Usuarios",
      dataIndex: "usersCount",
      align: "center",
      render: (text) => (
        <span className="badge badges-info">{text}</span>
      ),
      sorter: (a, b) => a.usersCount - b.usersCount
    },
    {
      title: "Acción",
      dataIndex: "actions",
      align: "center",
      key: "actions",
      render: (record, index) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <a className="me-2 p-2" onClick={() => onEditItem(index)}>
              <Edit className="feather-edit" />
            </a>
            <Link className="me-2 p-2" to={route.permissions + `?code=${index.id}`}>
              <Shield className="feather-shield" />
            </Link>
            <a className="confirm-text p-2" onClick={() => showDeleteItemAlert(index, "un rol")} >
              <Trash2 className="feather-trash-2" />
            </a>
          </div>
        </div>
      ),
    },
  ];

  const renderSearchTooltip = (props) => (
    <Tooltip id="search-tooltip" {...props}>
      Ingresa el nombre del rol
    </Tooltip>
  );

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Lista de Roles</h4>
                <h6>Puedes administrar los roles del sistema.</h6>
              </div>
            </div>
            <ToolsHead showExportData={true} showExcel={true} onReload={onLoadRoles} />
            <div className="page-btn">
              <a
                to="#"
                className="btn btn-added"
                onClick={onNewItem}
              >
                <PlusCircle className="me-2" />
                Nuevo Rol
              </a>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Búsqueda rápida"
                      className="form-control form-control-sm formsearch"
                      onChange={e => onChangeName(e)}
                      onBlur={onLoadRoles}
                      disabled={loading}
                    />
                    <a className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </a>
                  </div>
                  <OverlayTrigger placement="top" overlay={renderSearchTooltip}>
                    <a className="link-primary">
                      <HelpCircle />
                    </a>
                  </OverlayTrigger>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <Table
                  className="table"
                  rowKey={(record) => record?.id}
                  columns={columns}
                  loading={loading}
                  dataSource={dataSource}
                  size="small"
                  pagination={
                    {
                      position: ["topRight"],
                      total: dataSource.length,
                      showTotal: (total) => `Total: ${total}`,
                      showSizeChanger: true
                    }
                  }
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      {newItem
        ?
        showModal
          ? <AddCatalog id="add-role" show={showModal} onHide={onHideModal} type='role' isNew={true} />
          : null
        :
        showModal
          ? <AddCatalog id="edit-role" show={showModal} onHide={onHideModal} type='role' currentId={currentId} />
          : null
      }
    </div>
  );
};

export default RolesPermissions;
