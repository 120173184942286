import React, { useEffect, useState } from "react";
import { Filter, HelpCircle, Package, ShoppingBag, Shuffle, X } from "react-feather";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import Select from "react-select";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import AddPurchases from "../../core/modals/purchases/addpurchases";
import { useDispatch, useSelector } from "react-redux";
import { all_routes } from "../../Router/all_routes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ToolsHead from "../components/toolsHead";
import { fechaHoraFormat, formatearFecha, generarCodigo, noOptionsText } from "../../core/utils";
import EditStock from "../../core/modals/inventory/editstock";
import FilterControl from "../components/filterControl";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setCategoryOptions, setShowRemoteResponseToast, setStockList, setStoreOptions } from "../../core/redux/action";
import { showInternalErrorAlert } from "../components/customAlert";
import { Table } from "antd";
import { Catalogs } from "../../services/internal/catalogs";
import { StockManagement } from "../../services/internal/stockManagement";

const Managestock = () => {
  const dataSource = useSelector((state) => state.stockList);
  const loading = useSelector((state) => state.loading);
  const token = useSelector(state => state.token);
  const categoryOptions = useSelector(state => state.categoryOptions);
  const storeOptions = useSelector(state => state.storeOptions);
  const dispatch = useDispatch();
  const [showEditStockModal, setShowEditStockModal] = useState(false);
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [filterName, setFilterName] = useState(null);
  const [filterStore, setFilterStore] = useState(null);
  const [filterCategory, setFilterCategory] = useState(null);
  const [filterStock, setFilterStock] = useState(null);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const route = all_routes;
  const stockOptions = [
    { value: 0, label: "Todos" },
    { value: 1, label: "De 0 a 9 existencias" },
    { value: 2, label: "De 10 a 100 existencias" },
    { value: 3, label: "Más de 100 existencias" },
  ];

  const onChangeName = (e) => {
    setFilterName(e.target.value.trim())
  }

  const handleException = (err) => {
    console.log(err);
    if (err.response) {
      dispatch(fetchDataFailure(err.response.data));
      if (err.response.status >= 400 && err.response.status < 500) {
        dispatch(fetchDataErrors(err.response.data.errors));
        dispatch(setShowRemoteResponseToast(true));
        return;
      } else {
        showInternalErrorAlert(false, err.response.statusText);
        return;
      }
    }
    dispatch(fetchDataFailure(err));
    showInternalErrorAlert(true, err.code);
  }

  const onLoadCatalogs = async () => {
    try {
      dispatch(fetchDataRequest());

      const responseA = await Catalogs.getOptions(token, Catalogs.Name.Branches);
      dispatch(setStoreOptions(responseA.data.data));

      const responseB = await Catalogs.getOptions(token, Catalogs.Name.Categories);
      dispatch(setCategoryOptions(responseB.data.data));

      dispatch(fetchDataSuccess(responseB.data.success));
    }
    catch (err) {
      handleException(err);
    }
  }

  const setStockQtyFilters = (filterStockId, filters) => {
    switch (filterStockId) {
      case 1:
        filters["range.min"] = null;
        filters["range.max"] = 9;
        break;
      case 2:
        filters["range.min"] = 10;
        filters["range.max"] = 99;
        break;
      case 3:
        filters["range.min"] = 100;
        filters["range.max"] = null;
        break;
      default:
        filters["range.min"] = null;
        filters["range.max"] = null;
        break;
    }

    return filters;
  }

  const onLoadStock = async () => {
    let filters = {};
    if (filterName) {
      filters.articleName = filterName;
    }
    if (filterStore) {
      filters.branchId = filterStore.value;
    }
    if (filterCategory) {
      filters.categoryId = filterCategory.value;
    }
    if (filterStock) {
      filters = setStockQtyFilters(filterStock.value, filters);
    }

    try {
      dispatch(fetchDataRequest());
      const response = await StockManagement.getList(token, filters);
      //console.log(response)
      dispatch(setStockList(response.data.data));
      dispatch(fetchDataSuccess(response.data.success));
    }
    catch (err) {
      handleException(err);
    }
  }

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const onHideEditStockModal = async () => {
    setShowEditStockModal(false);
    await onLoadStock();
  }

  const onNewPurchase = () => {
    setShowPurchaseModal(true);
  }

  const onNewTransfer = () => {
    setShowTransferModal(true);
  }

  const onHidePurchaseModal = async (refresh) => {
    setShowPurchaseModal(false);
    if (refresh) {
      await onLoadStock();
    }
  }

  const onHideTransferModal = async (refresh) => {
    setShowTransferModal(false);
    if (refresh) {
      await onLoadStock();
    }
  }

  useEffect(() => {
    if (storeOptions.length === 0 || categoryOptions.length === 0) {
      onLoadCatalogs();
    }
    onLoadStock();
  }, []);

  const columns = [
    {
      title: "Sucursal",
      dataIndex: "branch.name",
      render: (text, record) => (
        <span className="text-primary-emphasis fw-semibold">{record.branch?.name}</span>
      ),
      sorter: (a, b) => a.branch?.name?.localeCompare(b.branch?.name)
    },
    {
      title: "Categoría",
      dataIndex: "article.category.name",
      render: (text, record) => (
        <span className="text-secondary fw-semibold">{record.article?.category?.name}</span>
      ),
      sorter: (a, b) => a.article?.category?.name?.localeCompare(b.article?.category?.name)
    },
    {
      title: "Producto",
      dataIndex: "article.id",
      render: (text, record) => (
        <span className="productimgname">
          <Link key={record.article?.id} to={`${route.productdetails}?code=${record.article?.id}`} className="product-img stock-img">
            <ImageWithBasePath
              alt="producto"
              src={record.article?.imageUrls?.length > 0
                ? record.article?.imageUrls[0]
                : "assets/img/product/default.png"}
            />
          </Link>
          <Link to={`${route.productdetails}?code=${record.article?.id}`} className="text-primary fw-semibold">{record.article?.name}</Link>
        </span>
      ),
      sorter: (a, b) => a.article?.id - b.article?.id
    },
    {
      title: "Código de producto",
      dataIndex: "article.code",
      align: 'center',
      render: (text, record) => (
        <span className="text-orange">{record.article?.code}</span>
      ),
      sorter: (a, b) => a.article?.code?.localeCompare(b.article?.code)
    },
    {
      title: "Unidad de medida",
      dataIndex: "article.unmed.abbreviation",
      align: 'center',
      render: (text, record) => (
        <span>{record.article?.unmed?.abbreviation}</span>
      ),
      sorter: (a, b) => a.article?.unmed?.abbreviation?.localeCompare(b.article?.unmed?.abbreviation)
    },
    {
      title: "Existencias",
      dataIndex: "quantity",
      align: 'center',
      render: (record) => (
        <>
          {record <= 10 && (
            <span className="badges bg-danger">{record}</span>
          )}
          {record > 10 && record <= 100 && (
            <span className="badges bg-warning">{record}</span>
          )}
          {record > 100 && (
            <span className="badges bg-success">{record}</span>
          )}
        </>
      ),
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: "Última compra",
      dataIndex: "stockOperationId",
      align: 'center',
      render: (text, record) => (
        <span>{record.registered ? formatearFecha(record.registered ?? new Date().toISOString(), fechaHoraFormat) : "Aún no hay datos"}</span>
      ),
      sorter: (a, b) => a.stockOperationId?.localeCompare(b.stockOperationId)
    },
  ];

  const renderSearchTooltip = (props) => (
    <Tooltip id="search-tooltip" {...props}>
      Ingresa el nombre del producto
    </Tooltip>
  );

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Existencia de productos</h4>
              <h6>Puedes consultar las existencias de los productos disponibles en el sistema.</h6>
            </div>
          </div>
          <ToolsHead showExportData={true} showExcel={true} onReload={onLoadStock} />
          <div className="page-btn">
            <a
              className="btn btn-added color"
              onClick={() => setShowEditStockModal(true)}
            >
              <Package className="me-2 iconsize" />
              Ajustar inventario
            </a>
          </div>
          <div className="page-btn">
            <a
              className="btn btn-added"
              onClick={() => onNewPurchase()}
            >
              <ShoppingBag className="me-2 iconsize" />
              Nueva orden de Compra
            </a>
          </div>
          <div className="page-btn">
            <a
              className="btn btn-added"
              onClick={() => onNewTransfer()}
            >
              <Shuffle className="me-2 iconsize" />
              Transferir entre sucursales
            </a>
          </div>
        </div>
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Búsqueda rápida"
                    className="form-control form-control-sm formsearch"
                    onChange={e => onChangeName(e)}
                    onBlur={onLoadStock}
                    disabled={loading}
                  />
                  <a className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </a>
                </div>
                <OverlayTrigger placement="top" overlay={renderSearchTooltip}>
                  <a className="link-primary">
                    <HelpCircle />
                  </a>
                </OverlayTrigger>
              </div>
              <div className="search-path">
                <div
                  className={`btn btn-filter ${isFilterVisible ? "setclose" : ""}`}
                  id="filter_search"
                  onClick={toggleFilterVisibility}
                >
                  <Filter className="filter-icon"
                  />
                  <span>
                    <X className="filter-icon" />
                  </span>
                </div>
              </div>
            </div>
            {/* /Filter */}
            <div
              className={`card${isFilterVisible ? " visible" : ""}`}
              id="filter_inputs"
              style={{ display: isFilterVisible ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-3 col-sm-3 col-12 mb-3">
                    <Select
                      options={storeOptions}
                      placeholder="Elige una sucursal"
                      onChange={(value) => setFilterStore(value)}
                      noOptionsMessage={() => noOptionsText}
                      components={{ Control: FilterControl }}
                      isDisabled={loading}
                      isClearable
                    />
                  </div>
                  <div className="col-lg-3 col-sm-3 col-12 mb-3">
                    <Select
                      options={categoryOptions}
                      onChange={(value) => setFilterCategory(value)}
                      noOptionsMessage={() => noOptionsText}
                      isClearable
                      placeholder="Elige una categoría"
                      components={{ Control: FilterControl }}
                      isDisabled={loading}
                    />
                  </div>
                  <div className="col-lg-3 col-sm-3 col-12 mb-3">
                    <Select
                      options={stockOptions}
                      onChange={(value) => setFilterStock(value)}
                      noOptionsMessage={() => noOptionsText}
                      isClearable
                      placeholder="Elige un rango de existencias"
                      components={{ Control: FilterControl }}
                      isDisabled={loading}
                    />
                  </div>
                  <div className="col-lg-3 col-sm-3 col-12 mb-3">
                    <button
                      className="btn btn-outline-primary w-100"
                      onClick={() => onLoadStock()}
                      disabled={loading}
                    >
                      <span data-feather="check" className="feather-check" />Aplicar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* /Filter */}
            <div className="table-responsive">
              <Table
                className="table"
                rowKey={() => generarCodigo()}
                columns={columns}
                loading={loading}
                dataSource={dataSource}
                size="small"
                pagination={
                  {
                    position: ["topRight"],
                    total: dataSource.length,
                    showTotal: (total) => `Total: ${total}`,
                    showSizeChanger: true
                  }
                }
              />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
      {
        showPurchaseModal
          ? <AddPurchases id="add-purchase" show={showPurchaseModal} onHide={onHidePurchaseModal} isNew={true} />
          : null
      }
      {
        showTransferModal
          ? <AddPurchases id="add-transfer" show={showTransferModal} isTransfer={true} onHide={onHideTransferModal} isNew={true} />
          : null
      }
      {
        showEditStockModal
          ? <EditStock id="edit-stock" show={showEditStockModal} onHide={onHideEditStockModal} />
          : null
      }
    </div>
  );
};

export default Managestock;
