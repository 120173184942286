import { axiosClientMp } from '../../axiosClientConfiguration';
import { getRequest, postRequest } from '../../axiosFunctions';
import { setToken } from '../oauth';

const requestDataIntent = {
  additional_info: {
    external_reference: undefined,
    print_on_terminal: undefined
  },
  amount: undefined
}

const getDevices = (token, filters) => {
  let config = setToken(token);
  config.params = {
    store_id: filters.store_id,
    pos_id: filters.pos_id,
    limit: filters.limit,
    offset: filters.offset
  };
  return getRequest(
    axiosClientMp,
    "/point/integration-api/devices",
    config
  );
}

const getIntent = (token, paymentIntentId) => {
  return getRequest(
    axiosClientMp,
    `/point/integration-api/payment-intents/${paymentIntentId}`,
    setToken(token)
  );
}

const postIntent = (token, deviceId, data) => {
  return postRequest(
    axiosClientMp,
    `/point/integration-api/devices/${deviceId}/payment-intents`,
    data,
    setToken(token)
  );
}

export const MpPoint = {
  requestDataIntent,
  getDevices,
  getIntent,
  postIntent,
};
