export const CustomerData = [
  {
    CustomerName: "Thomas",
    Code: "201",
    Customer: "MEMX030303000",
    Email: "thomas@exmple.com",
    Phone: "+12163547758",
    Country: "31000",
    Status: true
  },
  {
    CustomerName: "Rose",
    Code: "201",
    Customer: "XEXX010101000",
    Email: "rose@exmple.com",
    Phone: "+11367529510",
    Country: "29000",
    Status: true
  },
  {
    CustomerName: "Benjamin",
    Code: "203",
    Customer: "CACX020202000",
    Email: "benjamin@exmple.com",
    Phone: "+15362789414",
    Country: "31000",
    Status: true
  },
  {
    CustomerName: "Kaitlin",
    Code: "204",
    Customer: "LOPX040404000",
    Email: "kaitlin@exmple.com",
    Phone: "+18513094627",
    Country: "29000",
    Status: true
  },
  {
    CustomerName: "Lilly",
    Code: "205",
    Customer: "REXX050505000",
    Email: "lilly@exmple.com",
    Phone: "+14678219025",
    Country: "28000",
    Status: false
  },
  {
    CustomerName: "Freda",
    Code: "206",
    Customer: "TACX060606000",
    Email: "freda@exmple.com",
    Phone: "+10913278319",
    Country: "29000",
    Status: true
  },
  {
    CustomerName: "Walk-in-Customer",
    Code: "01",
    Customer: "PEXX070707000",
    Email: "customer01@exmple.com",
    Phone: "+19125852947",
    Country: "36000",
    Status: true
  },
  {
    CustomerName: "Maybelle",
    Code: "207",
    Customer: "FOCX080808000",
    Email: "maybelle@exmple.com",
    Phone: "+19125852947",
    Country: "21100",
    Status: false
  },
  {
    CustomerName: "Ellen",
    Code: "208",
    Customer: "GUMX090909000",
    Email: "ellen@exmple.com",
    Phone: "+19756194733",
    Country: "25000",
    Status: true
  },
  {
    CustomerName: "Walk-in-Customer",
    Code: "02",
    Customer: "HIXX101010000",
    Email: "customer02@exmple.com",
    Phone: "+19167850925",
    Country: "21100",
    Status: true
  }
]
