import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import FormCreatedBy from "../../../feature-module/components/createdByForm";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import es from 'date-fns/locale/es';
import { Clock } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import { Workshifts } from "../../../services/internal/workshifts";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setShowRemoteResponseToast } from "../../redux/action";
import { showInternalErrorAlert } from "../../../feature-module/components/customAlert";
import { Controller, useForm } from "react-hook-form";
import { Modal, Spinner } from "react-bootstrap";
import variables from "../../../style/scss/utils/_variables.scss";
import { convertirDateAHoraString, convertirHoraStringADate, horaFormat } from "../../utils";

registerLocale('es', es)

const sd = new Date();
const ed = new Date();
sd.setHours(10, 0, 0, 0);
ed.setHours(20, 0, 0, 0);


const AddShift = ({ id, show = false, isNew = false, currentId, onHide }) => {
    const loading = useSelector(state => state.loading);
    const token = useSelector(state => state.token);
    const dispatch = useDispatch();
    const { setValue, register, handleSubmit, reset, formState: { errors }, control } = useForm();
    const [dataSource] = useState([{
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true
    }]);

    const calculateResponse = (isLoad, data) => {

        if (isNew) {
            return Workshifts.postSingle(token, data);
        }

        if (isLoad) {
            return Workshifts.getSingle(token, data.id);
        }

        return Workshifts.putSingle(token, data);
    }

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure(err.response.data));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onSaveItem = async (formData) => {
        try {
            let data = Workshifts.requestData;
            data = {};
            data.id = currentId;
            data.name = formData.name;
            data.monday = formData.monday;
            data.tuesday = formData.tuesday;
            data.wednesday = formData.wednesday;
            data.thursday = formData.thursday;
            data.friday = formData.friday;
            data.saturday = formData.saturday;
            data.sunday = formData.sunday;
            data.timeIn = convertirDateAHoraString(formData.timeIn);
            data.timeOut = convertirDateAHoraString(formData.timeOut);

            dispatch(fetchDataRequest());

            const response = await calculateResponse(false, data);
            //console.log(response)
            dispatch(fetchDataSuccess(response.data.success));
            dispatch(setShowRemoteResponseToast(true));
            clearForm();
            onModalHide(null, true);
        }
        catch (err) {
            handleException(err);
        }
    }

    const onLoadItem = async () => {
        try {
            let data = Workshifts.requestData;
            data = {};
            data.id = currentId;

            dispatch(fetchDataRequest());

            const response = await calculateResponse(true, data);
            //console.log(response)

            setValue('name', response.data.data.name);
            setValue('timeIn', convertirHoraStringADate(response.data.data.timeIn));
            setValue('timeOut', convertirHoraStringADate(response.data.data.timeOut));
            setValue('monday', response.data.data.monday);
            setValue('tuesday', response.data.data.tuesday);
            setValue('wednesday', response.data.data.wednesday);
            setValue('thursday', response.data.data.thursday);
            setValue('friday', response.data.data.friday);
            setValue('saturday', response.data.data.saturday);
            setValue('sunday', response.data.data.sunday);
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const clearForm = () => {
        reset(formValues => ({
            ...formValues,
            name: null,
            timeIn: null,
            timeOut: null,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false
        }));
    }

    const onModalHide = (e, refresh) => {
        e?.preventDefault();
        clearForm();
        onHide(refresh);
    }

    useEffect(() => {
        if (currentId) {
            onLoadItem();
        }
    }, [currentId]);

    const columns = [
        {
            title: "L",
            dataIndex: "monday",
            align: "center",
            render: () => (
                <input type="checkbox" className="form-check-input" {...register("monday")} />
            ),
        },
        {
            title: "M",
            dataIndex: "tuesday",
            align: "center",
            render: () => (
                <input type="checkbox" className="form-check-input" {...register("tuesday")} />
            ),
        },
        {
            title: "M",
            dataIndex: "wednesday",
            align: "center",
            render: () => (
                <input type="checkbox" className="form-check-input"  {...register("wednesday")} />
            ),
        },
        {
            title: "J",
            dataIndex: "thursday",
            align: "center",
            render: () => (
                <input type="checkbox" className="form-check-input"  {...register("thursday")} />
            ),
        },
        {
            title: "V",
            dataIndex: "friday",
            align: "center",
            render: () => (
                <input type="checkbox" className="form-check-input"  {...register("friday")} />
            ),
        },
        {
            title: "S",
            dataIndex: "saturday",
            align: "center",
            render: () => (
                <input type="checkbox" className="form-check-input"  {...register("saturday")} />
            ),
        },
        {
            title: "D",
            dataIndex: "sunday",
            align: "center",
            render: () => (
                <input type="checkbox" className="form-check-input"  {...register("sunday")} />
            ),
        },
    ];

    /*const onChange = (id, value, type) => {
        dataSource.map(i => {
            if (i.id === id) {
                switch (type) {
                    case 'L':
                        i.Lunes = value
                        break;
                    case 'M':
                        i.Martes = value
                        break;
                    case 'MM':
                        i.Miércoles = value
                        break;
                    case 'J':
                        i.Jueves = value
                        break;
                    case 'V':
                        i.Viernes = value
                        break;
                    case 'S':
                        i.Sábado = value
                        break;
                    case 'D':
                        i.Domingo = value
                        break;
                    default:
                }
            }
        });

        setDataSource(prevDataSource => [...prevDataSource]);
    };*/


    return (
        <Modal show={show} centered onHide={onModalHide} id={id}>
            <form onSubmit={handleSubmit((data) => onSaveItem(data))}>
                <Modal.Header className="custom-modal-header" closeButton={!loading}>
                    <Modal.Title>
                        <h4>{isNew ? "Registrar horario" : "Actualizar horario"}</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12 col-12 mb-3">
                            <label className="form-label">Nombre<span className="text-danger"> *</span></label>
                            <input
                                type="text"
                                maxLength={300}
                                className={!errors.name ? "form-control" : "form-control is-invalid"}
                                disabled={loading}
                                {...register("name", {
                                    required: 'Es necesario el nombre'
                                })}
                            />
                            {
                                errors.name ?
                                    <div className="invalid-feedback">
                                        {errors.name.message}
                                    </div>
                                    : null
                            }
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12 mb-3">
                            <label className="form-label">Hora de entrada<span className="text-danger"> *</span></label>
                            <div className="input-group flex-wrap">
                                <span className="input-group-text"><Clock size={15} color={variables.primaryColor} /></span>
                                <Controller
                                    control={control}
                                    name='timeIn'
                                    render={({ field }) => (
                                        <DatePicker
                                            selected={field.value}
                                            onChange={(date) => field.onChange(date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeFormat={horaFormat}
                                            timeCaption="Hora"
                                            dateFormat={horaFormat}
                                            locale={es}
                                            wrapperClassName={"form-control"}
                                            className={!errors.timeIn ? "form-control" : "form-control is-invalid"}
                                        />
                                    )}
                                    rules={{ required: 'Requerido' }}
                                />
                                {
                                    errors.timeIn ?
                                        <div className="invalid-feedback">
                                            {errors.timeIn.message}
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12 mb-3">
                            <label className="form-label">Hora de salida<span className="text-danger"> *</span></label>
                            <div className="input-group flex-wrap">
                                <span className="input-group-text"><Clock size={15} color={variables.primaryColor} /></span>
                                <Controller
                                    control={control}
                                    name='timeOut'
                                    render={({ field }) => (
                                        <DatePicker
                                            selected={field.value}
                                            onChange={(date) => field.onChange(date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeFormat={horaFormat}
                                            timeCaption="Hora"
                                            dateFormat={horaFormat}
                                            locale={es}
                                            wrapperClassName={"form-control"}
                                            className={!errors.timeOut ? "form-control" : "form-control is-invalid"}
                                        />
                                    )}
                                    rules={{ required: 'Requerido' }}
                                />
                                {
                                    errors.timeOut ?
                                        <div className="invalid-feedback">
                                            {errors.timeOut.message}
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="col-lg-12 col-sm-12 col-12">
                            <center className="form-label">Días activos</center>
                            <div className="table-responsive mb-3">
                                <Table columns={columns} dataSource={dataSource} pagination={false} />
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <FormCreatedBy isNew={isNew} />
                    </div>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <button
                        className="btn btn-cancel me-2"
                        onClick={(e) => onModalHide(e, false)}
                        disabled={loading}
                    >
                        Cancelar
                    </button>
                    {
                        loading ?
                            <button disabled={loading} className="btn btn-submit">
                                <Spinner animation="border" role="status" size="sm" />
                            </button>
                            :
                            <input type="submit" value={isNew ? "Registrar" : "Actualizar"} className="btn btn-submit" />
                    }
                </Modal.Footer>
            </form>
        </Modal>
    );
};

AddShift.propTypes = {
    id: PropTypes.string.isRequired,
    show: PropTypes.bool,
    isNew: PropTypes.bool,
    currentId: PropTypes.number,
    onHide: PropTypes.func
};

export default AddShift;
