import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../img/imagewithbasebath";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setShowRemoteResponseToast } from "../../redux/action";
import { showInternalErrorAlert } from "../../../feature-module/components/customAlert";
import { MpPoint } from "../../../services/mercadoPago/point";
import { formatearMoneda, generarCodigo } from "../../utils";

const MercadoPagoModal = ({ id, show = false, onHide, totalAmount, onSubmit }) => {

    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loading);

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure(err.response.data));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadMpData = async () => {
        try {
            const token = "APP_USR-4484079510968704-100221-b222f5acb67bc3078ceeaf6458bcc893-1985383266";
            //const userId = "1985383266";
            const deviceId = "SMARTPOS1491469826";
            dispatch(fetchDataRequest());
            //const responseS = await MpStores.getStores(token, userId, {external_id: "1"} );
            //const responseD = await MpPoint.getDevices(token, { store_id: "64580580"});
            let dataDevice = MpPoint.requestDataIntent;
            dataDevice = {};
            dataDevice.amount = totalAmount;
            dataDevice.additional_info = {
                external_reference: generarCodigo(),
                print_on_terminal: false
            }
            const response = await MpPoint.postIntent(token, deviceId, dataDevice);
            console.log(response)
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onModalHide = () => {
        onHide();
    }

    useEffect(() => {
        onLoadMpData();
    }, []);

    return (
        <Modal show={show} centered onHide={onModalHide} id={id} backdrop="static" keyboard={false}>
            <Modal.Header className="custom-modal-header" closeButton={!loading}>
            </Modal.Header>
            <Modal.Body className="text-center">
                <Modal.Title className='justify-content-center mb-4'>
                    <ImageWithBasePath
                        style={{height: 100}}
                        src="assets/img/mercadopago/color-azul_hori-izq.svg"
                        alt="Mercado Pago"
                    />
                    <h4>Pago con tarjeta débito/crédito</h4>
                </Modal.Title>
                <span>Vamos a pagar: {formatearMoneda(totalAmount)}</span>
            </Modal.Body>
            <Modal.Footer className='justify-content-center'>
                <button
                    className="btn btn-cancel me-2"
                    onClick={(e) => onModalHide(e, false)}
                    disabled={loading}
                >
                    Regresar
                </button>
                {
                    loading ?
                        <button disabled={loading} className="btn btn-submit">
                            <Spinner animation="border" role="status" size="sm" />
                        </button>
                        :
                        <button
                            className="btn btn-dark"
                            onClick={() => onSubmit()}
                            disabled={loading}>
                            Procesar Orden
                        </button>
                }
            </Modal.Footer>
        </Modal>
    );
};

MercadoPagoModal.propTypes = {
    id: PropTypes.string.isRequired,
    show: PropTypes.bool,
    onHide: PropTypes.func,
    onSubmit: PropTypes.func,
    totalAmount: PropTypes.string.isRequired,
};


export default MercadoPagoModal;
