import React, { useState } from "react";
import PropTypes from "prop-types";
import { AutoComplete, Input } from 'antd';
import { Articles } from '../../../services/internal/articles';
import { Search } from "react-feather";
import { fetchDataRequest, fetchDataSuccess } from "../../../core/redux/action";
import { convertToOptions } from "../../../core/utils";
import { useDispatch, useSelector } from "react-redux";

const SearchProduct = ({ productSelectedCallback, errorCallback, resultProductsCallback }) => {

    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loading);
    const token = useSelector((state) => state.token);
    const [productOptions, setProductOptions] = useState([]);
    const [openDropDown, setOpenDropDown] = useState(false);
    const [searchText, setSearchText] = useState(null);

    const onLoadArticles = async (e) => {
        e?.preventDefault();
        let filters = {};
        filters.enabled = true;
        if (!searchText) {
            return;
        }

        filters.name = searchText;

        try {
            dispatch(fetchDataRequest());
            const response = await Articles.getList(token, filters);
            //console.log(response)

            setProductOptions(convertToOptions(response.data.data));

            if (resultProductsCallback) {
                resultProductsCallback(response.data.data);
            }

            dispatch(fetchDataSuccess(response.data.success));

            setOpenDropDown(true);
        }
        catch (err) {
            errorCallback(err);
        }
    }

    const onSelectProduct = (value, option) => {
        //console.log(option)
        setSearchText(option.label);
        setOpenDropDown(false);
        productSelectedCallback(option);
    }

    const onChangeSerchtext = (value) => {
        if (!value) {
            setOpenDropDown(false);
            productSelectedCallback(null);

            if (resultProductsCallback) {
                resultProductsCallback([]);
            }
        }
        setSearchText(value);
    }

    return (
        <div className="row">
            <div className="col-lg-12 col-sm-12 col-12 mb-3">
                <label className="form-label">Buscar producto<span className="text-danger"> *</span></label>
                <div className="input-group">
                    <AutoComplete
                        options={productOptions}
                        onSelect={onSelectProduct}
                        onChange={onChangeSerchtext}
                        value={searchText}
                        placeholder="Ingresa nombre del producto"
                        className="w-100"
                        disabled={loading}
                        open={openDropDown}
                        notFoundContent="Sin resultados"
                    >
                        <Input
                            className="form-control"
                            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                        />
                    </AutoComplete>
                    <button className="btn btn-primary input-group-text" autoFocus={searchText} onClick={(e) => onLoadArticles(e)}>
                        <Search size={22} />
                    </button>
                </div>
            </div>
        </div>
    );
};

SearchProduct.propTypes = {
    productSelectedCallback: PropTypes.func.isRequired,
    resultProductsCallback: PropTypes.func,
    errorCallback: PropTypes.func.isRequired,
};

export default SearchProduct;
