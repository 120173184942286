import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import FormCreatedBy from "../../../feature-module/components/createdByForm";
import Select from "react-select";
import ImageWithBasePath from "../../img/imagewithbasebath";
import { MapPin, Mail } from "react-feather";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setShiftList, setShowRemoteResponseToast } from "../../redux/action";
import { showInternalErrorAlert } from "../../../feature-module/components/customAlert";
import { Branches } from "../../../services/internal/branches";
import { Controller, useForm } from "react-hook-form";
import { Workshifts } from "../../../services/internal/workshifts";
import variables from "../../../style/scss/utils/_variables.scss";
import { convertirHoraStringADate, customRegex, emailRegex, horaFormat, phoneRegex, urlRegex } from "../../utils";
import { format } from 'date-fns';
import es from 'date-fns/locale/es';

const AddStore = ({ id, show = false, isNew = false, currentId, onHide }) => {
    const loading = useSelector(state => state.loading);
    const token = useSelector(state => state.token);
    const shiftList = useSelector(state => state.shiftList);
    const dispatch = useDispatch();
    const { setValue, register, handleSubmit, reset, formState: { errors }, control } = useForm();
    const [shiftOptions, setShiftOptions] = useState([]);

    const calculateResponse = (isLoad, data) => {

        if (isNew) {
            return Branches.postSingle(token, data);
        }

        if (isLoad) {
            return Branches.getSingle(token, data.id);
        }

        return Branches.putSingle(token, data);
    }

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure(err.response.data));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onSaveItem = async (formData) => {
        try {
            let data = Branches.requestData;
            data = {};
            data.id = currentId;
            data.name = formData.name;
            data.code = formData.code;
            data.location = formData.location;
            data.phone = formData.phone;
            data.email = formData.email;
            data.workshiftId = formData.workshiftId?.value;

            dispatch(fetchDataRequest());

            const response = await calculateResponse(false, data);
            dispatch(fetchDataSuccess(response.data.success));
            dispatch(setShowRemoteResponseToast(true));
            clearForm();
            onModalHide(null, true);
        }
        catch (err) {
            handleException(err);
        }
    }

    const onLoadOptions = async () => {
        try {
            let filters = {};
            filters.enabled = true;

            dispatch(fetchDataRequest());

            const response = await Workshifts.getList(token, filters);
            dispatch(setShiftList(response.data.data));
            setShiftOptions(convertShiftOptions(response.data.data));
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onLoadItem = async () => {
        try {
            let data = Branches.requestData;
            data = {};
            data.id = currentId;

            let filters = {};
            filters.enabled = true;

            dispatch(fetchDataRequest());

            let shift = [];
            if (shiftList.length === 0) {
                const responseW = await Workshifts.getList(token, filters);
                dispatch(setShiftList(responseW.data.data));
                shift = convertShiftOptions(responseW.data.data);
            } else {
                shift = convertShiftOptions(shiftList);
            }


            const response = await calculateResponse(true, data);
            setValue('name', response.data.data.name);
            setValue('location', response.data.data.location);
            setValue('email', response.data.data.email);
            setValue('phone', response.data.data.phone);
            setValue('workshiftId', shift.find((i) => i.value === response.data.data.workshiftId));
            setValue('code', response.data.data.code);
            setShiftOptions(shift);
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const convertShiftOptions = (data) => {
        let options = [];
        data.map((i) => {
            options.push(
                {
                    label: calculateLabel(i),
                    value: i.id
                });
        });
        return options;
    }

    const calculateLabel = (item) => {
        let label = '';
        label = `${item.name} - De ${format(convertirHoraStringADate(item.timeIn), horaFormat, { locale: es })}`;
        label += ` a ${format(convertirHoraStringADate(item.timeOut), horaFormat, { locale: es })} - `;
        label += item.monday ? ' L' : '';
        label += item.tuesday ? ' M' : '';
        label += item.wednesday ? ' M' : '';
        label += item.thursday ? ' J' : '';
        label += item.friday ? ' V' : '';
        label += item.saturday ? ' S' : '';
        label += item.sunday ? ' D' : '';

        return label;
    }

    const clearForm = () => {
        reset(formValues => ({
            ...formValues,
            name: null,
            location: null,
            email: null,
            phone: null,
            workshiftId: null,
            code: null
        }));
    }

    const onModalHide = (e, refresh) => {
        e?.preventDefault();
        clearForm();
        onHide(refresh);
    }

    /*const onGenerateCode = () => {
        setValue('code', generarCodigo());
    }*/

    useEffect(() => {
        if (currentId) {
            onLoadItem();
        } else {
            onLoadOptions();
        }
    }, [currentId]);

    return (
        <Modal show={show} centered onHide={onModalHide} id={id}>
            <form onSubmit={handleSubmit((data) => onSaveItem(data))}>
                <Modal.Header className="custom-modal-header" closeButton={!loading}>
                    <Modal.Title>
                        <h4>{isNew ? "Registrar Sucursal" : "Actualizar Sucursal"}</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12 col-12 mb-3 ">
                            <label className="form-label">Sucursal<span className="text-danger"> *</span></label>
                            <input
                                type="text"
                                className={!errors.name ? "form-control" : "form-control is-invalid"}
                                placeholder="Escribe el nombre de la sucursal"
                                disabled={loading}
                                {...register("name",
                                    {
                                        required: "Nombre es requerido.",
                                    }
                                )}
                                maxLength={300}
                            />
                            {
                                errors.name ?
                                    <div className="invalid-feedback">
                                        {errors.name.message}
                                    </div>
                                    : null
                            }
                        </div>
                        <div className="col-lg-12 col-sm-12 col-12 mb-3 ">
                            <label className="form-label">Código de sistema<span className="text-danger"> *</span></label>
                            <input
                                type="text"
                                className={!errors.code ? "form-control" : "form-control is-invalid"}
                                placeholder="Escribe el nombre de la sucursal"
                                disabled={loading}
                                {...register("code",
                                    {
                                        required: "Código es requerido.",
                                        pattern: {
                                            value: customRegex,
                                            message: 'Solo se permiten letras, números, guiones bajos y medios. Debe tener al menos 5 caracteres.'
                                        }
                                    }
                                )}
                                maxLength={300}
                            />
                            {
                                errors.code ?
                                    <div className="invalid-feedback">
                                        {errors.code.message}
                                    </div>
                                    : null
                            }
                        </div>
                        <div className="col-lg-12 col-sm-12 col-12 mb-3 ">
                            <label className="form-label">Correo electrónico</label>
                            <div className="input-group flex-wrap">
                                <span className="input-group-text"><Mail size={15} color={variables.primaryColor} /></span>
                                <input
                                    type="text"
                                    className={!errors.email ? "form-control" : "form-control is-invalid"}
                                    placeholder="Escribe el email de la sucursal"
                                    disabled={loading}
                                    {...register("email",
                                        {
                                            pattern: {
                                                value: emailRegex,
                                                message: "No tiene estructura de un email válido"
                                            },
                                        }
                                    )}
                                />
                                {
                                    errors.email ?
                                        <div className="invalid-feedback">
                                            {errors.email.message}
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="col-lg-12 col-sm-12 col-12 mb-3">
                            <label className="form-label">Teléfono</label>
                            <div className="input-group flex-wrap">
                                <ImageWithBasePath
                                    src="assets/img/flags/mx.png"
                                    alt="img"
                                    id="mx"
                                    style={{ height: 41 }}
                                    className={"input-group-text"}
                                />
                                <span className="input-group-text">+52</span>
                                <input type="text"
                                    className={!errors.phone ? "form-control" : "form-control is-invalid"}
                                    maxLength={10}
                                    placeholder="Escribe el teléfono a 10 dígitos"
                                    disabled={loading}
                                    {...register("phone",
                                        {
                                            pattern: {
                                                value: phoneRegex,
                                                message: "No tiene estructura de un teléfono válido"
                                            },
                                        }
                                    )}
                                />
                                {
                                    errors.phone ?
                                        <div className="invalid-feedback">
                                            {errors.phone.message}
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="col-lg-12 col-sm-12 col-12 mb-3">
                            <label className="form-label">Ubicación</label>
                            <div className="input-group flex-wrap">
                                <span className="input-group-text"><MapPin size={15} color={variables.primaryColor} /></span>
                                <input
                                    type="text"
                                    placeholder="Pega la liga de Maps https://"
                                    className={!errors.location ? "form-control" : "form-control is-invalid"}
                                    disabled={loading}
                                    {...register("location",
                                        {
                                            pattern: {
                                                value: urlRegex,
                                                message: "No tiene estructura de una URL válida"
                                            },
                                        }
                                    )}
                                />
                                {
                                    errors.location ?
                                        <div className="invalid-feedback">
                                            {errors.location.message}
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="col-lg-12 col-sm-12 col-12 mb-3">
                            <label className="form-label">Horario<span className="text-danger"> *</span></label>
                            <Controller
                                name="workshiftId"
                                control={control}
                                render={({ field: { onChange, value, ref } }) => (
                                    <Select
                                        inputRef={ref}
                                        className={errors.workshiftId ? "form-control is-invalid" : ""}
                                        options={shiftOptions}
                                        placeholder="Elige un horario"
                                        value={value}
                                        onChange={onChange}
                                        isClearable
                                        styles={{ menu: provided => ({ ...provided, zIndex: 100 }) }}
                                        noOptionsMessage={() => "Sin opciones"}
                                        isDisabled={loading}
                                    />
                                )}
                                rules={{ required: 'Requerido' }}
                            />
                            {
                                errors.workshiftId ?
                                    <div className="invalid-feedback">
                                        {errors.workshiftId.message}
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                    <div className="mb-3">
                        <FormCreatedBy isNew={isNew} />
                    </div>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <button
                        className="btn btn-cancel me-2"
                        onClick={(e) => onModalHide(e, false)}
                        disabled={loading}
                    >
                        Cancelar
                    </button>
                    {
                        loading ?
                            <button disabled={loading} className="btn btn-submit">
                                <Spinner animation="border" role="status" size="sm" />
                            </button>
                            :
                            <input type="submit" value={isNew ? "Registrar" : "Actualizar"} className="btn btn-submit" />
                    }
                </Modal.Footer>
            </form>
        </Modal>

    );
};

AddStore.propTypes = {
    id: PropTypes.string.isRequired,
    show: PropTypes.bool,
    isNew: PropTypes.bool,
    currentId: PropTypes.number,
    onHide: PropTypes.func
};


export default AddStore;
