import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setToogleHeader } from "../../../core/redux/action";
import { ChevronUp, RotateCcw } from "react-feather";
import ExportData from "../exportData";


const ToolsHead = ({ showExportData = false, showPdf = false, showPrint = false, showExcel = false, onReload, onPrint }) => {

    const dispatch = useDispatch();
    const data = useSelector((state) => state.toggle_header);

    const renderRefreshTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Actualizar
        </Tooltip>
    );
    const renderCollapseTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Colapsar
        </Tooltip>
    );
    return (
        <ul className="table-top-head">
            {showExportData ?
                <ExportData showPdf={showPdf} showExcel={showExcel} showPrint={showPrint} onPrint={onPrint} />
                : null}
            <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                    <a
                        onClick={onReload}>
                        <RotateCcw />
                    </a>
                </OverlayTrigger>
            </li>
            <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                    <a
                        id="collapse-header"
                        className={data ? "active" : ""}
                        onClick={(e) => {
                            e?.preventDefault();
                            dispatch(setToogleHeader(!data));
                        }}
                    >
                        <ChevronUp />
                    </a>
                </OverlayTrigger>
            </li>
        </ul>
    );
};

ToolsHead.propTypes = {
    showExportData: PropTypes.bool,
    showPdf: PropTypes.bool,
    showPrint: PropTypes.bool,
    showExcel: PropTypes.bool,
    onReload: PropTypes.func,
    onPrint: PropTypes.func
};

export default ToolsHead;
