import { setToken } from '../authentication';
import { axiosClientApi } from '../../axiosClientConfiguration';
import { getRequest, postRequest, putRequest } from '../../axiosFunctions';

const requestData = {
  articleId: undefined,
  imagePaths: undefined
}

const getGenerateToken = (token, containerName) => {
  return getRequest(
    axiosClientApi,
    `/Images/GenerateToken/${containerName}`,
    setToken(token)
  );
}

const postProduct = (token, data) => {
  return postRequest(
    axiosClientApi,
    "/Images/Product",
    data,
    setToken(token)
  );
}

const putProduct = (token, data) => {
  return putRequest(
    axiosClientApi,
    "/Images/Product",
    data,
    setToken(token)
  );
}

export const Images = {
  requestData,
  getGenerateToken,
  postProduct,
  putProduct
};
