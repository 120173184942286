import { setToken } from '../authentication';
import { axiosClientApi } from '../../axiosClientConfiguration';
import { deleteRequest, getRequest, postRequest, putRequest } from '../../axiosFunctions';

const requestData = {
  id: undefined,
  name: undefined,
  description: undefined,
  code: undefined,
  categoryId: undefined,
  unmedId: undefined,
  salePrice: undefined,
  buyPrice: undefined,
  recipeResult: undefined,
  inventoried: undefined,
  enabled: undefined
}

const getSingle = (token, id) => {
  return getRequest(
    axiosClientApi,
    `/Articles/Get/${id}`,
    setToken(token)
  );
}

const getList = (token, filters) => {
  let config = setToken(token);
  config.params = {
    name: filters.name,
    categoryId: filters.categoryId,
    unmedId: filters.unmedId,
    recipeResult: filters.recipeResult,
    inventoried: filters.inventoried,
    enabled: filters.enabled
  };
  return getRequest(
    axiosClientApi,
    "/Articles/List",
    config
  );
}

const postSingle = (token, data) => {
  return postRequest(
    axiosClientApi,
    "/Articles",
    data,
    setToken(token)
  );
}

const putSingle = (token, data) => {
  return putRequest(
    axiosClientApi,
    "/Articles",
    data,
    setToken(token)
  );
}

const deleteSingle = (token, id) => {
  return deleteRequest(
    axiosClientApi,
    `/Articles/Delete/${id}`,
    setToken(token)
  );
}

export const Articles = {
  requestData,
  getSingle,
  getList,
  postSingle,
  putSingle,
  deleteSingle,
};
