export const rolesandpermission = [
	{
		"id": "1",
		"rolename": "Administrador",
		"desc": "Permite administrar el sistema",
		"createdon": "25 May 2023",
		status: true,
		
	},
	{
		"id": "1",
		"rolename": "Auxiliar Operativo",
		"desc": "Personal de operaciones",
		"createdon": "30 May 2023",
		status: true,
	},
	{
		"id": "1",
		"rolename": "Gerente",
		"desc": "Gerente de piso",
		"createdon": "20 Apr 2023",
		status: false,
	}
]