import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import variables from "../../../style/scss/utils/_variables.scss";
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Spinner } from 'react-bootstrap';
import { DollarSign } from 'react-feather';
import Select from "react-select";
import { noOptionsText, priceRegex } from '../../utils';
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setConceptOptions, setShowRemoteResponseToast } from '../../redux/action';
import { showInternalErrorAlert } from '../../../feature-module/components/customAlert';
import FormCreatedBy from '../../../feature-module/components/createdByForm';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Sales } from '../../../services/internal/sales';
import { Catalogs } from '../../../services/internal/catalogs';

const CashOutflowModal = ({ id, show = false, onHide }) => {

    const { register, handleSubmit, reset, formState: { errors }, control } = useForm();
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();
    const userDetails = useSelector((state) => state.userDetails);
    const loading = useSelector((state) => state.loading);
    const token = useSelector((state) => state.token);
    const conceptOptions = useSelector((state) => state.conceptOptions);

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure(err.response.data));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadCatalogs = async () => {
        try {
            dispatch(fetchDataRequest());

            if (conceptOptions.length === 0) {
                const response = await Catalogs.getOptions(token, Catalogs.Name.Concept);
                dispatch(setConceptOptions(response.data.data));
            }

            dispatch(fetchDataSuccess(true));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onSaveCashOutflow = async (formData) => {
        try {
            let data = Sales.requestBreakData;
            data = {};
            data.branchId = userDetails?.branch?.id;
            data.conceptId = formData.conceptId?.value;
            data.ammount = formData.retiredAmmount;
            data.terminal = userDetails?.alias;
            data.comment = formData.comment;

            dispatch(fetchDataRequest());
            const response = await Sales.postCashoutComesCreate(token, data);
            dispatch(fetchDataSuccess(response.data.success));
            dispatch(setShowRemoteResponseToast(true));
            clearForm();
            onModalHide(null, true);
        }
        catch (err) {
            handleException(err);
        }
    }

    const clearForm = async () => {
        reset(formValues => ({
            ...formValues,
            branchId: null,
            conceptId: null,
            retiredAmmount: null,
            comment: null,
        }));
    }

    const showConfirmAlert = async (formData) => {
        const result = await MySwal.fire({
            title: "¿Estás seguro?",
            html: `Vamos a registrar una salida de efectivo, asegúrate de revisar las cantidades ingresadas.`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Registrar",
            customClass: {
                confirmButton: "btn btn-warning",
            },
        });

        if (result.isConfirmed) {
            await onSaveCashOutflow(formData);
        } else {
            MySwal.close();
        }
    }

    const onModalHide = (e, refresh) => {
        e?.preventDefault();
        clearForm();
        onHide(refresh);
    }

    useEffect(() => {
        if (userDetails) {
            onLoadCatalogs();
        }
    }, [userDetails]);

    return (
        <Modal show={show} centered onHide={onModalHide} id={id} backdrop="static" keyboard={false}>
            <form onSubmit={handleSubmit((data) => showConfirmAlert(data))}>
                <Modal.Header className="custom-modal-header" closeButton={!loading}>
                    <Modal.Title>
                        <h4>Registrar salida de efectivo</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 col-12 mb-3">
                            <label className="form-label">Concepto<span className="text-danger"> *</span></label>
                            <Controller
                                name="conceptId"
                                control={control}
                                render={({ field: { onChange, value, ref } }) => (
                                    <Select
                                        inputRef={ref}
                                        options={conceptOptions}
                                        placeholder="Elige una opción"
                                        className={errors.conceptId ? "form-control is-invalid" : ""}
                                        value={value}
                                        onChange={onChange}
                                        noOptionsMessage={() => noOptionsText}
                                        isDisabled={loading}
                                        styles={{ menu: provided => ({ ...provided, zIndex: 100 }) }}
                                        isClearable
                                    />
                                )}
                                rules={
                                    {
                                        validate: {
                                            sizes: (value) => {
                                                return value || "Requerido";
                                            }
                                        }
                                    }
                                }
                            />
                            {
                                errors.conceptId ?
                                    <div className="invalid-feedback">
                                        {errors.conceptId.message}
                                    </div>
                                    : null
                            }
                        </div>
                        <div className="col-lg-6 col-sm-12 col-12 mb-3">
                            <label className="form-label">Monto <span className="text-danger">*</span></label>
                            <div className="input-group flex-wrap">
                                <span className="input-group-text"><DollarSign size={15} color={variables.textColor} /></span>
                                <input
                                    type="text"
                                    className={!errors.retiredAmmount ? "form-control" : "form-control is-invalid"}
                                    {...register("retiredAmmount",
                                        {
                                            required: "Necesitamos el monto.",
                                            pattern: {
                                                message: "Monto inválido.",
                                                value: priceRegex,
                                            },
                                            validate: {
                                                mayorCero: (value) => {
                                                    return Number(value) > 0 || "Monto debe ser mayor a cero.";
                                                }
                                            }
                                        }
                                    )}
                                    disabled={loading}
                                />
                                {
                                    errors.retiredAmmount ?
                                        <div className="invalid-feedback">
                                            {errors.retiredAmmount.message}
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="col-12 mb-3">
                            <label className="form-label">Comentario</label>
                            <textarea
                                rows={2}
                                maxLength={200}
                                className={!errors.comment ? "form-control" : "form-control is-invalid"}
                                disabled={loading}
                                {...register("comment")}
                            />
                            <p>Máximo 200 caracteres.</p>
                        </div>
                    </div>
                    <FormCreatedBy isNew={true} />
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <button
                        className="btn btn-cancel me-2"
                        onClick={(e) => onModalHide(e, false)}
                        disabled={loading}
                    >
                        Cancelar
                    </button>
                    {
                        loading ?
                            <button disabled={loading} className="btn btn-submit">
                                <Spinner animation="border" role="status" size="sm" />
                            </button>
                            : <input
                                type="submit"
                                value="Registrar"
                                className="btn btn-submit"
                            />
                    }
                </Modal.Footer>
            </form>
        </Modal>
    )
}

CashOutflowModal.propTypes = {
    id: PropTypes.string.isRequired,
    show: PropTypes.bool,
    onHide: PropTypes.func
};


export default CashOutflowModal
