import { setToken } from '../authentication';
import { axiosClientApi } from '../../axiosClientConfiguration';
import { getRequest } from '../../axiosFunctions';

const Values = {
  Registered: { id: "Registered", label: "Registrado", value: 1 },
  Updated: { id: "Updated", label: "Actualizado", value: 2 },
  Deleted: { id: "Deleted", label: "Eliminado", value: 3 },
  Sended: { id: "Sended", label: "Enviado", value: 4 },
  Delivered: { id: "Delivered", label: "Entregado", value: 5 },
  InProcess: { id: "InProcess", label: "En proceso", value: 6 },
  Approved: { id: "Approved", label: "Aprobado", value: 7 },
  Rejected: { id: "Rejected", label: "Rechazado", value: 8 },
  Pending: { id: "Pending", label: "Pendiente", value: 9 },
  Canceled: { id: "Canceled", label: "Cancelado", value: 10 },
  Received: { id: "Received", label: "Recibido", value: 11 },
  Accepted: { id: "Accepted", label: "Aceptado", value: 12 },
  Supplied: { id: "Supplied", label: "Surtido", value: 13 },
  Requested: { id: "Required", label: "Solicitado", value: 14 },
};

const getSingle = (token, id) => {
  return getRequest(
    axiosClientApi,
    `/Statuses/Get/${id}`,
    setToken(token)
  );
}

const getList = (token, filters) => {
  let config = setToken(token);
  config.params = {
    enabled: filters.enabled
  };
  return getRequest(
    axiosClientApi,
    "/Statuses/List",
    config
  );
}

export const Statuses = {
  Values,
  getSingle,
  getList
};
