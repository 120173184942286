import { setToken } from '../authentication';
import { axiosClientApi } from '../../axiosClientConfiguration';
import { getRequest, postRequest, putRequest } from '../../axiosFunctions';

const requestData = {
  userId: undefined,
  justifyId: undefined,
  comment: undefined
}

const getSingle = (token, userId) => {
  return getRequest(
    axiosClientApi,
    `/CheckIns/Get/${userId}`,
    setToken(token)
  );
}

const getList = (token, filters) => {
  let config = setToken(token);
  config.params = {
    userId: filters.userId,
    checkInStart: filters.checkInStart,
    checkInEnd: filters.checkInEnd
  };
  return getRequest(
    axiosClientApi,
    "/CheckIns/List",
    config
  );
}

const postSingle = (token, data) => {
  return postRequest(
    axiosClientApi,
    "/CheckIns",
    data,
    setToken(token)
  );
}

const putSingle = (token, data) => {
  return putRequest(
    axiosClientApi,
    "/CheckIns",
    data,
    setToken(token)
  );
}

export const CheckIns = {
  requestData,
  getSingle,
  getList,
  postSingle,
  putSingle,
};
