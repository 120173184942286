import React, { useEffect, useState } from "react";
import {
    Calendar,
} from "feather-icons-react/build/IconComponents";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import es from 'date-fns/locale/es';
import PropTypes from 'prop-types';
import variables from "../../../style/scss/utils/_variables.scss";
import { fechaHoraFormat } from "../../../core/utils";
import { useSelector } from "react-redux";

registerLocale('es', es)

const FormCreatedBy = ({ isNew = false }) => {
    const userDetails = useSelector((state) => state.userDetails);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [name, setName] = useState("");

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const calculateName = () => {
        if (!userDetails) {
            return;
        }

        let fullName = `${userDetails.name ? userDetails.name : ''} ${userDetails.surname ? userDetails.surname : ''}`;
        setName(fullName.trim() ? fullName : userDetails.alias);
    }

    useEffect(() => {
        if (userDetails) {
            calculateName();
        }
    }, [userDetails]);

    return (
        <>
            <div className="row">
                <div className="col-lg-6 col-sm-6 col-12 mb-3">
                    <label className="form-label">Registrado por:</label>
                    <input type="text" className="form-control" value={name} disabled />
                </div>
                <div className="col-lg-6 col-sm-6 col-12 mb-3">
                    <label className="form-label">Fecha de registro</label>
                    <div className="input-group">
                        <span className="input-group-text"><Calendar size={15} color={variables.primaryColor} /></span>
                        <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            dateFormat={fechaHoraFormat}
                            locale={es}
                            wrapperClassName="form-control"
                            className="form-control"
                            disabled
                        />
                    </div>
                </div>
            </div>
            {!isNew &&
                <div className="row">
                    <div className="col-lg-6 col-sm-6 col-12 mb-3">
                        <label className="form-label">Actualizado por:</label>
                        <input type="text" className="form-control" defaultValue={"Admin"} disabled />
                    </div>
                    <div className="col-lg-6 col-sm-6 col-12 mb-3">
                        <label className="form-label">Última actualización</label>
                        <div className="input-group">
                            <span className="input-group-text"><Calendar size={15} color={variables.primaryColor} /></span>
                            <DatePicker
                                selected={selectedDate}
                                onChange={handleDateChange}
                                dateFormat={fechaHoraFormat}
                                locale={es}
                                wrapperClassName="form-control"
                                className="form-control"
                                disabled
                            />
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

FormCreatedBy.propTypes = {
    isNew: PropTypes.bool
};

export default FormCreatedBy;
