import { Table } from "antd";
import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import es from 'date-fns/locale/es';
import { fechaHoraFormat, formatearFecha, generarCodigo } from "../../utils";
import { setNotes } from "../../redux/action";
import { Calendar, Trash2 } from "react-feather";
import variables from "../../../style/scss/utils/_variables.scss";
import DatePicker from "react-datepicker";

const NotesEditModal = ({ id, show = false, onHide }) => {

  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const dataSource = useSelector(state => state.notes);
  const loading = useSelector(state => state.loading);
  const userDetails = useSelector(state => state.userDetails);
  const [comments, setComments] = useState([]);

  const onSaveItem = async (formData) => {
    if (formData.comment) {
      let newComment = {
        key: generarCodigo(),
        date: new Date().toISOString(),
        user: userDetails?.alias,
        comment: formData.comment
      };
      const updatedComments = [...comments, newComment];
      setComments(updatedComments);
      const updatedNotes = [...dataSource, newComment];
      dispatch(setNotes(updatedNotes));
      clearForm();
    }
  }

  const onModalHide = (e) => {
    e?.preventDefault();
    clearForm();
    onHide(comments.length > 0);
  }

  const onDeleteComment = (record) => {
    const updatedComments = [...comments.filter((i) => i.key !== record.key)];
    setComments(updatedComments);
    const updatedNotes = [...dataSource.filter((i) => i.key !== record.key)];
    dispatch(setNotes(updatedNotes));
  }

  const columns = [
    {
      title: "Fecha y hora",
      dataIndex: "date",
      align: "center",
      render: (text, record) => (
        <span>{formatearFecha(record.date, fechaHoraFormat)}</span>
      ),
    },
    {
      title: "Usuario",
      dataIndex: "user",
      render: (text) => (
        <span className="text-primary fw-semibold">{text}</span>
      ),
    },
    {
      title: "Comentario",
      dataIndex: "comment",
    },
    {
      title: "Acción",
      align: "center",
      dataIndex: "action",
      width: 60,
      render: (text, record) => (
        record.key &&
        <div className="action-table-data">
          <a className="edit-delete-action me-2 p-2" onClick={() => onDeleteComment(record)}>
            <Trash2 className='feather-trash-2' />
          </a>
        </div>
      )
    },
  ];

  const clearForm = () => {
    reset(formValues => ({
      ...formValues,
      comment: null,
    }));
  }

  return (
    <Modal show={show} centered onHide={onModalHide} id={id} className="modal-lg">
      <form onSubmit={handleSubmit((data) => onSaveItem(data))}>
        <Modal.Header className="custom-modal-header" closeButton={!loading}>
          <Modal.Title>
            <h4>Notas</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive mb-3">
            <Table columns={columns} dataSource={dataSource} footer={false} pagination={false} />
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-6 col-12 mb-3">
              <label className="form-label">Registrado por</label>
              <input type="text" className="form-control" defaultValue={userDetails?.alias} disabled />
            </div>
            <div className="col-lg-6 col-sm-6 col-12 mb-3">
              <label className="form-label">Fecha de registro</label>
              <div className="input-group">
                <span className="input-group-text"><Calendar size={15} color={variables.primaryColor} /></span>
                <DatePicker
                  selected={new Date()}
                  dateFormat={fechaHoraFormat}
                  locale={es}
                  wrapperClassName="form-control"
                  className="form-control"
                  disabled
                />
              </div>
            </div>
            <div className="col-12 mb-3">
              <label className="form-label">Comentario <span className="text-danger"> *</span></label>
              <textarea
                rows={2}
                maxLength={200}
                className={!errors.comment ? "form-control" : "form-control is-invalid"}
                disabled={loading}
                {...register("comment", { required: 'Necesitamos tus comentarios' })}
              />
              {
                errors.comment ?
                  <div className="invalid-feedback">
                    {errors.comment.message}
                  </div>
                  : null
              }
              <p>Máximo 200 caracteres.</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='justify-content-center'>
          <button
            className="btn btn-cancel me-2"
            onClick={(e) => onModalHide(e)}
            disabled={loading}
          >
            Regresar
          </button>
          {
            loading ?
              <button disabled={loading} className="btn btn-submit">
                <Spinner animation="border" role="status" size="sm" />
              </button>
              :
              <input type="submit" value="Agregar" className="btn btn-submit" />
          }
        </Modal.Footer>
      </form>
    </Modal >
  );
};

NotesEditModal.propTypes = {
  id: PropTypes.string.isRequired,
  show: PropTypes.bool,
  onHide: PropTypes.func
};


export default NotesEditModal;
