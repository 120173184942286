import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ImageWithBasePath from '../../core/img/imagewithbasebath'
import { CornerUpRight, MinusCircle, PlusCircle, RotateCw, ShoppingCart, Trash2, UserPlus, XCircle } from 'react-feather'
import Select from 'react-select'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { fechaFormat, formatearFecha, formatearMoneda, generarCodigo, IVA, noOptionsText, paymentMethods, validateQuantity } from '../../core/utils'
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setCategoryList, setCustomerOptions, setShowRemoteResponseToast } from '../../core/redux/action'
import { showInternalErrorAlert } from '../components/customAlert'
import { useDispatch, useSelector } from 'react-redux'
import { Catalogs } from '../../services/internal/catalogs'
import { Bs1Circle, Bs2Circle, Bs3Circle, Bs4Circle, BsCashStack, BsPlusCircleFill } from "react-icons/bs";
import CashModal from '../../core/modals/payment/cash'
import MercadoPagoModal from '../../core/modals/payment/mercadopago'
import { Sales } from '../../services/internal/sales'
import { Categories } from '../../services/internal/categories'
import { Articles } from '../../services/internal/articles'
import CashOutflowModal from '../../core/modals/sales/cashOutflowModal'
import UserSalesModal from '../../core/modals/sales/userSales'


const SimplePos = () => {
  const customerOptions = useSelector((state) => state.customerOptions);
  const categoryList = useSelector((state) => state.categoryList);
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading);
  const token = useSelector((state) => state.token);
  const userDetails = useSelector((state) => state.userDetails);
  const [orderProducts, setOrderProducts] = useState([]);
  const [orderPackages, setOrderPackages] = useState([]);
  const [subtotalProducts, setSubtotalProducts] = useState(0);
  const [subtotalPackages, setSubtotalPackages] = useState(0);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [customer, setCustomer] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [code, setCode] = useState(null);
  const [showOrder, setShowOrder] = useState(false);
  const [showCashModal, setShowCashModal] = useState(false);
  const [showMercadoPagoModal, setShowMercadoPagoModal] = useState(false);
  const [showUserSalesModal, setShowUserSalesModal] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [showCashOutflowModal, setShowCashOutflowModal] = useState(false);

  const sliderSettings = {
    dots: false,
    autoplay: false,
    infinite: true,
    slidesToShow: 5,
    margin: 0,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }

  const handleCardClick = (cardId) => {
    setPaymentMethod(cardId);
  };

  const handleException = (err) => {
    console.log(err);
    if (err.response) {
      dispatch(fetchDataFailure(err.response.data));
      if (err.response.status >= 400 && err.response.status < 500) {
        dispatch(fetchDataErrors(err.response.data.errors));
        dispatch(setShowRemoteResponseToast(true));
        return;
      } else {
        showInternalErrorAlert(false, err.response.statusText);
        return;
      }
    }
    dispatch(fetchDataFailure(err));
    showInternalErrorAlert(true, err.code);
  }

  const onLoadProducts = async (currentCategoryId) => {
    console.log(customer)

    let filters = {};
    filters.enabled = true;
    if (currentCategoryId) {
      filters.categoryId = currentCategoryId;
    }

    try {
      dispatch(fetchDataRequest());
      const response = await Articles.getList(token, filters);
      //console.log(response)
      setFilteredProducts(response.data.data);
      dispatch(fetchDataSuccess(response.data.success));
    }
    catch (err) {
      handleException(err);
    }
  }

  const onLoadCategories = async () => {
    let filters = {};
    filters.enabled = true;

    try {
      dispatch(fetchDataRequest());
      let cOptions = [];
      if (customerOptions.length === 0) {
        const responseA = await Catalogs.getOptions(token, Catalogs.Name.Clients);
        dispatch(setCustomerOptions(responseA.data.data));
        cOptions = responseA.data.data;
      } else {
        cOptions = customerOptions;
      }

      const response = await Categories.getList(token, filters);
      dispatch(setCategoryList(response.data.data));
      setCustomer(cOptions.find((i) => i.label?.toLowerCase().includes("general")));
      dispatch(fetchDataSuccess(response.data.success));
    }
    catch (err) {
      handleException(err);
    }
  }

  const onSaveSale = async () => {
    let data = Sales.requestData;
    data = {};
    data.branchId = userDetails?.branch?.id;
    data.clientId = customer?.value;
    data.code = code;
    data.paymentMethod = paymentMethod;
    data.total = total;
    data.terminal = userDetails?.alias;
    data.saleArticles = orderProducts;
    data.salePackages = orderPackages;

    try {
      dispatch(fetchDataRequest());
      const response = await Sales.postSingle(token, data);
      dispatch(fetchDataSuccess(response.data.success));
      onReset();
      dispatch(setShowRemoteResponseToast(true));
    }
    catch (err) {
      handleException(err);
    }
  }

  const onAddProduct = (currentProduct, fromPackage) => {
    if (!currentProduct) {
      return;
    }

    if (orderProducts.find((i) => i.productId === currentProduct.id)) {
      showSameProductAlert("Ya tienes este producto en la orden.");
      return;
    }

    let item = {
      key: generarCodigo(),
      code: currentProduct.code,
      productId: currentProduct.id,
      articleId: currentProduct.id,
      quantity: 1,
      name: currentProduct.name,
      salePrice: fromPackage ? 0 : currentProduct.salePrice,
      unit: currentProduct.unmed?.abbreviation,
      imagePath: currentProduct.imageUrls?.length > 0 ? currentProduct.imageUrls[0] : null
    };

    item.subtotal = item.quantity * item.salePrice;
    setOrderProducts(prevOrderList => [...prevOrderList, item].sort((a, b) => a.id - b.id));
    setSubtotalProducts(subtotalProducts + item.subtotal);
    setSubtotal(subtotalPackages + subtotalProducts + item.subtotal);
    setTotal((subtotalPackages + subtotalProducts + item.subtotal) * (1 + IVA));
    setShowOrder(true);
  }

  const onChangeProductQty = (value, product) => {
    const newQuantity = validateQuantity(value);

    //Incluido en paquete
    if (product.salePrice === 0) {
      product.quantity = newQuantity;
    } else {
      product.quantity = newQuantity;
      product.subtotal = newQuantity * product.salePrice;
    }

    setOrderProducts(prevDataSource => {

      let items = [...prevDataSource.filter(item => item.key !== product.key), product].sort((a, b) => a.id - b.id);
      const stProducts = calculateTotal(items);
      setSubtotalProducts(stProducts);
      setSubtotal(subtotalPackages + stProducts);
      setTotal((subtotalPackages + stProducts) * (1 + IVA));
      return items;
    });
  }

  const onChangePackageQty = (value, pkg) => {
    const newQuantity = validateQuantity(value);
    pkg.quantity = newQuantity;
    pkg.subtotal = newQuantity * pkg.salePrice;

    setOrderPackages(prevDataSource => {

      let items = [...prevDataSource.filter(item => item.key !== pkg.key), pkg].sort((a, b) => a.id - b.id);
      const stPackages = calculateTotal(items);
      setSubtotalPackages(stPackages);
      setSubtotal(subtotalProducts + stPackages);
      setTotal((subtotalProducts + stPackages) * (1 + IVA));
      return items;
    });
  }

  const onDeletePackage = (pkg) => {
    if (loading) {
      return;
    }

    setOrderPackages(prevOrderList => [...prevOrderList.filter(item => item.key !== pkg.key)]);

    setSubtotalPackages(subtotalPackages - pkg.subtotal);
    setSubtotal(subtotalProducts + subtotalPackages - pkg.subtotal);
    setTotal((subtotalProducts + subtotalPackages - pkg.subtotal) * (1 + IVA));
  }

  const onDeleteProduct = (product) => {
    if (loading) {
      return;
    }

    setOrderProducts(prevOrderList => [...prevOrderList.filter(item => item.key !== product.key)]);

    setSubtotalProducts(subtotalProducts - product.subtotal);
    setSubtotal(subtotalPackages + subtotalProducts - product.subtotal);
    setTotal((subtotalPackages + subtotalProducts - product.subtotal) * (1 + IVA));
  }

  const onFilterProducts = async (categoryId) => {
    setCurrentCategory(categoryId);
    await onLoadProducts(categoryId);
  };

  const calculateTotal = (items) => {

    let sumaTotal = items.reduce((acumulador, objetoActual) => {
      return acumulador + objetoActual.subtotal;
    }, 0);

    return sumaTotal;
  }

  const showCleanOrderListAlert = async () => {
    if (orderProducts.length === 0 && orderPackages.length === 0) {
      return;
    }
    const result = await MySwal.fire({
      title: "¿Estás seguro?",
      html: `Vamos a eliminar todos productos de la orden.`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Limpiar orden",
      customClass: {
        confirmButton: "btn btn-warning",
      },
    });

    if (result.isConfirmed) {
      setOrderProducts([]);
      setOrderPackages([]);
      setSubtotalPackages(0);
      setSubtotalProducts(0);
      setSubtotal(0);
      setTotal(0);
    } else {
      MySwal.close();
    }
  }

  const showSameProductAlert = async (text) => {
    const result = await MySwal.fire({
      title: `${text}`,
      icon: "info",
      html: `Puedes modificar la cantidad si necesitas agregar más del mismo producto.`,
      confirmButtonText: "De acuerdo",
      confirmButtonColor: "#3fc3ee"
    });

    if (result.isConfirmed) {
      MySwal.close();
    }
  }

  const handlePayment = () => {
    switch (paymentMethod) {
      case 1:
        setShowCashModal(true);
        break;
      case 2:
        setShowMercadoPagoModal(true);
        break;
      default:
    }
  }

  const onReset = () => {
    setCurrentCategory(null);
    setFilteredProducts([]);
    setPaymentMethod(null);
    setShowOrder(false);
    setShowCashModal(false);
    setShowMercadoPagoModal(false);
    setCode(generarCodigo());
    setOrderProducts([]);
    setOrderPackages([]);
    setSubtotalPackages(0);
    setSubtotalProducts(0);
    setSubtotal(0);
    setTotal(0);
  }

  useEffect(() => {
    if (userDetails) {
      onLoadCategories();
      setCode(generarCodigo());
    }
  }, [userDetails]);

  return (
    <div className="page-wrapper content ms-0">
      <div className="row ps-3 pe-3 pt-4">
        <div className="col-sm-12 col-md-3 col-lg-3 mt-1">
          <div
            onClick={() => setShowUserSalesModal(true)}
            className="d-flex justify-content-center align-items-center btn btn-lg btn-primary"
          >
            <ShoppingCart className="me-2" size={16} />
            Mis ventas
          </div>
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3 mt-1">
          <div
            onClick={() => setShowCashOutflowModal(true)}
            className="d-flex justify-content-center align-items-center btn btn-lg btn-warning"
          >
            <CornerUpRight className="me-2" size={16} />
            Salida de efectivo
          </div>
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3 mt-1">
          <div
            className="d-flex justify-content-center align-items-center btn btn-lg btn-primary"
          >
            <BsCashStack className="me-2" size={16} />
            Corte de caja
          </div>
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3 mt-1">
          <div
            onClick={() => onReset()}
            className="d-flex justify-content-center align-items-center btn btn-lg btn-secondary"
          >
            <RotateCw className=" me-2" size={16} />
            Reiniciar orden
          </div>
        </div>
      </div>
      <div className="row p-4">
        <div className="col-sm-12 col-md-12 col-lg-8">
          <div className="row mb-3">
            <div className="col-auto">
              <Bs1Circle className="text-secondary" size={35} />
            </div>
            <div className="col-auto">
              <h5 className="h4 text-secondary">Elige una categoría</h5>
              <p>Selecciona una categoría para realizar un filtrado de productos.</p>
            </div>
          </div>
          {categoryList.length <= 1 ?
            <aside>
              <div className="block-section">
                <div className="order-total text-center">
                  <h6 className="text-primary">No hay categorías de productos registradas.</h6>
                </div>
              </div>
            </aside>
            :
            <div className="row mb-4">
              <Slider {...sliderSettings}>
                {categoryList.map((item) => (
                  <div
                    key={item.id}
                    id={item.name}
                    className="pos-slick-item"
                    onClick={() => onFilterProducts(item.id)}
                  >
                    <div className='card'>
                      <ImageWithBasePath
                        src={item.imagePath ? item.imagePath : "assets/img/categories/default.png"}
                        alt="Categories"
                      />
                      <h6 className="text-secondary">{item.name}</h6>
                      <span>{item.articleCount} productos</span>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          }
          {currentCategory &&
            <div className="row mb-4">
              <div className="col-auto">
                <Bs2Circle className="text-secondary" size={35} />
              </div>
              <div className="col-auto">
                <h5 className="h4 text-secondary">Escoge un producto</h5>
                <p>Selecciona un producto para agregarlos a la orden.</p>
              </div>
            </div>
          }
          {loading ?
            <div id="global-loader" style={{ position: 'relative', height: 80 }}>
              <div className="whirly-loader"></div>
            </div>
            :
            <div className="row pos-products">
              {currentCategory && filteredProducts.length === 0 &&
                <aside>
                  <div className="block-section">
                    <div className="order-total text-center">
                      <h6 className="text-primary">No hay productos para esta categoría</h6>
                    </div>
                  </div>
                </aside>
              }
              {filteredProducts.map((item) => (
                <div key={item.id} className="col-sm-12 col-md-6 col-lg-3 col-xl-3" onClick={() => onAddProduct(item, item.quantity)}>
                  <div className="product-info card">
                    <a className="img-bg">
                      <ImageWithBasePath
                        src={item.imageUrls?.length > 0
                          ? item.imageUrls[0]
                          : "assets/img/product/default.png"}
                        alt="Products"
                      />
                      <span>
                        <BsPlusCircleFill size={30} />
                      </span>
                    </a>
                    <h5 className="text-secondary">{item.category?.name}</h5>
                    <h6 className="text-dark">{item.name}</h6>
                    <div className="d-flex align-items-center justify-content-between price">
                      <span>{item.unmed?.name}</span>
                      <p className="fw-bold">{formatearMoneda(item.salePrice)}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          }
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4">
          <aside className="product-order-list p-3">
            <div className="card p-3 mb-3">
              {/*<h4 className="h4 text-secondary">Datos de la orden</h4>*/}
              <table>
                <tr>
                  <td>Fecha:</td>
                  <td className="text-end text-primary-emphasis fw-bold">{formatearFecha(new Date().toISOString(), fechaFormat + " hh:mm aa")}</td>
                </tr>
                <tr>
                  <td>Operador(a):</td>
                  <td className="text-end text-primary-emphasis fw-bold">{userDetails?.alias}</td>
                </tr>
                <tr>
                  <td>Susursal:</td>
                  <td className="text-end text-primary-emphasis fw-bold">{userDetails?.branch?.name}</td>
                </tr>
                <tr>
                  <td>Número de caja:</td>
                  <td className="text-end text-primary-emphasis fw-bold">{"Desconocido"}</td></tr>
                <tr>
                  <td>Código de orden: </td>
                  <td className="text-end text-orange fw-bold">{code}</td>
                </tr>
              </table>
            </div>
            {showOrder &&
              <>
                <div className="row">
                  <div className="col-auto">
                    <Bs3Circle className="text-secondary" size={35} />
                  </div>
                  <div className="col-auto d-flex align-items-center">
                    <h5 className="h4 text-secondary">Verifica tu orden</h5>
                  </div>
                </div>
                <div className="card p-3 mt-3 mb-3">
                  <h6 className="form-label">Cliente<span className="text-danger"> *</span></h6>
                  <div className="input-block d-flex align-items-center">
                    <div className="flex-grow-1 me-2">
                      <Select
                        options={customerOptions}
                        onChange={(value) => setCustomer(value)}
                        value={customer}
                        noOptionsMessage={() => noOptionsText}
                        placeholder="Elige a un cliente"
                        isDisabled={loading}
                      />
                    </div>
                    <a
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#create"
                    >
                      <UserPlus size={18} />
                    </a>
                  </div>
                </div>
                <div className="card p-3 mt-3 mb-3">
                  <div className="product-added m-0">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <h6 className="d-flex align-items-center mb-0">
                        Pedidos<span className="count">{orderProducts.length + orderPackages.length}</span>
                      </h6>
                      {(orderPackages.length > 0 || orderProducts.length > 0) &&
                        <a
                          className="d-flex align-items-center text-danger fw-semibold"
                          onClick={() => showCleanOrderListAlert()}
                        >
                          <span className="me-1">
                            <XCircle size={16} />
                          </span>
                          Quitar todos
                        </a>
                      }
                    </div>
                    <div className="product-wrap">
                      {orderPackages.map((item) => {
                        if (item.requiredProducts || item.optionalProducts) {
                          return (
                            <div key={item.key} className="card mb-3">
                              <div className="row product-list">
                                <div className='col-sm-12 col-md-10 col-lg-10 mt-3 mb-3 d-flex'>
                                  <div className="product-info me-2">
                                    <ImageWithBasePath
                                      className="img-bg"
                                      src={item.imagePath ? item.imagePath : "assets/img/package/default.png"}
                                      alt="Paquete"
                                    />
                                  </div>
                                  <div>
                                    <div className="badge badge-purple mb-2">Paquete</div>
                                    <h4 className="text-secondary text-uppercase fw-bold">{item.name}</h4>
                                    <p className="text-info fw-semibold">
                                      {`${formatearMoneda(item.salePrice)} x ${item.quantity} = ${formatearMoneda(item.subtotal)} `}
                                    </p>
                                  </div>
                                </div>
                                <div className="col mb-3 d-flex justify-content-center align-items-center action">
                                  <a onClick={() => onDeletePackage(item)}
                                    className="btn delete-icon"
                                  >
                                    <Trash2 size={30} />
                                  </a>
                                </div>
                                {item.requiredProducts?.map((rp) => (
                                  <div
                                    key={rp.productId}
                                    className='col-sm-12 col-md-12 col-lg-12 mb-3 d-flex'>
                                    <div className="product-info me-2">
                                      <ImageWithBasePath
                                        className="img-bg"
                                        style={{ width: 65 }}
                                        src={rp.imagePath ? rp.imagePath : "assets/img/product/default.png"}
                                      />
                                    </div>
                                    <div>
                                      <div className="badge badge-warning mb-2">Incluido</div>
                                      <h5 className="text-secondary text-uppercase fw-bold">{rp.name}</h5>
                                      <h6 className="text-purple">{rp.unit}</h6>
                                    </div>
                                  </div>
                                ))}
                                {item.optionalProducts?.map((op) => (
                                  <div
                                    key={op.productId}
                                    className='col-sm-12 col-md-12 col-lg-12 mb-3 d-flex'>
                                    <div className="product-info me-2">
                                      <ImageWithBasePath
                                        className="img-bg"
                                        style={{ width: 65 }}
                                        src={op.imagePath ? op.imagePath : "assets/img/product/default.png"}
                                      />
                                    </div>
                                    <div>
                                      <div className="badge badge-warning mb-2">Incluido</div>
                                      <h5 className="text-secondary text-uppercase fw-bold">{op.name}</h5>
                                      <h6 className="text-purple">{op.unit}</h6>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          );
                        }

                        return (
                          <div key={item.key} className="row me-0">
                            <div className="col mb-3 d-flex">
                              <div className="input-group">
                                <a onClick={() => onChangePackageQty(item.quantity + 1, item)}
                                  className="input-group-text link-info pe-auto"
                                >
                                  <PlusCircle size={30} />
                                </a>
                                <input
                                  type="text"
                                  className="form-control text-center"
                                  name="qty"
                                  onChange={() => onChangePackageQty(item.quantity, item)}
                                  value={item.quantity}
                                  readOnly
                                  style={{ fontSize: '2rem', minWidth: 70 }}
                                />
                                <a
                                  className="input-group-text link-info pe-auto"
                                  onClick={() => onChangePackageQty(item.quantity - 1, item)}
                                >
                                  <MinusCircle size={30} />
                                </a>
                              </div>
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mb-3 d-flex product-list'>
                              <div className="product-info me-2">
                                <ImageWithBasePath
                                  className="img-bg"
                                  src={item.imagePath ? item.imagePath : "assets/img/package/default.png"}
                                  alt="Paquete"
                                />
                              </div>
                              <div>
                                <div className="badge badge-purple mb-2">Paquete</div>
                                <h4 className="text-secondary text-uppercase fw-bold">{item.name}</h4>
                                <p className="text-info fw-semibold">
                                  {`${formatearMoneda(item.salePrice)} x ${item.quantity} = ${formatearMoneda(item.subtotal)} `}
                                </p>
                              </div>
                            </div>
                            <div className="col mb-3 d-flex justify-content-center align-items-center product-list">
                              <div className="action">
                                <a
                                  onClick={() => onDeletePackage(item)}
                                  className="btn delete-icon"
                                >
                                  <Trash2 size={30} />
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {orderProducts.map((item) => (
                        <div key={item.key} className="row me-0">
                          <div className="col mb-3 d-flex">
                            <div className="input-group">
                              <a onClick={() => onChangeProductQty(item.quantity + 1, item)}
                                className="input-group-text link-info pe-auto"
                              >
                                <PlusCircle size={30} />
                              </a>
                              <input
                                type="text"
                                className="form-control text-center"
                                name="qty"
                                onChange={() => onChangeProductQty(item.quantity, item)}
                                value={item.quantity}
                                readOnly
                                style={{ fontSize: '2rem', minWidth: 70 }}
                              />
                              <a
                                className="input-group-text link-info pe-auto"
                                onClick={() => onChangeProductQty(item.quantity - 1, item)}
                              >
                                <MinusCircle size={30} />
                              </a>
                            </div>
                          </div>
                          <div className='col-sm-12 col-md-6 col-lg-6 mb-3 d-flex product-list'>
                            <div className="product-info me-2">
                              <ImageWithBasePath
                                className="img-bg"
                                src={item.imagePath ? item.imagePath : "assets/img/product/default.png"}
                                alt="Producto"
                              />
                            </div>
                            <div>
                              {
                                item.salePrice > 0
                                  ? <div className="badge badge-warning mb-2">{item.code}</div>
                                  : <div className="badge badge-purple mb-2">Paquete</div>}
                              <h4 className="text-secondary text-uppercase fw-bold">{item.name}</h4>
                              <p className="text-info fw-semibold">
                                {item.salePrice > 0 ? `${formatearMoneda(item.salePrice)} x ${item.quantity} = ${formatearMoneda(item.subtotal)} ` : "Incluido"}
                              </p>
                            </div>
                          </div>
                          <div className="col mb-3 d-flex justify-content-center align-items-center product-list">
                            <div className="action">
                              <a
                                onClick={() => onDeleteProduct(item)}
                                className="btn delete-icon"
                              >
                                <Trash2 size={30} />
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {
                  (orderProducts.length > 0 || orderPackages.length > 0)
                    ?
                    <div className="order-total mb-3">
                      <table className="table table-responsive table-borderless">
                        <tbody>
                          <tr>
                            <td>Subtotal</td>
                            <td className="text-end">{formatearMoneda(subtotal)}</td>
                          </tr>
                          <tr>
                            <td>IVA ({IVA > 0 ? `${IVA * 100}%` : "Incluido"})</td>
                            <td className="text-end">{formatearMoneda(subtotal * IVA)}</td>
                          </tr>
                          <tr>
                            <td className="text-dark">Total</td>
                            <td className="text-dark text-end">{formatearMoneda(total)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    :
                    <div className="block-section">
                      <div className="order-total text-center">
                        <h6>Orden vacía</h6>
                      </div>
                    </div>
                }
                {
                  (orderProducts.length > 0 || orderPackages.length > 0) &&
                  <>
                    <div className="row mb-3">
                      <div className="col-auto">
                        <Bs4Circle className="text-secondary" size={35} />
                      </div>
                      <div className="col-auto d-flex align-items-center">
                        <h5 className="h4 text-secondary">Elige un método de pago</h5>
                      </div>
                    </div>
                    <div className="payment-method card p-3 mt-3 mb-3">
                      <div className="row d-flex align-items-center justify-content-center methods">
                        {paymentMethods.map((card) => (
                          <div
                            key={card.value}
                            className="col-sm-12 col-md-6 col-lg-6 item"
                            style={{ borderColor: card.color }}
                            onClick={() => handleCardClick(card.value)}
                          >
                            <div className={`default-cover ${paymentMethod === card.value ? 'selected' : ''}`}>
                              <a>
                                <ImageWithBasePath
                                  src={card.image}
                                  alt="Payment Method"
                                />
                                <span className="text-orange fw-bold">{card.label}</span>
                              </a>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    {paymentMethod &&
                      <div className="d-grid btn-block">
                        <div className="btn btn-primary" onClick={handlePayment}>
                          Proceder con el pago de: {formatearMoneda(total)}
                        </div>
                      </div>
                    }
                  </>
                }
              </>
            }
          </aside>
        </div>
      </div>
      {
        showCashModal &&
        <CashModal id="cash-modal" show={showCashModal} onHide={() => setShowCashModal(false)} totalAmount={total} onSubmit={onSaveSale} />
      }
      {
        showMercadoPagoModal &&
        <MercadoPagoModal id="mercadopago-modal" show={showMercadoPagoModal} onHide={() => setShowMercadoPagoModal(false)} totalAmount={total} onSubmit={onSaveSale} />
      }
      {
        showUserSalesModal &&
        <UserSalesModal id="user-sales-modal" show={showUserSalesModal} onHide={() => setShowUserSalesModal(false)} userId={userDetails?.id} />
      }
      {
        showCashOutflowModal &&
        <CashOutflowModal id="cash-outflow-modal" show={showCashOutflowModal} onHide={() => setShowCashOutflowModal(false)} />
      }
      <div
        className="modal fade"
        id="create"
        tabIndex={-1}
        aria-labelledby="create"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Create</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="input-blocks">
                      <label>Customer Name</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="input-blocks">
                      <label>Email</label>
                      <input type="email" className="form-control" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="input-blocks">
                      <label>Phone</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="input-blocks">
                      <label>Country</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="input-blocks">
                      <label>City</label>
                      <input type="text" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="input-blocks">
                      <label>Address</label>
                      <input type="text" />
                    </div>
                  </div>
                </div>
                <div className="modal-footer d-sm-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-cancel"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <Link to="#" className="btn btn-submit me-2">
                    Submit
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SimplePos