import React, { useEffect, useState } from 'react'
import ImageWithBasePath from '../../core/img/imagewithbasebath'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { Table } from 'antd';
import { convertToOptions, fechaFormat, fechaHoraFormat, formatearFecha, noOptionsText } from '../../core/utils';
import { CheckCircle, Edit, Eye, Filter, HelpCircle, MessageSquare, Plus, X, XCircle } from 'react-feather';
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setNotes, setShowRemoteResponseToast, setUserList, setVacationList } from '../../core/redux/action';
import { Users } from '../../services/internal/users';
import { showInternalErrorAlert } from '../components/customAlert';
import { all_routes } from '../../Router/all_routes';
import ToolsHead from '../components/toolsHead';
import FilterControl from '../components/filterControl';
import NotesEditModal from '../../core/modals/applications/notesEditModal';
import { Statuses } from '../../services/internal/statuses';
import NotesModal from '../../core/modals/applications/notesModal';
import { Vacations } from '../../services/internal/vacations';
import VacationModal from '../../core/modals/hrm/addVacation';

const LeavesEmployee = () => {
    const dataSource = useSelector((state) => state.vacationList);
    const userList = useSelector((state) => state.userList);
    const notes = useSelector((state) => state.notes);
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loading);
    const token = useSelector((state) => state.token);
    const [filterCode, setFilterCode] = useState("");
    const [filterStatus, setFilterStatus] = useState("");
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [showPurchaseModal, setShowPurchaseModal] = useState(false);
    const [showNotesModal, setShowNotesModal] = useState(false);
    const [showNotesEditModal, setShowNotesEditModal] = useState(false);
    const [newPurchase, setNewPurchase] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [userOptions, setUserOptions] = useState([]);
    const [filterUser, setFilterUser] = useState("");
    const route = all_routes;
    const statusOptions = [
        Statuses.Values.Requested,
        Statuses.Values.Approved,
        Statuses.Values.Rejected,
    ];

    const onChangeName = (e) => {
        setFilterCode(e.target.value.trim())
    }

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure(err.response.data));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadVacations = async () => {

        let filters = {};
        filters.enabled = true;

        if (filterCode) {
            filters.code = filterCode;
        }
        if (filterStatus) {
            filters.statusId = filterStatus.value;
        }
        if (filterUser) {
            filters.requesterUserId = filterUser.value;
        }

        try {
            dispatch(fetchDataRequest());

            if (userList.length === 0) {
                const response = await Users.getList(token, filters);
                dispatch(setUserList(response.data.data));
            }
            const response = await Vacations.getList(token, filters);
            dispatch(setVacationList(response.data.data));
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onChangeStatus = async (vacationId, status) => {
        try {
            let data = Vacations.requestData;
            data = {};
            data.comment = notes ? JSON.stringify(cleanNotes(notes)) : null;
            data.id = vacationId;

            dispatch(fetchDataRequest());
            if (status.value === Statuses.Values.Approved.value) {
                await Vacations.putApprove(token, data);
            } else {
                await Vacations.putReject(token, data);
            }
            dispatch(setShowRemoteResponseToast(true));
            dispatch(setNotes(null));
            dispatch(fetchDataSuccess(true));

            await onLoadVacations();
        }
        catch (err) {
            handleException(err);
        }
    }

    const onNewPurchase = () => {
        setCurrentItem(null);
        setNewPurchase(true);
        setShowPurchaseModal(true);
    }

    const onEditItem = (record) => {
        let notes = record.comment;
        if (!notes) {
            notes = "[]";
        }
        dispatch(setNotes(JSON.parse(notes)))

        setCurrentItem(record);
        if (record.provider?.id) {
            setNewPurchase(false);
            setShowPurchaseModal(true);
        }
    }

    const onHidePurchaseModal = async (refresh) => {
        setCurrentItem(null);
        setShowPurchaseModal(false);
        if (refresh) {
            await onLoadVacations();
        }
    }

    const onShowNotesModal = (record) => {
        setCurrentItem(record);
        let notes = record.comment;
        if (!notes) {
            notes = "[]";
        }
        dispatch(setNotes(JSON.parse(notes)))
        setShowNotesModal(true);
    }

    const onHideNotesModal = async () => {
        setCurrentItem(null);
        dispatch(setNotes(null));
        setShowNotesModal(false);
    }

    const onShowNotesEditModal = (record) => {
        setCurrentItem(record);
        let notes = record.comment;
        if (!notes) {
            notes = "[]";
        }
        dispatch(setNotes(JSON.parse(notes)))
        setShowNotesEditModal(true);
    }

    const onHideNotesEditModal = () => {
        setShowNotesEditModal(false);
    }

    const showConfirmAlert = async (record, status) => {
        setCurrentItem(record);

        let title = "";
        let html = "";
        let confirmButtonText = "";
        let confirmButtonColor = null;
        let icon = "";
        switch (status.value) {
            case Statuses.Values.Approved.value:
                title = "¿<strong>Aprobar</strong> solicitud?";
                html = "No podrás realizar cambios una vez que confirmes.";
                icon = "info";
                confirmButtonText = "Aprobar solicitud";
                confirmButtonColor = "#3fc3ee";
                break;
            case Statuses.Values.Rejected.value:
                title = "¿<strong>Rechazar</strong> solicitud?";
                html = "No podrás realizar cambios una vez que confirmes.";
                icon = "warning";
                confirmButtonText = "Rechazar solicitud";
                confirmButtonColor = "#FF9900";
                break;
            default:
                title = "¿<strong>Recibir</strong> solicitud?";
                html = "No podrás realizar cambios una vez que confirmes.";
                icon = "info";
                confirmButtonText = "He recibido la solicitud";
                confirmButtonColor = "#3fc3ee";
        }

        const result = await MySwal.fire({
            title: title,
            html: html,
            icon: icon,
            showCancelButton: true,
            cancelButtonText: "Regresar",
            confirmButtonText: confirmButtonText,
            confirmButtonColor: confirmButtonColor,
        });

        if (result.isConfirmed) {
            await onChangeStatus(record.id, status);
        } else {
            MySwal.close();
        }
    }

    const toggleFilterVisibility = () => {
        setUserOptions(convertToOptions(userList));
        setIsFilterVisible((prevVisibility) => !prevVisibility);
        onLoadVacations();
    };

    const calculateImagePath = (userDetails) => {
        return userDetails?.imagePath ? userDetails?.imagePath : "assets/img/profiles/profile.png";
    }

    const calculateName = (userDetails) => {
        let fullName = `${userDetails.name ? userDetails.name : ''} ${userDetails.surname ? userDetails.surname : ''} ${userDetails.lastname ? userDetails.lastname : ''}`;
        return fullName.trim() ? fullName : userDetails.alias;
    }

    const cleanNotes = () => {
        const cleanedNotes = notes.map((obj) => {
            const newObj = {};
            for (const prop in obj) {
                if (prop !== 'key') {
                    newObj[prop] = obj[prop];
                }
            }
            return newObj;
        });
        return cleanedNotes;
    }

    useEffect(() => {
        if (dataSource.length === 0) {
            onLoadVacations();
        }
    }, []);

    const columns = [
        {
            title: "Fecha y Hora",
            dataIndex: "requestedDate",
            align: "center",
            render: (text, record) => (
                <span>{formatearFecha(record.requestedDate ? record.requestedDate : new Date().toISOString(), fechaHoraFormat)}</span>
            ),
            sorter: (a, b) => a.requestedDate?.localeCompare(b.requestedDate),
            defaultSortOrder: "descend"
        },
        {
            title: "Código",
            dataIndex: "code",
            align: "center",
            render: (text) => (
                <span className="text-orange">{text}</span>
            ),
            sorter: (a, b) => a.code?.localeCompare(b.code)
        },
        {
            title: "Solicitante",
            dataIndex: "requesterUser",
            align: "center",
            render: (text, record) => (
                <span className="productimgname">
                    <Link
                        key={record.id}
                        to={`${route.profile}?code=${record.requesterUser?.id}`}
                        className="product-img stock-img"
                    >
                        <ImageWithBasePath alt="avatar" src={calculateImagePath(record.requesterUser)} />
                    </Link>
                    <Link to={`${route.profile}?code=${record.requesterUser?.id}`} className="text-primary fw-semibold">{calculateName(record.requesterUser)}</Link>
                </span>
            ),
            sorter: (a, b) => a.requesterUser?.id - b.requesterUser?.id
        },
        {
            title: "Fecha de inicio",
            dataIndex: "dateStart",
            align: "center",
            render: (text, record) => (
                <span>{formatearFecha(record.dateStart, fechaFormat)}</span>
            ),
            sorter: (a, b) => a.dateStart?.localeCompare(b.dateStart)
        },
        {
            title: "Fecha fin",
            dataIndex: "dateEnd",
            align: "center",
            render: (text, record) => (
                <span>{formatearFecha(record.dateEnd, fechaFormat)}</span>
            ),
            sorter: (a, b) => a.dateEnd?.localeCompare(b.dateEnd)
        },
        {
            title: "Estatus",
            dataIndex: "status.name",
            align: "center",
            render: (text, record) => (
                <>
                    {record.status?.value === "Aprobado" && (
                        <span className="badge badges-success">{record.status?.value}</span>
                    )}
                    {record.status?.value === "Rechazado" && (
                        <span className="badge badges-danger">{record.status?.value}</span>
                    )}
                    {record.status?.value === "Solicitado" && (
                        <span className="badge badges-purple">{record.status?.value}</span>
                    )}
                </>
            ),
            sorter: (a, b) => a.status?.name?.localeCompare(b.status?.name)
        },
        {
            title: "Notas",
            align: "center",
            render: (text, record) => (
                record.comment
                    ? <a className="text-blue fw-semibold" onClick={() => onShowNotesModal(record)}>Ver Notas</a>
                    : <span>Ninguna</span>

            ),
        },
        {
            title: "Acción",
            align: "center",
            dataIndex: "action",
            render: (text, record) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        <Link
                            className="me-2 p-2"
                            to={`${route.leaveEmployeeDetails}?code=${record.id}`}
                        >
                            <Eye />
                        </Link>
                        {(record.status?.name === "Registrado" || record.status?.name === "Actualizado")
                            &&
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip-minus">Editar</Tooltip>}
                            >
                                <a className="confirm-text me-2 p-2" onClick={() => { onEditItem(record) }}>
                                    <Edit className="feather-edit" />
                                </a>
                            </OverlayTrigger>

                        }
                        {record.status?.name === "Solicitado"
                            &&
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip-minus">Comentar</Tooltip>}
                            >
                                <a className="confirm-text me-2 p-2" onClick={() => { onShowNotesEditModal(record) }}>
                                    <MessageSquare className="feather-truck" />
                                </a>
                            </OverlayTrigger>
                        }
                        {(record.status?.name === "Solicitado")
                            &&
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip-minus">Aprobar</Tooltip>}
                            >
                                <a
                                    className="confirm-text me-2 p-2"
                                    onClick={() => showConfirmAlert(record, Statuses.Values.Approved)}
                                >
                                    <CheckCircle className="feather-check-circle" />
                                </a>
                            </OverlayTrigger>
                        }
                        {(record.status?.name === "Solicitado")
                            &&
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip-minus">Rechazar</Tooltip>}
                            >
                                <a
                                    className="confirm-text me-2"
                                    onClick={() => showConfirmAlert(record, Statuses.Values.Rejected)}
                                >
                                    <XCircle className="feather-x-circle" />
                                </a>
                            </OverlayTrigger>
                        }
                    </div>
                </div>
            )
        },
    ];

    const renderSearchTooltip = (props) => (
        <Tooltip id="search-tooltip" {...props}>
            Ingresa el código de la solicitud
        </Tooltip>
    );
    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Solicitudes de Vacaciones</h4>
                            <h6>Puedes gestionar las solicitudes de vacaciones registradas en el sistema.</h6>
                        </div>
                    </div>
                    <ToolsHead showExportData={true} showExcel={true} onReload={onLoadVacations} />
                    <div className="page-btn">
                        <a
                            className="btn btn-added"
                            onClick={() => onNewPurchase()}
                        >
                            <Plus className="me-2 iconsize" />
                            Nueva solicitud
                        </a>
                    </div>
                </div>
                {/* /product list */}
                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input
                                        type="text"
                                        placeholder="Búsqueda rápida"
                                        className="form-control form-control-sm formsearch"
                                        onChange={e => onChangeName(e)}
                                        onBlur={onLoadVacations}
                                        disabled={loading}
                                    />
                                    <a className="btn btn-searchset">
                                        <i data-feather="search" className="feather-search" />
                                    </a>
                                </div>
                                <OverlayTrigger placement="top" overlay={renderSearchTooltip}>
                                    <a className="link-primary">
                                        <HelpCircle />
                                    </a>
                                </OverlayTrigger>
                            </div>
                            <div className="search-path">
                                <div
                                    className={`btn btn-filter ${isFilterVisible ? "setclose" : ""}`}
                                    id="filter_search"
                                    onClick={toggleFilterVisibility}
                                >
                                    <Filter className="filter-icon"
                                    />
                                    <span>
                                        <X className="filter-icon" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/* /Filter */}
                        <div
                            className={`card${isFilterVisible ? " visible" : ""}`}
                            id="filter_inputs"
                            style={{ display: isFilterVisible ? "block" : "none" }}
                        >
                            <div className="card-body pb-0">
                                <div className="row">
                                    <div className="col-lg-4 col-sm-12 col-12 mb-3">
                                        <Select
                                            options={userOptions}
                                            onChange={(value) => setFilterUser(value)}
                                            noOptionsMessage={() => noOptionsText}
                                            isClearable
                                            placeholder="Elige un empleado"
                                            components={{ Control: FilterControl }}
                                            isDisabled={loading}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-sm-12 col-12 mb-3">
                                        <Select
                                            options={statusOptions}
                                            onChange={(value) => setFilterStatus(value)}
                                            noOptionsMessage={() => noOptionsText}
                                            isClearable
                                            placeholder="Elige un estatus"
                                            components={{ Control: FilterControl }}
                                            isDisabled={loading}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-sm-12 col-12 mb-3">
                                        <button
                                            className="btn btn-outline-primary w-100"
                                            onClick={() => onLoadVacations()}
                                            disabled={loading}
                                        >
                                            <span data-feather="check" className="feather-check" />Aplicar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /Filter */}
                        <div className="table-responsive">
                            <Table
                                className="table"
                                rowKey={(record) => record?.id}
                                columns={columns}
                                loading={loading}
                                dataSource={dataSource}
                                size="small"
                                pagination={
                                    {
                                        position: ["topRight"],
                                        total: dataSource.length,
                                        showTotal: (total) => `Total: ${total}`,
                                        showSizeChanger: true
                                    }
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            {newPurchase
                ? showPurchaseModal
                    ? <VacationModal id="add-vacation" show={showPurchaseModal} onHide={onHidePurchaseModal} isNew={true} />
                    : null
                : showPurchaseModal
                    ? <VacationModal id="edit-vacation" show={showPurchaseModal} onHide={onHidePurchaseModal} currentId={currentItem.id} />
                    : null
            }
            {showNotesModal
                ? <NotesModal id="notes" show={showNotesModal} onHide={onHideNotesModal} />
                : null
            }
            {showNotesEditModal
                ? <NotesEditModal id="notes" show={showNotesEditModal} onHide={onHideNotesEditModal} />
                : null
            }
        </div>
    );
}

export default LeavesEmployee
