import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import FormCreatedBy from "../../../feature-module/components/createdByForm";
import Select from "react-select";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setShowRemoteResponseToast, setUserList } from "../../redux/action";
import { showInternalErrorAlert } from "../../../feature-module/components/customAlert";
import { Controller, useForm } from "react-hook-form";
import variables from "../../../style/scss/utils/_variables.scss";
import { fechaFormat, generarCodigo } from "../../utils";
import { Vacations } from "../../../services/internal/vacations";
import { Users } from "../../../services/internal/users";
import DatePicker, { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { Calendar } from "react-feather";

registerLocale('es', es);

const sd = new Date();
const ed = new Date();
sd.setHours(0, 0, 0, 0);
ed.setHours(0, 0, 0, 0);

const VacationModal = ({ id, show = false, isNew = false, currentId, onHide }) => {
    const loading = useSelector(state => state.loading);
    const token = useSelector(state => state.token);
    const userList = useSelector(state => state.userList);
    const userInfo = useSelector(state => state.userInfo);
    const notes = useSelector(state => state.notes);
    const dispatch = useDispatch();
    const { setValue, register, handleSubmit, reset, formState: { errors }, control } = useForm();
    const [userOptions, setUserOptions] = useState([]);
    const [startDate, setStartDate] = useState(sd);
    const [endDate, setEndDate] = useState(ed);

    const calculateResponse = (isLoad, data) => {
        if (isNew) {
            return Vacations.postSingle(token, data);
        }

        if (isLoad) {
            return Vacations.getSingle(token, data.id);
        }

        return Vacations.putSingle(token, data);
    }

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure(err.response.data));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onSaveItem = async (formData) => {
        try {
            let data = Vacations.requestData;
            data = {};
            data.requesterUserId = formData.requesterUserId?.value;
            data.dateStart = startDate.toISOString();
            data.dateEnd = endDate.toISOString();
            data.code = generarCodigo();
            if (isNew) {
                if (formData.comment) {
                    let newComment = [{
                        date: new Date().toISOString(),
                        user: userInfo?.given_name,
                        comment: formData.comment
                    }];
                    data.comment = JSON.stringify(newComment);
                }
            } else {
                if (notes.length > 0) {
                    const cleanedNotes = cleanNotes();
                    data.comment = JSON.stringify(cleanedNotes);
                }
            }

            dispatch(fetchDataRequest());
            const response = await calculateResponse(false, data);
            dispatch(fetchDataSuccess(response.data.success));
            dispatch(setShowRemoteResponseToast(true));
            clearForm();
            onModalHide(null, true);
        }
        catch (err) {
            handleException(err);
        }
    }

    const convertToOptions = (users) => {
        let options = [];
        users.map((i) => {
            options.push(
                {
                    label: calculateName(i),
                    value: i.id
                });
        });
        return options;
    }

    const calculateName = (userDetails) => {
        let fullName = `${userDetails.name ? userDetails.name : ''} ${userDetails.surname ? userDetails.surname : ''} ${userDetails.lastname ? userDetails.lastname : ''}`;
        return fullName.trim() ? fullName : userDetails.alias;
    }

    const onLoadOptions = async () => {
        try {
            let filters = {};
            filters.enabled = true;

            dispatch(fetchDataRequest());
            console.log(userInfo)
            if (userList.length === 0) {
                const response = await Users.getList(token, filters);
                dispatch(setUserList(response.data.data));
                setUserOptions(convertToOptions(response.data.data));
            } else {
                const users = convertToOptions(userList);
                setUserOptions(users);
                setValue('requesterUserId', users.find((i) => i.value === Number(userInfo?.nameid)));
            }
            dispatch(fetchDataSuccess(true));
        }
        catch (err) {
            handleException(err);
        }
    }

    /*const onLoadItem = async () => {
        try {
            let data = Branches.requestData;
            data = {};
            data.id = currentId;

            let filters = {};
            filters.enabled = true;

            dispatch(fetchDataRequest());

            let users = [];
            if (userList.length === 0) {
                const response = await Users.getList(token, filters);
                dispatch(setUserList(response.data.data));
                setUserOptions(convertToOptions(response.data.data));
            } else {
                users = userList;
                setUserOptions(users);
            }


            const response = await calculateResponse(true, data);
            setValue('requesterUserId', users.find((i) => i.value === userInfo?.id));
            setValue('code', response.data.data.code);
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }*/

    const cleanNotes = () => {
        const cleanedNotes = notes.map((obj) => {
            const newObj = {};
            for (const prop in obj) {
                if (prop !== 'key') {
                    newObj[prop] = obj[prop];
                }
            }
            return newObj;
        });
        return cleanedNotes;
    }

    const clearForm = () => {
        reset(formValues => ({
            ...formValues,
            name: null,
            location: null,
            email: null,
            phone: null,
            workshiftId: null,
            code: null
        }));
    }

    const onModalHide = (e, refresh) => {
        e?.preventDefault();
        clearForm();
        onHide(refresh);
    }

    useEffect(() => {
        if (userInfo) {
            onLoadOptions();
        }
    }, [currentId, userInfo]);

    return (
        <Modal show={show} centered onHide={onModalHide} id={id}>
            <form onSubmit={handleSubmit((data) => onSaveItem(data))}>
                <Modal.Header className="custom-modal-header" closeButton={!loading}>
                    <Modal.Title>
                        <h4>{isNew ? "Registro de Vacaciones" : "Actualizar Sucursal"}</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12 col-12 mb-3">
                            <label className="form-label">Usuario<span className="text-danger"> *</span></label>
                            <Controller
                                name="requesterUserId"
                                control={control}
                                render={({ field: { onChange, value, ref } }) => (
                                    <Select
                                        inputRef={ref}
                                        className={errors.requesterUserId ? "form-control is-invalid" : ""}
                                        options={userOptions}
                                        placeholder="Elige a un usuario"
                                        value={value}
                                        onChange={onChange}
                                        isClearable
                                        styles={{ menu: provided => ({ ...provided, zIndex: 100 }) }}
                                        noOptionsMessage={() => "Sin opciones"}
                                        isDisabled={loading}
                                    />
                                )}
                                rules={{ required: 'Requerido' }}
                            />
                            {
                                errors.requesterUserId ?
                                    <div className="invalid-feedback">
                                        {errors.requesterUserId.message}
                                    </div>
                                    : null
                            }
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12 mb-3">
                            <label className="form-label">Fecha inicio<span className="text-danger"> *</span></label>
                            <div className="input-group flex-wrap">
                                <span className="input-group-text"><Calendar size={15} color={variables.primaryColor} /></span>
                                <Controller
                                    control={control}
                                    name='start'
                                    render={({ field }) => (
                                        <DatePicker
                                            onChange={(date) => { field.onChange(date); setStartDate(date); }}
                                            minDate={startDate}
                                            selected={field.value}
                                            locale="es"
                                            dateFormat={fechaFormat}
                                            todayButton="Clic aquí para Hoy"
                                            className={!errors.start ? "form-control" : "form-control is-invalid"}
                                            wrapperClassName={"form-control"}
                                        />
                                    )}
                                    rules={{ required: 'Requerido' }}
                                />
                                {
                                    errors.start ?
                                        <div className="invalid-feedback">
                                            {errors.start.message}
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12 mb-3">
                            <label className="form-label">Fecha fin<span className="text-danger"> *</span></label>
                            <div className="input-group flex-wrap">
                                <span className="input-group-text"><Calendar size={15} color={variables.primaryColor} /></span>
                                <Controller
                                    control={control}
                                    name='end'
                                    render={({ field }) => (
                                        <DatePicker
                                            minDate={startDate}
                                            onChange={(date) => { field.onChange(date); setEndDate(date); }}
                                            selected={field.value}
                                            locale="es"
                                            dateFormat={fechaFormat}
                                            todayButton="Clic aquí para Hoy"
                                            className={!errors.end ? "form-control" : "form-control is-invalid"}
                                            wrapperClassName={"form-control"}
                                        />
                                    )}
                                    rules={{ required: 'Requerido' }}
                                />
                                {
                                    errors.end ?
                                        <div className="invalid-feedback">
                                            {errors.end.message}
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="col-12 mb-3">
                            <label className="form-label">Comentarios</label>
                            <textarea
                                rows={3}
                                maxLength={800}
                                placeholder="Escribe tus comentarios"
                                className={!errors.comment ? "form-control" : "form-control is-invalid"}
                                disabled={loading}
                                {...register("comment")}
                            />
                            {
                                errors.comment ?
                                    <div className="invalid-feedback">
                                        {errors.comment.message}
                                    </div>
                                    : null
                            }
                            <p>Máximo 800 caracteres.</p>
                        </div>
                    </div>
                    <div className="mb-3">
                        <FormCreatedBy isNew={isNew} />
                    </div>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <button
                        className="btn btn-cancel me-2"
                        onClick={(e) => onModalHide(e, false)}
                        disabled={loading}
                    >
                        Cancelar
                    </button>
                    {
                        loading ?
                            <button disabled={loading} className="btn btn-submit">
                                <Spinner animation="border" role="status" size="sm" />
                            </button>
                            :
                            <input type="submit" value={isNew ? "Registrar" : "Actualizar"} className="btn btn-submit" />
                    }
                </Modal.Footer>
            </form>
        </Modal>

    );
};

VacationModal.propTypes = {
    id: PropTypes.string.isRequired,
    show: PropTypes.bool,
    isNew: PropTypes.bool,
    currentId: PropTypes.number,
    onHide: PropTypes.func
};


export default VacationModal;
