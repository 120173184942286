import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setShowRemoteResponseToast } from "../../redux/action";
import { showInternalErrorAlert } from "../../../feature-module/components/customAlert";
import { fechaHoraFormat, formatearFecha, formatearMoneda, generarCodigo } from "../../utils";
import { Sales } from "../../../services/internal/sales";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { all_routes } from "../../../Router/all_routes";
import { Eye } from "react-feather";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const UserCashBreakModal = ({ id, show = false, onHide }) => {

    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);
    const loading = useSelector((state) => state.loading);
    const token = useSelector((state) => state.token);
    const userDetails = useSelector((state) => state.userDetails);
    const [dataSource, setDataSource] = useState([]);
    const route = all_routes;

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure(err.response.data));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadSales = async () => {
        try {
            dispatch(fetchDataRequest());
            let filters = {};
            filters.saleUserId = userDetails?.id;
            filters.branchId = userDetails?.branch?.id;
            const response = await Sales.getBreakList(token, filters);
            setDataSource(response.data.data);
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onBreakRegister = async () => {
        try {
            dispatch(fetchDataRequest());
            let data = Sales.requestBreakData;
            data = {};
            data.code = generarCodigo();
            data.terminal = userDetails?.alias;
            data.branchId = userDetails?.branch?.id;
            const response = await Sales.postBreakRegister(token, data);
            dispatch(fetchDataSuccess(response.data.success));

            await onLoadSales();
        }
        catch (err) {
            handleException(err);
        }

    }

    const showConfirmAlert = async (e) => {
        e?.preventDefault();

        const result = await MySwal.fire({
            title: "¿Estás seguro?",
            html: `Asegúrate de haber registrado las <strong>salidas de efectivo</strong> necesarias.`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Calcular",
            customClass: {
                confirmButton: "btn btn-warning",
            },
        });

        if (result.isConfirmed) {
            await onBreakRegister();
        } else {
            MySwal.close();
        }
    }

    const calculateDiff = (cashbreak, needStatus) => {
        const cashTotal = isNaN(Number(cashbreak.cashTotal)) ? 0 : Number(cashbreak.cashTotal);
        const cashOutcomesTotal = isNaN(Number(cashbreak.cashOutcomesTotal)) ? 0 : Number(cashbreak.cashOutcomesTotal);

        const result = cashTotal - cashOutcomesTotal;

        if (result > 0) {
            return needStatus ?
                <span className="badge badges-success">Sobrante</span>
                :
                <span className="text-success fw-semibold">{formatearMoneda(result)}</span>;
        }
        if (result < 0) {
            return needStatus ?
                <span className="badge badges-danger">Faltante</span>
                :
                <span className="text-danger fw-semibold">{formatearMoneda(result)}</span>;
        }

        return needStatus ?
            <span className="badge badges-purple">Cuadre</span>
            :
            <span className="text-purple fw-semibold">{formatearMoneda(result)}</span>;
    }

    const onModalHide = () => {
        onHide();
    }

    useEffect(() => {
        onLoadSales();
    }, [userDetails]);

    const columns = [
        {
            title: "Fecha y Hora",
            dataIndex: "registered",
            align: "center",
            render: (text, record) => (
                <span>{formatearFecha(record.registered ? record.registered : new Date().toISOString(), fechaHoraFormat)}</span>
            ),
            sorter: (a, b) => a.registered?.localeCompare(b.registered),
            defaultSortOrder: "descend"
        },
        {
            title: "Código del corte",
            dataIndex: "code",
            align: "center",
            render: (text) => (
                <span className="text-orange">{text}</span>
            ),
            sorter: (a, b) => a.code?.localeCompare(b.code)
        },
        {
            title: "Sucursal",
            dataIndex: "branch",
            render: (text, record) => (
                <span className="text-secondary fw-semibold">{record.branch}</span>
            ),
            sorter: (a, b) => a.branch.localeCompare(b.branch)
        },
        {
            title: "Núm. de ventas",
            dataIndex: "salesNumber",
            align: "center",
            render: (text,) => (
                <span className="badge badges-dark">{text}</span>
            ),
            sorter: (a, b) => a.salesNumber - b.salesNumber
        },
        {
            title: "Total en ventas",
            dataIndex: "salesTotal",
            align: "right",
            render: (text, record) => (
                <span className="text-info fw-bold">{formatearMoneda(record.salesTotal)}</span>
            ),
            sorter: (a, b) => a.salesTotal - b.salesTotal
        },
        {
            title: "Entradas de efectivo",
            dataIndex: "cashTotal",
            align: "right",
            render: (text, record) => (
                <span className="text-success fw-semibold">{formatearMoneda(record.total)}</span>
            ),
            sorter: (a, b) => a.cashTotal - b.cashTotal
        },
        {
            title: "Salidas de efectivo",
            dataIndex: "cashOutcomesNumber",
            align: "center",
            render: (text) => (
                <span className="badge badges-warning">{text ? text : 0}</span>
            ),
            sorter: (a, b) => a.cashOutcomesNumber - b.cashOutcomesNumber
        },
        {
            title: "Total en salidas",
            dataIndex: "cashOutcomesTotal",
            align: "right",
            render: (text, record) => (
                <span className="text-warning fw-bold">{formatearMoneda(record.cashOutcomesTotal)}</span>
            ),
            sorter: (a, b) => a.cashOutcomesTotal - b.cashOutcomesTotal
        },
        {
            title: "Neto calculado",
            align: "right",
            render: (text, record) => (calculateDiff(record, false)),
        },
        {
            title: "Estatus",
            align: "center",
            render: (text, record) => (calculateDiff(record, true)),
        },
        {
            title: "Acción",
            align: "center",
            dataIndex: "action",
            render: (text, record) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        <Link
                            className="me-2 p-2"
                            target="_blank" rel="noopener noreferrer"
                            to={`${route.cashBreakDetails}?code=${record?.id}`}
                        >
                            <Eye />
                        </Link>
                    </div>
                </div>
            )
        },
    ];

    return (
        <Modal show={show} centered onHide={onModalHide} id={id} backdrop="static" keyboard={false} size="xl">
            <Modal.Header className="custom-modal-header" closeButton={!loading}>
                <Modal.Title>
                    <h4>Mis Cortes de Caja</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Te mostramos los cortes de caja de tu sucursal asignada:</p>
                <div className="table-responsive">
                    <Table
                        className="table"
                        rowKey={(record) => record?.id}
                        columns={columns}
                        loading={loading}
                        dataSource={dataSource}
                        size="small"
                        pagination={
                            {
                                position: ["bottomCenter"],
                                total: dataSource.length,
                                showTotal: (total) => `Total de Ventas: ${total}`,
                                showSizeChanger: true
                            }
                        }
                    />
                </div>
            </Modal.Body>
            <Modal.Footer className='justify-content-center'>
                {
                    loading ?
                        <button disabled={loading} className="btn btn-submit">
                            <Spinner animation="border" role="status" size="sm" />
                        </button>
                        :
                        <button
                            className="btn btn-cancel me-2"
                            onClick={(e) => onModalHide(e, false)}
                            disabled={loading}
                        >
                            Regresar
                        </button>
                }
                {
                    !loading &&
                    <button
                        className="btn btn-submit me-2"
                        onClick={(e) => showConfirmAlert(e)}
                        disabled={loading}
                    >
                        Calcular Nuevo Corte
                    </button>
                }
            </Modal.Footer>
        </Modal>
    );
};

UserCashBreakModal.propTypes = {
    id: PropTypes.string.isRequired,
    show: PropTypes.bool,
    onHide: PropTypes.func,
};


export default UserCashBreakModal;
