import { baseConfig } from '../authentication';
import { axiosClientApi } from '../../axiosClientConfiguration';
import { postRequest, putRequest } from '../../axiosFunctions';

const requestData = {
  email: undefined,
  password: undefined,
  resetToken: undefined,
  currentPassword: undefined
}

const postRecovery = (data) => {
  return postRequest(
    axiosClientApi,
    "/Password/Recovery",
    data,
    baseConfig
  );
}

const putReset = (data) => {
  return putRequest(
    axiosClientApi,
    "/Password/Reset",
    data,
    baseConfig
  );
}

const putChange = (data) => {
  return putRequest(
    axiosClientApi,
    "/Password/Change",
    data
  );
}

export const Password = {
  requestData,
  postRecovery,
  putReset,
  putChange,
};
