import React from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";

const Error500 = () => {
  const route = all_routes;
  return (
    <div className="main-wrapper">
      <div className="error-box">
        <div className="error-img">
          <ImageWithBasePath
            src="assets/img/authentication/error-500.png"
            className="img-fluid"
            alt="error-500"
          />
        </div>
        <h3 className="h2 mb-3">Ups, algo salió mal...</h3>
        <p>
          Error en el servidor. Sentimos las molestias estamos resolviendo el problema, intenta más tarde por favor.
        </p>
        <Link to={route.home} className="btn btn-primary">
          Volver a inicio
        </Link>
      </div>
    </div>
  );
};

export default Error500;
