import axios from "axios";
import axiosRetry from "axios-retry";

export const quitarDiagonalAlFinal = (url) => {
  if (url.endsWith('/')) {
    url = url.slice(0, -1);
  }
  return url;
}

const baseUrlApi = quitarDiagonalAlFinal(process.env.REACT_APP_BASE_URL_API);

const axiosClientApi = axios.create({
  baseURL: baseUrlApi,
  timeout: process.env.REACT_APP_TIMEOUT_API,
});

axiosRetry(axiosClientApi, {
  retries: process.env.REACT_APP_AXIOS_RETRIES,
  retryDelay: (retryCount) => {
    return retryCount * process.env.REACT_APP_AXIOS_DELAY;
  },
  retryCondition: (error) => {
    return error?.response?.status >= 500;
  },
});


const baseUrlMp = quitarDiagonalAlFinal(process.env.REACT_APP_BASE_URL_MP);

const axiosClientMp = axios.create({
  baseURL: baseUrlMp,
  timeout: process.env.REACT_APP_TIMEOUT_API,
});

axiosRetry(axiosClientMp, {
  retries: process.env.REACT_APP_AXIOS_RETRIES,
  retryDelay: (retryCount) => {
    return retryCount * process.env.REACT_APP_AXIOS_DELAY;
  },
  retryCondition: (error) => {
    return error?.response?.status >= 500;
  },
});

export { axiosClientApi, axiosClientMp };
