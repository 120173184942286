import { setToken } from '../authentication';
import { axiosClientApi } from '../../axiosClientConfiguration';
import { deleteRequest, getRequest, postRequest, putRequest } from '../../axiosFunctions';

const requestData = {
  id: undefined,
  name: undefined,
  timeIn: {
    hour: undefined,
    minute: undefined,
    second: undefined,
    millisecond: undefined,
    microsecond: undefined,
    nanosecond: undefined,
    ticks: undefined
  },
  timeOut: {
    hour: undefined,
    minute: undefined,
    second: undefined,
    millisecond: undefined,
    microsecond: undefined,
    nanosecond: undefined,
    ticks: undefined
  },
  enabled: undefined,
  monday: undefined,
  tuesday: undefined,
  wednesday: undefined,
  thursday: undefined,
  friday: undefined,
  saturday: undefined,
  sunday: undefined
}

const getSingle = (token, id) => {
  return getRequest(
    axiosClientApi,
    `/Workshifts/Get/${id}`,
    setToken(token)
  );
}

const getList = (token, filters) => {
  let config = setToken(token);
  config.params = {
    name: filters.name,
    enabled: filters.enabled,
  };
  return getRequest(
    axiosClientApi,
    "/Workshifts/List",
    config
  );
}

const postSingle = (token, data) => {
  return postRequest(
    axiosClientApi,
    "/Workshifts",
    data,
    setToken(token)
  );
}

const putSingle = (token, data) => {
  return putRequest(
    axiosClientApi,
    "/Workshifts",
    data,
    setToken(token)
  );
}

const deleteSingle = (token, id) => {
  return deleteRequest(
    axiosClientApi,
    `/Workshifts/Delete/${id}`,
    setToken(token)
  );
}

export const Workshifts = {
  requestData,
  getSingle,
  getList,
  postSingle,
  putSingle,
  deleteSingle,
};
