import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatearMoneda, priceRegex } from '../../../core/utils';
import { DollarSign } from 'react-feather';
import variables from "../../../style/scss/utils/_variables.scss";
import { useSelector } from 'react-redux';

const ChangeCalculator = ({ totalAmount, onCashCallback }) => {

    const loading = useSelector(state => state.loading);

    const [givenAmount, setGivenAmount] = useState('');
    const [change, setChange] = useState(0);

    const onGivenAmountChange = (e) => {
        e?.preventDefault;
        const given = e.target.value;
        setGivenAmount(given);
        if (!given) {
            if (onCashCallback) {
                onCashCallback(given);
            }
            return;
        }
        calculateChange(given);
    }

    const calculateChange = (amount) => {
        const total = parseFloat(totalAmount);
        const given = parseFloat(amount);

        if (!isNaN(total) && !isNaN(given)) {
            const changeValue = given - total;
            setChange(changeValue.toFixed(2));
            if (onCashCallback) {
                onCashCallback(changeValue.toFixed(2));
            }
            return;
        }
        setChange(0);
        if (onCashCallback) {
            onCashCallback(0);
        }
    };

    const calculateChangeClass = () => {
        if (change < 0)
            return "text-danger fw-bold mb-3";
        if (change > 0)
            return "text-green fw-bold mb-3";

        return "text-dark fw-bold mb-3";
    }

    return (
        <div className='container'>
            <div className="row mb-3">
                <div className="col-6 text-end">
                    <label className="col-form-label">Cantidad Total:</label>
                </div>
                <div className="col-6">
                    <div className="input-group">
                        <span className="input-group-text"><DollarSign size={15} color={variables.textColor} /></span>
                        <input
                            type="text"
                            className="form-control text-end"
                            id="totalAmount"
                            value={totalAmount?.toFixed(2)}
                            disabled
                        />
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-6 text-end">
                    <label className="col-form-label">Cantidad Recibida:</label>
                </div>
                <div className="col-6">
                    <div className="input-group">
                        <span className="input-group-text"><DollarSign size={15} color={variables.textColor} /></span>
                        <input
                            type="text"
                            className="form-control text-end"
                            id="givenAmount"
                            value={givenAmount}
                            onChange={(e) => onGivenAmountChange(e)}
                            pattern={priceRegex}
                            disabled={loading}
                        />
                    </div>
                </div>
            </div>
            {change !== 0 &&
                <h4 className={calculateChangeClass()}>
                    Cambio: {formatearMoneda(change)}
                </h4>
            }
        </div>
    );
}

ChangeCalculator.propTypes = {
    totalAmount: PropTypes.number.isRequired,
    onCashCallback: PropTypes.func,
};

export default ChangeCalculator;