import React, { useEffect, useState } from 'react'
import ImageWithBasePath from '../../core/img/imagewithbasebath'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { PurchaseOrders } from '../../services/internal/purchaseOrders';
import { Table } from 'antd';
import { formatearMoneda, noOptionsText } from '../../core/utils';
import { Filter, HelpCircle, X } from 'react-feather';
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setArticleOptions, setPurchaseProductList, setShowRemoteResponseToast } from '../../core/redux/action';
import { showInternalErrorAlert } from '../components/customAlert';
import { all_routes } from '../../Router/all_routes';
import ToolsHead from '../components/toolsHead';
import FilterControl from '../components/filterControl';
import { Catalogs } from '../../services/internal/catalogs';
import { Statuses } from '../../services/internal/statuses';

const PurchaseProductList = () => {
    const dataSource = useSelector((state) => state.purchaseProductList);
    const articleOptions = useSelector((state) => state.articleOptions);
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loading);
    const token = useSelector((state) => state.token);
    const [filterCode, setFilterCode] = useState("");
    const [filterName, setFilterName] = useState("");
    const [filterStatus, setFilterStatus] = useState("");
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const route = all_routes;
    const statusOptions = [
        Statuses.Values.Registered,
        Statuses.Values.Updated,
        Statuses.Values.Requested,
        Statuses.Values.Pending,
        Statuses.Values.Approved,
        Statuses.Values.Received,
        Statuses.Values.Rejected,
        Statuses.Values.Canceled,
    ];

    const onChangeCode = (e) => {
        setFilterCode(e.target.value.trim())
    }

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure(err.response.data));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadPurchaseProducts = async () => {

        let filters = {};
        filters.enabled = true;

        if (filterName) {
            filters.name = filterName.label;
        }
        if (filterCode) {
            filters.code = filterCode;
        }
        if (filterStatus) {
            filters.statusId = filterStatus.value;
        }

        try {
            dispatch(fetchDataRequest());
            if (articleOptions.length === 0) {
                const responseA = await Catalogs.getOptions(token, Catalogs.Name.Articles);
                dispatch(setArticleOptions(responseA.data.data));
            }
            /*if (statusOptions.length === 0) {
                const responseB = await Catalogs.getOptions(token, Catalogs.Name.Statuses);
                dispatch(setStatusOptions(responseB.data.data));
            }*/
            const response = await PurchaseOrders.getArticlesList(token, filters);
            //console.log(response)
            dispatch(setPurchaseProductList(response.data.data));
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const toggleFilterVisibility = () => {
        setIsFilterVisible((prevVisibility) => !prevVisibility);
        onLoadPurchaseProducts();
    };

    useEffect(() => {
        if (dataSource.length === 0) {
            onLoadPurchaseProducts();
        }
    }, []);

    const columns = [
        {
            title: "Orden de compra",
            dataIndex: "id",
            align: "center",
            render: (text, record) => (
                <Link
                    to={`${route.purchasedetails}?code=${record.purchaseOrderId}`}
                    className="text-primary fw-bold"
                >
                    Ir a la orden
                </Link>
            ),
            sorter: (a, b) => a.id - b.id,
            defaultSortOrder: "descend"
        },
        {
            title: "Código de orden",
            dataIndex: "code",
            align: "center",
            render: (text, record) => (
                <Link
                    to={`${route.purchasedetails}?code=${record.purchaseOrderId}`}
                    className="text-orange"
                >
                    {record.code}
                </Link>
            ),
            sorter: (a, b) => a.code?.localeCompare(b.code),
        },
        {
            title: "Estatus de la orden",
            dataIndex: "statusId.value",
            align: "center",
            render: (text, record) => (
                <>
                    {record.statusId?.value === "Registrado" && (
                        <span className="badge badges-info">{record.statusId?.value}</span>
                    )}
                    {record.statusId?.value === "Actualizado" && (
                        <span className="badge badges-dark">{record.statusId?.value}</span>
                    )}
                    {record.statusId?.value === "Aprobado" && (
                        <span className="badge badges-success">{record.statusId?.value}</span>
                    )}
                    {record.statusId?.value === "Rechazado" && (
                        <span className="badge badges-danger">{record.statusId?.value}</span>
                    )}
                    {record.statusId?.value === "Solicitado" && (
                        <span className="badge badges-purple">{record.statusId?.value}</span>
                    )}
                    {record.statusId?.value === "Cancelado" && (
                        <span className="badge badges-danger">{record.statusId?.value}</span>
                    )}
                    {record.statusId?.value === "Pendiente" && (
                        <span className="badge badges-warning">{record.statusId?.value}</span>
                    )}
                    {record.statusId?.value === "Recibido" && (
                        <span className="badge badges-secondary">{record.statusId?.value}</span>
                    )}
                </>
            ),
            sorter: (a, b) => a.statusId?.value?.localeCompare(b.statusId?.value)
        },
        {
            title: "Producto",
            dataIndex: "name",
            render: (text, record) => (
                <span className="productimgname">
                    <Link key={record.id} to={`${route.productdetails}?code=${record?.article?.id}`} className="product-img stock-img">
                        <ImageWithBasePath
                            alt="producto"
                            src={record.article?.imageUrls?.length > 0
                                ? record.article?.imageUrls[0]
                                : "assets/img/product/default.png"}
                        />
                    </Link>
                    <Link to={`${route.productdetails}?code=${record?.article?.id}`} className="text-primary fw-semibold">{record.article?.name}</Link>
                </span>
            ),
            sorter: (a, b) => a.name?.localeCompare(b.name)
        },
        {
            title: "Categoría",
            dataIndex: "article.category.name",
            render: (text, record) => (
                <span className="text-secondary fw-semibold">{record.article?.category?.name}</span>
            ),
            sorter: (a, b) => a.article?.category?.name?.localeCompare(b.article?.category?.name)
        },
        {
            title: "Unidad de Medida",
            dataIndex: "article.unmed.name",
            align: 'center',
            render: (text, record) => (
                <span>{record.article?.unmed?.abbreviation}</span>
            ),
            sorter: (a, b) => a.article?.unmed?.abbreviation.localeCompare(b.article?.unmed?.abbreviation)
        },
        {
            title: "Solicitados",
            dataIndex: "requested",
            align: 'center',
            sorter: (a, b) => a.requested - b.requested,
            render: (text) => (
                <span className="badge badges-dark">{text}</span>
            ),
        },
        {
            title: "Enviados",
            dataIndex: "sent",
            align: 'center',
            sorter: (a, b) => a.sent - b.sent,
            render: (text) => (
                <span className="badge badges-warning">{text}</span>
            ),
        },
        {
            title: "Recibidos",
            dataIndex: "received",
            align: 'center',
            sorter: (a, b) => a.received - b.received,
            render: (text) => (
                <span className="badge badges-success">{text}</span>
            ),
        },
        {
            title: "Subtotal del pedido",
            dataIndex: "subTotal",
            align: 'right',
            sorter: (a, b) => a.subTotal - b.subTotal,
            render: (text) => (
                <span className="text-info fw-semibold">{formatearMoneda(text)}</span>
            ),
        },
    ];

    const renderSearchTooltip = (props) => (
        <Tooltip id="search-tooltip" {...props}>
            Ingresa el código de la orden de compra
        </Tooltip>
    );
    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Pedidos de órdenes de compras</h4>
                            <h6>Puedes visualizar la información de los pedidos de las órdenes de compra.</h6>
                        </div>
                    </div>
                    <ToolsHead showExportData={true} showExcel={true} onReload={onLoadPurchaseProducts} />
                </div>
                {/* /product list */}
                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input
                                        type="text"
                                        placeholder="Búsqueda rápida"
                                        className="form-control form-control-sm formsearch"
                                        onChange={e => onChangeCode(e)}
                                        onBlur={onLoadPurchaseProducts}
                                        disabled={loading}
                                    />
                                    <a className="btn btn-searchset">
                                        <i data-feather="search" className="feather-search" />
                                    </a>
                                </div>
                                <OverlayTrigger placement="top" overlay={renderSearchTooltip}>
                                    <a className="link-primary">
                                        <HelpCircle />
                                    </a>
                                </OverlayTrigger>
                            </div>
                            <div className="search-path">
                                <div
                                    className={`btn btn-filter ${isFilterVisible ? "setclose" : ""}`}
                                    id="filter_search"
                                    onClick={toggleFilterVisibility}
                                >
                                    <Filter className="filter-icon"
                                    />
                                    <span>
                                        <X className="filter-icon" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/* /Filter */}
                        <div
                            className={`card${isFilterVisible ? " visible" : ""}`}
                            id="filter_inputs"
                            style={{ display: isFilterVisible ? "block" : "none" }}
                        >
                            <div className="card-body pb-0">
                                <div className="row">
                                    <div className="col-lg-4 col-sm-12 col-12 mb-3">
                                        <Select
                                            options={articleOptions}
                                            onChange={(value) => setFilterName(value)}
                                            noOptionsMessage={() => noOptionsText}
                                            isClearable
                                            placeholder="Elige un producto"
                                            components={{ Control: FilterControl }}
                                            isDisabled={loading}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-sm-12 col-12 mb-3">
                                        <Select
                                            options={statusOptions}
                                            onChange={(value) => setFilterStatus(value)}
                                            noOptionsMessage={() => noOptionsText}
                                            isClearable
                                            placeholder="Elige un estatus de la orden"
                                            components={{ Control: FilterControl }}
                                            isDisabled={loading}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-sm-12 col-12 mb-3">
                                        <button
                                            className="btn btn-outline-primary w-100"
                                            onClick={() => onLoadPurchaseProducts()}
                                            disabled={loading}
                                        >
                                            <span data-feather="check" className="feather-check" />Aplicar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /Filter */}
                        <div className="table-responsive">
                            <Table
                                className="table"
                                rowKey={(record) => record?.id}
                                columns={columns}
                                loading={loading}
                                dataSource={dataSource}
                                size="small"
                                pagination={
                                    {
                                        position: ["topRight"],
                                        total: dataSource.length,
                                        showTotal: (total) => `Total: ${total}`,
                                        showSizeChanger: true
                                    }
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PurchaseProductList
