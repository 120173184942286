import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Loader = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const hideLoader = () => {
    setLoading(false);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      hideLoader();
    }, 600);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [location]); // Trigger useEffect when the pathname changes

  return loading ? (
    <div id="global-loader">
      <div className="whirly-loader"></div>
    </div>
  ) : <></>

};

export default Loader;
