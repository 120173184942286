import React from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";

const Error404 = () => {
  const route = all_routes;
  return (
    <div className="main-wrapper">
      <div className="error-box">
        <div className="error-img">
          <ImageWithBasePath
            src="assets/img/authentication/error-404.png"
            className="img-fluid"
            alt="error-404"
          />
        </div>
        <h3 className="h2 mb-3">Ups, algo anda mal...</h3>
        <p>
          Página no encontrada. Lo sentimos el contenido que buscas no existe o ha sido removido.
        </p>
        <Link to={route.home} className="btn btn-primary">
          Ir al inicio
        </Link>
      </div>
    </div>
  );
};

export default Error404;
