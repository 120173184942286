import { setToken } from '../authentication';
import { axiosClientApi } from '../../axiosClientConfiguration';
import { deleteRequest, getRequest, postRequest, putRequest } from '../../axiosFunctions';

const requestData = {
  name: undefined,
  enabled: undefined,
  imagePath: undefined,
  recipeBookId: undefined,
  recipeIds: [
    undefined
  ],
  ingredients: [
    {
      element: undefined,
      quantity: undefined
    }
  ],
  results: [
    {
      element: undefined,
      quantity: undefined
    }
  ]
}

const requestDataExecute = {
  recipeId: undefined,
  quantity: undefined,
  branchId: undefined
}

const getSingle = (token, id) => {
  return getRequest(
    axiosClientApi,
    `/Recipes/Get/${id}`,
    setToken(token)
  );
}

const getList = (token, filters) => {
  let config = setToken(token);
  config.params = {
    name: filters.name,
    idRecipeBook: filters.idRecipeBook,
    enabled: filters.enabled
  };
  return getRequest(
    axiosClientApi,
    "/Recipes/List",
    config
  );
}

const postSingle = (token, data) => {
  return postRequest(
    axiosClientApi,
    "/Recipes",
    data,
    setToken(token)
  );
}

const postExecute = (token, data) => {
  return postRequest(
    axiosClientApi,
    "/Recipes/Execute",
    data,
    setToken(token)
  );
}

const putSingle = (token, data) => {
  return putRequest(
    axiosClientApi,
    "/Recipes",
    data,
    setToken(token)
  );
}

const deleteSingle = (token, id) => {
  return deleteRequest(
    axiosClientApi,
    `/Recipes/Delete/${id}`,
    setToken(token)
  );
}

export const Recipes = {
  requestData,
  requestDataExecute,
  getSingle,
  getList,
  postSingle,
  postExecute,
  putSingle,
  deleteSingle,
};
