export const leavedata = [
	{
		"id":1,
		"name": "Año nuevo",
		"date": "01 Ene 2024",
		"duration": "1",
		"createdon": "04 Aug 2023",
		"status": "Active",
		
	},
	{
		"id":2,
		"name": "Día de la constitución",
		"date": "5 Feb 2024",
		"duration": "1",
		"createdon": "31 Jan 2022",
		"status": "Active",
		
	},
	{
		"id":3,
		"name": "Día de la revolución",
		"date": "20 Nov 2024",
		"duration": "1",
		"createdon": "21 July 2023",
		"status": "Active",
		
	},
	{
		"id":4,
		"name": "Día del trabajo",
		"date": "01 May 2024",
		"duration": "1",
		"createdon": "15 May 2023",
		"status": "Active",
		
	},
	{
		"id":5,
		"name": "Día de la independencia",
		"date": "16 Sep 2024",
		"duration": "1",
		"createdon": "04 Aug 2023",
		"status": "Active",
		
	}
]