export const ManageStocksdata = [
  {
    Warehouse: "Lobar Handy",
    SKU: "DS2GE45674",
    Shop: "Selosy",
    Product: {
      Name: "Nike Jordan",
      Image: "assets/img/products/stock-img-02.png"
    },
    Date: "25 Jul 2023",
    Person: {
      Name: "Steven",
      Image: "assets/img/users/user-08.jpg"
    },
    Quantity: 9,
    OutFlow: "Mal Estado"
  },
  {
    Warehouse: "Quaint Warehouse",
    SKU: "DS2GE45674",
    Shop: "Logerro",
    Product: {
      Name: "Apple Series 5 Watch",
      Image: "assets/img/products/stock-img-03.png"
    },
    Date: "28 Jul 2023",
    Person: {
      Name: "Gravely",
      Image: "assets/img/users/user-04.jpg"
    },
    Quantity: 130,
    OutFlow: "Mal Estado"
  },
  {
    Warehouse: "Traditional Warehouse",
    SKU: "DS2GE45674",
    Shop: "Vesloo",
    Product: {
      Name: "Amazon Echo Dot",
      Image: "assets/img/products/stock-img-04.png"
    },
    Date: "24 Jul 2023",
    Person: {
      Name: "Kevin",
      Image: "assets/img/users/user-09.jpg"
    },
    Quantity: 99,
    OutFlow: "Ajuste de Inventario"
  },
  {
    Warehouse: "Cool Warehouse",
    SKU: "DS2GE45674",
    Shop: "Crompy",
    Product: {
      Name: "Lobar Handy",
      Image: "assets/img/products/stock-img-05.png"
    },
    Date: "15 Jul 2023",
    Person: {
      Name: "Grillo",
      Image: "assets/img/users/user-10.jpg"
    },
    Quantity: 150,
    OutFlow: "Merma"
  }
]
