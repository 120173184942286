import { setToken } from '../authentication';
import { axiosClientApi } from '../../axiosClientConfiguration';
import { getRequest, putRequest } from '../../axiosFunctions';

const requestData = {
  id: undefined,
  comment: undefined,
  status: undefined
}

const getSingle = (token, id) => {
  return getRequest(
    axiosClientApi,
    `/Transfers/Get/${id}`,
    setToken(token)
  );
}

const getList = (token, filters) => {
  let config = setToken(token);
  config.params = {
    code: filters.code,
    statusId: filters.statusId,
    originPrivider: filters.originPrivider,
    originBranchId: filters.originBranchId,
    destinyBranchId: filters.destinyBranchId,
  };
  return getRequest(
    axiosClientApi,
    "/Transfers/List",
    config
  );
}

const putSingle = (token, data) => {
  return putRequest(
    axiosClientApi,
    "/Transfers",
    data,
    setToken(token)
  );
}

export const Transfers = {
  requestData,
  getSingle,
  getList,
  putSingle,
};
