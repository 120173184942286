export const shiftlistdata = [
	{
		"id": 1,
		"shiftname": "Matutino 1",
		"time": "09:00 AM",
		"time2": "6:00 PM",
		"weekoff": "Sunday, Monday",
		"createdon": "04 Aug 2023",
		"status": "Active",
		"Lunes": true,
		"Martes": false,
		"Miércoles": true,
		"Jueves": false,
		"Viernes": true,
		"Sábado": false,
		"Domingo": true
	},
	{
		"id": 2,
		"shiftname": "Vespertino 1",
		"time": "06:00 AM",
		"time2": "3:00 PM",
		"weekoff": "Saturday, Sunday",
		"createdon": "21 July 2023",
		"status": "Inactive",
		"Lunes": true,
		"Martes": false,
		"Miércoles": true,
		"Jueves": false,
		"Viernes": true,
		"Sábado": false,
		"Domingo": true
	},
	{
		"id": 3,
		"shiftname": "Matutino 2",
		"time": "03:00 AM",
		"time2": "9:00 PM",
		"weekoff": "Tuesday, Saturday",
		"createdon": "31 Jan 2022",
		"status": "Active",
		"Lunes": true,
		"Martes": false,
		"Miércoles": true,
		"Jueves": false,
		"Viernes": true,
		"Sábado": false,
		"Domingo": true
	},
	{
		"id": 4,
		"shiftname": "Nocturno",
		"time": "09:00 AM ",
		"time2": "6:00 PM",
		"weekoff": "Monday",
		"createdon": "15 May 2023",
		"status": "Active",
		"Lunes": true,
		"Martes": false,
		"Miércoles": true,
		"Jueves": false,
		"Viernes": true,
		"Sábado": false,
		"Domingo": true
	},
	{
		"id": 5,
		"shiftname": "Vespertino 2",
		"time": "06:00 AM",
		"time2": "3:00 PM",
		"weekoff": "Friday",
		"createdon": "04 Aug 2023",
		"status": "Active",
		"Lunes": true,
		"Martes": false,
		"Miércoles": true,
		"Jueves": false,
		"Viernes": true,
		"Sábado": false,
		"Domingo": true
	}
]