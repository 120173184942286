import React, { useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setShowRemoteResponseToast } from "../../../core/redux/action";
import { showInternalErrorAlert, showResetPasswordAlert } from "../../components/customAlert";
import { Password } from "../../../services/internal/password";
import { useForm } from "react-hook-form";
import { strongPassRegex } from "../../../core/utils";


const Resetpassword = () => {
  const route = all_routes;
  const { register, handleSubmit, getValues, reset, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(state => state.loading);
  const [inputType, setInputType] = useState("password");

  const [searchParams] = useSearchParams();
  const token = searchParams.get('t');

  const onShowPassword = () => {
    if (inputType === "text") {
      setInputType("password");
      return;
    }
    setInputType("text");
  }

  const handleException = (err) => {
    console.log(err);
    if (err.response) {
      dispatch(fetchDataFailure(err.response.data));
      if (err.response.status >= 400 && err.response.status < 500) {
        dispatch(fetchDataErrors(err.response.data.errors));
        dispatch(setShowRemoteResponseToast(true));
        return;
      } else {
        showInternalErrorAlert(false, err.response.statusText);
        return;
      }
    }
    dispatch(fetchDataFailure(err));
    showInternalErrorAlert(true, err.code);
  }

  const onReset = async (formData) => {
    try {
      let data = Password.requestData;
      data = {};
      data.password = formData.pass;
      data.resetToken = token;
      
      dispatch(fetchDataRequest());
      const response = await Password.putReset(data);
      console.log(response)
      dispatch(fetchDataSuccess(response.data.success));
      showResetPasswordAlert(navigate);
    }
    catch (err) {
      handleException(err);
      clearForm();
    }
  }

  const clearForm = () => {
    reset(formValues => ({
      ...formValues,
      pass: null,
      newPass: null,
    }));
  }

  return (
    <div className="main-wrapper">
      <div className="account-content">
        <div className="login-wrapper reset-pass-wrap bg-img">
          <div className="login-content">
            <form className="container" onSubmit={handleSubmit((data) => onReset(data))}>
              <div className="login-logo logo-normal">
                <ImageWithBasePath src="assets/img/logo.png" alt="logo" />
              </div>
              <Link to={route.home} className="login-logo logo-white">
                <ImageWithBasePath src="assets/img/logo-white.png" alt="logo" />
              </Link>
              <div className="login-userheading">
                <h3>Restablecer contraseña</h3>
                <h4 className="mb-2">
                  Para actualizar tu contraseña necesitamos que nos proporciones una nueva
                  y que cumpla con las siguientes reglas:
                </h4>
                <div className="col-lg-12 col-sm-12 mb-3">
                  <div className="alert alert-info" role="alert">
                    <li>Al menos una letra minúscula (a-z).</li>
                    <li>Al menos una letra mayúscula (A-Z).</li>
                    <li>Al menos un dígito (0-9).</li>
                    <li>Al menos un carácter especial (como @ $ % # . ! *).</li>
                    <li>Longitud mínima de 8 caracteres.</li>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mb-3">
                  <label className="form-label">Nueva contraseña<span className="text-danger"> *</span></label>
                  <div className="pass-group">
                    <input
                      type={inputType}
                      className={!errors.pass ? "form-control" : "form-control is-invalid"}
                      {...register("pass", {
                        required: "Requerido.",
                        pattern: {
                          value: strongPassRegex,
                          message: "Tu contraseña no cumple las reglas"
                        }
                      })}
                      maxLength={300}
                      disabled={loading}
                    />
                    {
                      !errors.pass ?
                        <span className={inputType === "text" ? "fas toggle-password fa-eye-slash" : "fas toggle-password fa-eye"}
                          onClick={onShowPassword} />
                        :
                        <div className="invalid-feedback">
                          {errors.pass.message}
                        </div>
                    }
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <label className="form-label"> Confirmar contraseña<span className="text-danger"> *</span></label>
                  <div className="pass-group">
                    <input
                      type={inputType}
                      className={!errors.newPass ? "form-control" : "form-control is-invalid"}
                      {...register("newPass", {
                        required: "Requerido.",
                        pattern: {
                          value: strongPassRegex,
                          message: "Tu contraseña no cumple las reglas"
                        },
                        validate: {
                          confirmPass: (value) => {
                            const { pass } = getValues();
                            return pass === value || "Contraseñas no coinciden";
                          }
                        }
                      })}
                      maxLength={300}
                      disabled={loading}
                    />
                    {
                      !errors.newPass ?
                        <span
                          className={inputType === "text" ? "fas toggle-password fa-eye-slash" : "fas toggle-password fa-eye"}
                          onClick={onShowPassword}
                        />
                        : <div className="invalid-feedback">
                          {errors.newPass.message}
                        </div>
                    }
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <label className="form-label">Código de recuperación</label>
                  <div className="pass-group">
                    <input type="password"
                      className="form-control"
                      defaultValue={token}
                      disabled />
                  </div>
                </div>
                <div className="col-12 mb-3">
                  {
                    loading ?
                      <button disabled={loading} className="btn btn-primary w-100">
                        <Spinner animation="border" role="status" size="sm" />
                      </button>
                      :
                      <input type="submit" value="Enviar" className="btn btn-primary w-100" />
                  }
                </div>
              </div>
              <div className="signinform text-center">
                <h4>
                  o puedes ir a
                  <Link to={route.signin} className="link-primary hover-a">
                    {" "}
                    Inicio de sesión{" "}
                  </Link>
                </h4>
              </div>
              <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
                <p>Copyright © 2024 Casa Meper. Todos los derechos reservados.</p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resetpassword;
