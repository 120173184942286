import React, { useEffect, useState } from "react";
import { Filter, HelpCircle, Package } from "react-feather";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import Select from "react-select";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { all_routes } from "../../Router/all_routes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ToolsHead from "../components/toolsHead";
import { fechaHoraFormat, formatearFecha, noOptionsText } from "../../core/utils";
import EditStock from "../../core/modals/inventory/editstock";
import { Table } from "antd";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setCategoryOptions, setExpiredTypeOptions, setShowRemoteResponseToast, setStockOperationList, setStoreOptions } from "../../core/redux/action";
import { Catalogs } from "../../services/internal/catalogs";
import { showInternalErrorAlert } from "../components/customAlert";
import FilterControl from "../components/filterControl";
import { StockManagement } from "../../services/internal/stockManagement";
import NoteModal from "../../core/modals/applications/noteModal";

const StockAjustment = () => {
  const dataSource = useSelector((state) => state.stockOperationList);
  const loading = useSelector((state) => state.loading);
  const token = useSelector(state => state.token);
  const [showEditStockModal, setShowEditStockModal] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const categoryOptions = useSelector(state => state.categoryOptions);
  const storeOptions = useSelector(state => state.storeOptions);
  const [filterName, setFilterName] = useState(null);
  const [filterStore, setFilterStore] = useState(null);
  const [filterCategory, setFilterCategory] = useState(null);
  const [filterExpiredType, setFilterExpiredType] = useState(null);
  const [note, setNote] = useState({});
  const expiredTypeOptions = useSelector(state => state.expiredTypeOptions);
  const dispatch = useDispatch();
  const route = all_routes;

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const onHideEditStockModal = async () => {
    setShowEditStockModal(false);
  }

  const onChangeName = (e) => {
    setFilterName(e.target.value.trim())
  }

  const renderSearchTooltip = (props) => (
    <Tooltip id="search-tooltip" {...props}>
      Ingresa el nombre del producto
    </Tooltip>
  );

  const onHideNotesModal = () => {
    setShowNotesModal(false);
  }

  const onShowNotesModal = (note) => {
    if (!note) {
      return;
    }
    setNote(JSON.parse(note));
    setShowNotesModal(true);
  }

  const handleException = (err) => {
    console.log(err);
    if (err.response) {
      dispatch(fetchDataFailure(err.response.data));
      if (err.response.status >= 400 && err.response.status < 500) {
        dispatch(fetchDataErrors(err.response.data.errors));
        dispatch(setShowRemoteResponseToast(true));
        return;
      } else {
        showInternalErrorAlert(false, err.response.statusText);
        return;
      }
    }
    dispatch(fetchDataFailure(err));
    showInternalErrorAlert(true, err.code);
  }

  const onLoadStockOperations = async () => {
    let filters = {};
    if (filterName) {
      filters.articleName = filterName;
    }
    if (filterStore) {
      filters.branchId = filterStore.value;
    }
    if (filterCategory) {
      filters.categoryId = filterCategory.value;
    }
    if (filterExpiredType) {
      filters.expiredTypeId = filterExpiredType.value;
    }

    try {
      dispatch(fetchDataRequest());

      if (storeOptions.length === 0) {
        const responseA = await Catalogs.getOptions(token, Catalogs.Name.Branches);
        dispatch(setStoreOptions(responseA.data.data));
      }

      if (categoryOptions.length === 0) {
        const responseB = await Catalogs.getOptions(token, Catalogs.Name.Categories);
        dispatch(setCategoryOptions(responseB.data.data));
      }
      if (expiredTypeOptions.length === 0) {
        const responseC = await Catalogs.getOptions(token, Catalogs.Name.ExpiredTypes);
        dispatch(setExpiredTypeOptions(responseC.data.data));
      }

      const response = await StockManagement.getListOperations(token, filters);
      //console.log(response)
      dispatch(setStockOperationList(response.data.data));
      dispatch(fetchDataSuccess(response.data.success));
    }
    catch (err) {
      handleException(err);
    }
  }

  useEffect(() => {
    onLoadStockOperations();
  }, []);

  const columns = [
    {
      title: "Fecha y hora",
      dataIndex: "registered",
      align: 'center',
      render: (text, record) => (
        <span>{record.registered ? formatearFecha(record.registered ?? new Date().toISOString(), fechaHoraFormat) : "Aún no hay datos"}</span>
      ),
      sorter: (a, b) => a.registered?.localeCompare(b.registered),
      defaultSortOrder: "descend"
    },
    {
      title: "Sucursal",
      dataIndex: "branch.name",
      render: (text, record) => (
        <span className="text-primary-emphasis fw-semibold">{record.branch?.name}</span>
      ),
      sorter: (a, b) => a.branch?.name?.localeCompare(b.branch?.name)
    },
    {
      title: "Categoría",
      dataIndex: "article.category.name",
      render: (text, record) => (
        <span className="text-secondary fw-semibold">{record.article?.category?.name}</span>
      ),
      sorter: (a, b) => a.article?.category?.name?.localeCompare(b.article?.category?.name)
    },
    {
      title: "Código de producto",
      align: 'center',
      dataIndex: "article.code",
      render: (text, record) => (
        <span className="text-orange">{record.article?.code}</span>
      ),
      sorter: (a, b) => a.article?.code?.localeCompare(b.article?.code)
    },
    {
      title: "Producto",
      dataIndex: "article.id",
      render: (text, record) => (
        <span className="productimgname">
          <Link
            to={`${route.productdetails}?code=${record.article?.id}`}
            className="text-primary fw-semibold"
          >
            {record.article?.name}
          </Link>
        </span>
      ),
      sorter: (a, b) => a.article?.id - b.article?.id
    },
    {
      title: "Unidad de medida",
      align: 'center',
      dataIndex: "article.unmed.abbreviation",
      render: (text, record) => (
        <span>{record.article?.unmed?.abbreviation}</span>
      ),
      sorter: (a, b) => a.article?.unmed?.abbreviation?.localeCompare(b.article?.unmed?.abbreviation)
    },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      align: 'center',
      render: (text, record) => (
        <span className={record.quantity > 0 ? "badges bg-success" : "badges bg-danger"}>{record.quantity > 0 ? "+" : ""}{record.quantity}</span>
      ),
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: "Tipo de salida",
      dataIndex: "expiredType.name",
      //align: 'center',
      render: (text, record) => (
        <span className="badge badges-info">
          {record.expiredType?.name}
        </span>
      ),
      sorter: (a, b) => a.expiredType?.name?.localeCompare(b.expiredType?.name)
    },
    {
      title: "Notas",
      render: (text, record) => (
        record.comment
          ? <a className="view-note" onClick={() => onShowNotesModal(record.comment)}>Ver Notas</a>
          : <span>Ninguna</span>
      ),
    }
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Operaciones de inventario</h4>
              <h6>Puedes consultar las diferentes Operaciones de inventario registradas en el sistema.</h6>
            </div>
          </div>
          <ToolsHead showExportData={true} showExcel={true} onReload={onLoadStockOperations} />
          <div className="page-btn">
            <a
              className="btn btn-added color"
              onClick={() => setShowEditStockModal(true)}
            >
              <Package className="me-2 iconsize" />
              Ajustar inventario
            </a>
          </div>
        </div>
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Búsqueda rápida"
                    className="form-control form-control-sm formsearch"
                    onChange={e => onChangeName(e)}
                    onBlur={onLoadStockOperations}
                    disabled={loading}
                  />
                  <a className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </a>
                </div>
                <OverlayTrigger placement="top" overlay={renderSearchTooltip}>
                  <a className="link-primary">
                    <HelpCircle />
                  </a>
                </OverlayTrigger>
              </div>
              <div className="search-path">
                <a
                  className={`btn btn-filter ${isFilterVisible ? "setclose" : ""
                    }`}
                  id="filter_search"
                >
                  <Filter
                    className="filter-icon"
                    onClick={toggleFilterVisibility}
                  />
                  <span onClick={toggleFilterVisibility}>
                    <ImageWithBasePath
                      src="assets/img/icons/closes.svg"
                      alt="img"
                    />
                  </span>
                </a>
              </div>
            </div>
            {/* /Filter */}
            <div
              className={`card${isFilterVisible ? " visible" : ""}`}
              id="filter_inputs"
              style={{ display: isFilterVisible ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-3 col-sm-3 col-12 mb-3">
                    <Select
                      options={storeOptions}
                      placeholder="Elige una sucursal"
                      onChange={(value) => setFilterStore(value)}
                      noOptionsMessage={() => noOptionsText}
                      components={{ Control: FilterControl }}
                      isDisabled={loading}
                      isClearable
                    />
                  </div>
                  <div className="col-lg-3 col-sm-3 col-12 mb-3">
                    <Select
                      options={categoryOptions}
                      onChange={(value) => setFilterCategory(value)}
                      noOptionsMessage={() => noOptionsText}
                      isClearable
                      placeholder="Elige una categoría"
                      components={{ Control: FilterControl }}
                      isDisabled={loading}
                    />
                  </div>
                  <div className="col-lg-3 col-sm-3 col-12 mb-3">
                    <Select
                      options={expiredTypeOptions}
                      onChange={(value) => setFilterExpiredType(value)}
                      noOptionsMessage={() => noOptionsText}
                      isClearable
                      placeholder="Elige un tipo de operación"
                      components={{ Control: FilterControl }}
                      isDisabled={loading}
                    />
                  </div>
                  <div className="col-lg-3 col-sm-3 col-12 mb-3">
                    <button
                      className="btn btn-outline-primary w-100"
                      onClick={() => onLoadStockOperations()}
                      disabled={loading}
                    >
                      <span data-feather="check" className="feather-check" />Aplicar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* /Filter */}
            <div className="table-responsive">
              <Table
                className="table"
                rowKey={(record) => record?.id}
                columns={columns}
                loading={loading}
                dataSource={dataSource}
                size="middle"
                pagination={
                  {
                    position: ["topRight"],
                    total: dataSource.length,
                    showTotal: (total) => `Total: ${total}`,
                    showSizeChanger: true
                  }
                }
              />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
      {
        showNotesModal
          ? <NoteModal id="notes" show={showNotesModal} onHide={onHideNotesModal} note={note} />
          : null
      }
      {
        showEditStockModal
          ? <EditStock id="edit-stock" writeOff={true} show={showEditStockModal} onHide={onHideEditStockModal} />
          : null
      }
    </div>
  );
};

export default StockAjustment;
