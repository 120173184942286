export const getRequest = (
  axiosClient,
  endpoint,
  config
) => {
  return axiosClient
    .get(
      endpoint,
      config
    );
}

export const postRequest = (
  axiosClient,
  endpoint,
  data,
  config
) => {
  return axiosClient
    .post(
      endpoint,
      data,
      config
    );
}

export const putRequest = (
  axiosClient,
  endpoint,
  data,
  config
) => {
  return axiosClient
    .put(
      endpoint,
      data,
      config
    );
}

export const deleteRequest = (
  axiosClient,
  endpoint,
  config
) => {
  return axiosClient
    .delete(
      endpoint,
      config
    );
}
