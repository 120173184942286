export const estadosMexico = [
    { "value": "AG", "label": "Aguascalientes" },
    { "value": "BC", "label": "Baja California" },
    { "value": "BS", "label": "Baja California Sur" },
    { "value": "CH", "label": "Chihuahua" },
    { "value": "CL", "label": "Colima" },
    { "value": "CM", "label": "Campeche" },
    { "value": "CO", "label": "Coahuila" },
    { "value": "CS", "label": "Chiapas" },
    { "value": "DF", "label": "Ciudad de México" },
    { "value": "DG", "label": "Durango" },
    { "value": "GR", "label": "Guerrero" },
    { "value": "GT", "label": "Guanajuato" },
    { "value": "HG", "label": "Hidalgo" },
    { "value": "JA", "label": "Jalisco" },
    { "value": "ME", "label": "Estado de México" },
    { "value": "MI", "label": "Michoacán" },
    { "value": "MO", "label": "Morelos" },
    { "value": "NA", "label": "Nayarit" },
    { "value": "NL", "label": "Nuevo León" },
    { "value": "OA", "label": "Oaxaca" },
    { "value": "PB", "label": "Puebla" },
    { "value": "QE", "label": "Querétaro" },
    { "value": "QR", "label": "Quintana Roo" },
    { "value": "SI", "label": "Sinaloa" },
    { "value": "SL", "label": "San Luis Potosí" },
    { "value": "SO", "label": "Sonora" },
    { "value": "TB", "label": "Tabasco" },
    { "value": "TL", "label": "Tlaxcala" },
    { "value": "TM", "label": "Tamaulipas" },
    { "value": "VE", "label": "Veracruz" },
    { "value": "YU", "label": "Yucatán" },
    { "value": "ZA", "label": "Zacatecas" }
];