import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import NotesTable from "../../../feature-module/components/notes";

const NotesModal = ({ id, show = false, onHide }) => {

  const loading = useSelector(state => state.loading);

  const onModalHide = (e, refresh) => {
    e?.preventDefault();
    onHide(refresh);
  }

  return (
    <Modal show={show} centered onHide={onModalHide} id={id} className="modal-lg">
      <Modal.Header className="custom-modal-header" closeButton={!loading}>
        <Modal.Title>
          <h4>Notas</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <NotesTable />
      </Modal.Body>
      <Modal.Footer className='justify-content-center'>
        <button
          className="btn btn-cancel me-2"
          onClick={(e) => onModalHide(e, false)}
          disabled={loading}
        >
          Regresar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

NotesModal.propTypes = {
  id: PropTypes.string.isRequired,
  show: PropTypes.bool,
  onHide: PropTypes.func
};


export default NotesModal;
