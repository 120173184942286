import { setToken } from '../authentication';
import { axiosClientApi } from '../../axiosClientConfiguration';
import { getRequest, postRequest, putRequest } from '../../axiosFunctions';

const requestData = {
  id: null,
  code: null,
  status: null,
  transferCode: null,
  registered: null,
  providerId: null,
  branchIdOrigin: null,
  branchIdDestination: null,
  subtotal: null,
  total: null,
  idStatus: null,
  comment: null,
  products: [
    {
      productId: null,
      quantity: null,
      subtotal: null,
    }
  ]
}

const getSingle = (token, id) => {
  return getRequest(
    axiosClientApi,
    `/PurchaseOrders/Get/${id}`,
    setToken(token)
  );
}

const getList = (token, filters) => {
  let config = setToken(token);
  config.params = {
    code: filters.code,
    branchIdDestination: filters.branchIdDestination,
    providerId: filters.providerId,
    userId: filters.userId,
    statusId: filters.statusId,
    branchToBranch: filters.branchToBranch
  };
  return getRequest(
    axiosClientApi,
    "/PurchaseOrders/List",
    config
  );
}

const getArticlesList = (token, filters) => {
  let config = setToken(token);
  config.params = {
    name: filters.name,
    code: filters.code,
    statusId: filters.statusId,
  };
  return getRequest(
    axiosClientApi,
    "/PurchaseOrders/ArticlesList",
    config
  );
}

const postSingle = (token, data) => {
  return postRequest(
    axiosClientApi,
    "/PurchaseOrders",
    data,
    setToken(token)
  );
}

const putSingle = (token, data) => {
  return putRequest(
    axiosClientApi,
    "/PurchaseOrders",
    data,
    setToken(token)
  );
}

const putChangeStatus = (token, data) => {
  return putRequest(
    axiosClientApi,
    "/PurchaseOrders/ChangeStatus",
    data,
    setToken(token)
  );
}

export const PurchaseOrders = {
  requestData,
  getSingle,
  getList,
  getArticlesList,
  postSingle,
  putSingle,
  putChangeStatus,
};
