import React, { useEffect, useState } from 'react'
import ImageWithBasePath from '../../core/img/imagewithbasebath'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Table } from 'antd';
import { convertToOptions, fechaHoraFormat, formatearFecha, formatearMoneda, noOptionsText } from '../../core/utils';
import { Eye, Filter, HelpCircle, X } from 'react-feather';
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setCashBreakList, setCustomerOptions, setShowRemoteResponseToast, setStoreOptions, setUserList } from '../../core/redux/action';
import { Users } from '../../services/internal/users';
import { showInternalErrorAlert } from '../components/customAlert';
import { all_routes } from '../../Router/all_routes';
import ToolsHead from '../components/toolsHead';
import FilterControl from '../components/filterControl';
import { Catalogs } from '../../services/internal/catalogs';
import { Sales } from '../../services/internal/sales';

const CashBreak = () => {
    const dataSource = useSelector((state) => state.cashBreakList);
    const storeOptions = useSelector((state) => state.storeOptions);
    const userList = useSelector((state) => state.userList);
    const customerOptions = useSelector((state) => state.customerOptions);
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loading);
    const token = useSelector((state) => state.token);
    const [filterCode, setFilterCode] = useState("");
    const [filterBranch, setBranch] = useState("");
    const [filterUser, setFilterUser] = useState("");
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [userOptions, setUserOptions] = useState([]);
    const route = all_routes;

    const renderSearchTooltip = (props) => (
        <Tooltip id="search-tooltip" {...props}>
            Ingresa el código de la venta
        </Tooltip>
    );

    const onChangeName = (e) => {
        setFilterCode(e.target.value.trim())
    }

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure(err.response.data));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadCashBreak = async () => {

        let filters = {};
        filters.enabled = true;

        if (filterCode) {
            filters.code = filterCode;
        }
        if (filterBranch) {
            filters.branchId = filterBranch.value;
        }
        if (filterUser) {
            filters.saleUserId = filterUser.value;
        }

        try {
            dispatch(fetchDataRequest());

            if (storeOptions.length === 0) {
                const responseB = await Catalogs.getOptions(token, Catalogs.Name.Branches);
                dispatch(setStoreOptions(responseB.data.data));
            }
            if (customerOptions.length === 0) {
                const responseP = await Catalogs.getOptions(token, Catalogs.Name.Clients);
                dispatch(setCustomerOptions(responseP.data.data));
            }
            if (userList.length === 0) {
                const responseU = await Users.getList(token, filters);
                dispatch(setUserList(responseU.data.data));
            }
            const response = await Sales.getBreakList(token, filters);
            dispatch(setCashBreakList(response.data.data));
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const toggleFilterVisibility = () => {
        setUserOptions(convertToOptions(userList));
        setIsFilterVisible((prevVisibility) => !prevVisibility);
        onLoadCashBreak();
    };

    const calculateImagePath = (userDetails) => {
        return userDetails?.imagePath ? userDetails?.imagePath : "assets/img/profiles/profile.png";
    }

    const calculateName = (userDetails) => {
        let fullName = `${userDetails.name ? userDetails.name : ''} ${userDetails.surname ? userDetails.surname : ''} ${userDetails.lastname ? userDetails.lastname : ''}`;
        return fullName.trim() ? fullName : userDetails.alias;
    }

    const calculateDiff = (cashbreak, needStatus) => {
        const cashTotal = isNaN(Number(cashbreak.cashTotal)) ? 0 : Number(cashbreak.cashTotal);
        const cashOutcomesTotal = isNaN(Number(cashbreak.cashOutcomesTotal)) ? 0 : Number(cashbreak.cashOutcomesTotal);

        const result = cashTotal - cashOutcomesTotal;

        if (result > 0) {
            return needStatus ?
                <span className="badge badges-success">Sobrante</span>
                :
                <span className="text-success fw-semibold">{formatearMoneda(result)}</span>;
        }
        if (result < 0) {
            return needStatus ?
                <span className="badge badges-danger">Faltante</span>
                :
                <span className="text-danger fw-semibold">{formatearMoneda(result)}</span>;
        }

        return needStatus ?
            <span className="badge badges-purple">Cuadre</span>
            :
            <span className="text-purple fw-semibold">{formatearMoneda(result)}</span>;
    }

    useEffect(() => {
        if (dataSource.length === 0) {
            onLoadCashBreak();
        }
    }, []);

    const columns = [
        {
            title: "Fecha y Hora",
            dataIndex: "registered",
            align: "center",
            render: (text, record) => (
                <span>{formatearFecha(record.registered ? record.registered : new Date().toISOString(), fechaHoraFormat)}</span>
            ),
            sorter: (a, b) => a.registered?.localeCompare(b.registered),
            defaultSortOrder: "descend"
        },
        {
            title: "Código del corte",
            dataIndex: "code",
            align: "center",
            render: (text) => (
                <span className="text-orange">{text}</span>
            ),
            sorter: (a, b) => a.code?.localeCompare(b.code)
        },
        {
            title: "Sucursal",
            dataIndex: "branch",
            render: (text, record) => (
                <span className="text-secondary fw-semibold">{record.branch}</span>
            ),
            sorter: (a, b) => a.branch.localeCompare(b.branch)
        },
        {
            title: "Vendedor",
            dataIndex: "userId",
            align: "center",
            render: (text, record) => (
                <span className="productimgname">
                    <Link key={record.id} to={`${route.profile}?code=${record.user?.id}`} className="product-img stock-img">
                        <ImageWithBasePath alt="avatar" src={calculateImagePath(record.user)} />
                    </Link>
                    <Link to={`${route.profile}?code=${record.user?.id}`} className="text-primary fw-semibold">{calculateName(record.user)}</Link>
                </span>
            ),
            sorter: (a, b) => a.user?.id - b.user?.id
        },
        {
            title: "Núm. de ventas",
            dataIndex: "salesNumber",
            align: "center",
            render: (text,) => (
                <span className="badge badges-dark">{text}</span>
            ),
            sorter: (a, b) => a.salesNumber - b.salesNumber
        },
        {
            title: "Total en ventas",
            dataIndex: "salesTotal",
            align: "right",
            render: (text, record) => (
                <span className="text-info fw-bold">{formatearMoneda(record.salesTotal)}</span>
            ),
            sorter: (a, b) => a.salesTotal - b.salesTotal
        },
        {
            title: "Entradas de efectivo",
            dataIndex: "cashTotal",
            align: "right",
            render: (text, record) => (
                <span className="text-success fw-semibold">{formatearMoneda(record.total)}</span>
            ),
            sorter: (a, b) => a.cashTotal - b.cashTotal
        },
        {
            title: "Salidas de efectivo",
            dataIndex: "cashOutcomesNumber",
            align: "center",
            render: (text) => (
                <span className="badge badges-warning">{text ? text : 0}</span>
            ),
            sorter: (a, b) => a.cashOutcomesNumber - b.cashOutcomesNumber
        },
        {
            title: "Total en salidas",
            dataIndex: "cashOutcomesTotal",
            align: "right",
            render: (text, record) => (
                <span className="text-warning fw-bold">{formatearMoneda(record.cashOutcomesTotal)}</span>
            ),
            sorter: (a, b) => a.cashOutcomesTotal - b.cashOutcomesTotal
        },
        {
            title: "Neto calculado",
            align: "right",
            render: (text, record) => (calculateDiff(record, false)),
        },
        {
            title: "Estatus",
            align: "center",
            render: (text, record) => (calculateDiff(record, true)),
        },
        {
            title: "Acción",
            align: "center",
            dataIndex: "action",
            render: (text, record) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        <Link className="me-2 p-2" to={`${route.cashBreakDetails}?code=${record?.id}`}>
                            <Eye />
                        </Link>
                    </div>
                </div>
            )
        },
    ];

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Cortes de caja</h4>
                            <h6>Puedes ver la información de los cortes de caja registrados en el sistema.</h6>
                        </div>
                    </div>
                    <ToolsHead showExportData={true} showExcel={true} onReload={onLoadCashBreak} />
                </div>
                {/* /product list */}
                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input
                                        type="text"
                                        placeholder="Búsqueda rápida"
                                        className="form-control form-control-sm formsearch"
                                        onChange={e => onChangeName(e)}
                                        onBlur={onLoadCashBreak}
                                        disabled={loading}
                                    />
                                    <a className="btn btn-searchset">
                                        <i data-feather="search" className="feather-search" />
                                    </a>
                                </div>
                                <OverlayTrigger placement="top" overlay={renderSearchTooltip}>
                                    <a className="link-primary">
                                        <HelpCircle />
                                    </a>
                                </OverlayTrigger>
                            </div>
                            <div className="search-path">
                                <div
                                    className={`btn btn-filter ${isFilterVisible ? "setclose" : ""}`}
                                    id="filter_search"
                                    onClick={toggleFilterVisibility}
                                >
                                    <Filter className="filter-icon"
                                    />
                                    <span>
                                        <X className="filter-icon" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/* /Filter */}
                        <div
                            className={`card${isFilterVisible ? " visible" : ""}`}
                            id="filter_inputs"
                            style={{ display: isFilterVisible ? "block" : "none" }}
                        >
                            <div className="card-body pb-0">
                                <div className="row">
                                    <div className="col-lg-4 col-sm-12 col-12 mb-3">
                                        <Select
                                            options={storeOptions}
                                            placeholder="Elige una sucursal"
                                            onChange={(value) => setBranch(value)}
                                            noOptionsMessage={() => noOptionsText}
                                            components={{ Control: FilterControl }}
                                            isDisabled={loading}
                                            isClearable
                                        //isMulti
                                        />
                                    </div>
                                    <div className="col-lg-4 col-sm-12 col-12 mb-3">
                                        <Select
                                            options={userOptions}
                                            onChange={(value) => setFilterUser(value)}
                                            noOptionsMessage={() => noOptionsText}
                                            isClearable
                                            placeholder="Elige un vendedor"
                                            components={{ Control: FilterControl }}
                                            isDisabled={loading}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-sm-12 col-12 mb-3">
                                        <button
                                            className="btn btn-outline-primary w-100"
                                            onClick={() => onLoadCashBreak()}
                                            disabled={loading}
                                        >
                                            <span data-feather="check" className="feather-check" />Aplicar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /Filter */}
                        <div className="table-responsive">
                            <Table
                                className="table"
                                rowKey={(record) => record?.id}
                                columns={columns}
                                loading={loading}
                                dataSource={dataSource}
                                size="small"
                                pagination={
                                    {
                                        position: ["topRight"],
                                        total: dataSource.length,
                                        showTotal: (total) => `Total: ${total}`,
                                        showSizeChanger: true
                                    }
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CashBreak
