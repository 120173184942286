import React from "react";
import FormProduct from "../components/productForm";

const AddProduct = () => {
  return (
    <FormProduct isNew />
  );
};

export default AddProduct;
