import React from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setShowRemoteResponseToast } from "../../../core/redux/action";
import { Password } from "../../../services/internal/password";
import { Spinner } from "react-bootstrap";
import { showForgotPasswordAlert, showInternalErrorAlert } from "../../components/customAlert";
import { useForm } from "react-hook-form";
import { emailRegex } from "../../../core/utils";

const Forgotpassword = () => {
  const route = all_routes;
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const loading = useSelector(state => state.loading);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleException = (err) => {
    console.log(err);
    if (err.response) {
      dispatch(fetchDataFailure(err.response.data));
      if (err.response.status >= 400 && err.response.status < 500) {
        dispatch(fetchDataErrors(err.response.data.errors));
        dispatch(setShowRemoteResponseToast(true));
        return;
      } else {
        showInternalErrorAlert(false, err.response.statusText);
        return;
      }
    }
    dispatch(fetchDataFailure(err));
    showInternalErrorAlert(true, err.code);
  }

  const onRecovery = async (formData) => {
    try {
      let data = Password.requestData;
      data = {};
      data.email = formData.email;

      dispatch(fetchDataRequest());
      const response = await Password.postRecovery(data);
      console.log(response)
      dispatch(fetchDataSuccess(response.data.success));
      showForgotPasswordAlert(navigate);
    }
    catch (err) {
      handleException(err);
      clearForm();
    }
  }

  const clearForm = () => {
    reset(formValues => ({
      ...formValues,
      email: null,
    }));
  }


  return (
    <div className="main-wrapper">
      <div className="account-content">
        <div className="login-wrapper forgot-pass-wrap bg-img">
          <div className="login-content">
            <form className="container" onSubmit={handleSubmit((data) => onRecovery(data))}>
              <div className="login-logo logo-normal">
                <ImageWithBasePath src="assets/img/logo.png" alt="logo" />
              </div>
              <Link to={route.home} className="login-logo logo-white">
                <ImageWithBasePath src="assets/img/logo-white.png" alt="logo" />
              </Link>
              <div className="login-userheading">
                <h3>¿Olvidaste tu contraseña?</h3>
                <h4>
                  No te preocupes, por favor ingresa el correo electrónico vinculado  a tu cuenta <strong>Casa Meper</strong> y te enviaremos la infomación necesaria para utilizar una nueva.
                </h4>
              </div>
              <div className="row">
                <div className="col-12 mb-3">
                  <label className="form-label">Correo electrónico<span className="text-danger"> *</span></label>
                  <div className="pass-group">
                    <input
                      type="email"
                      className={!errors.email ? "form-control" : "form-control is-invalid"}
                      placeholder="Escribe tu correo electrónico"
                      {...register("email", {
                        required: "Requerido.",
                        pattern: {
                          value: emailRegex,
                          message: "No es un email válido"
                        },
                      })}
                      maxLength={300}
                      disabled={loading}
                    />
                    {
                      !errors.email ?
                        <span className="fas toggle-password fa-envelope" />
                        :
                        <div className="invalid-feedback">
                          {errors.email.message}
                        </div>
                    }
                  </div>
                </div>
                <div className="col-12 mb-3">
                  {
                    loading ?
                      <button disabled={loading} className="btn btn-primary w-100">
                        <Spinner animation="border" role="status" size="sm" />
                      </button>
                      :
                      <input type="submit" value="Enviar" className="btn btn-primary w-100" />
                  }
                </div>
              </div>
              <div className="signinform text-center">
                <h4>
                  o puedes regresar a
                  <Link to={route.signin} className="link-primary hover-a">
                    {" "}
                    Inicio de sesión{" "}
                  </Link>
                </h4>
              </div>
              <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
                <p>Copyright © 2024 Casa Meper. Todos los derechos reservados.</p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgotpassword;
