import React from "react";
import { Route, Navigate } from "react-router-dom";
import ProductList from "../feature-module/inventory/productlist";
import Dashboard from "../feature-module/dashboard/Dashboard";
import AddProduct from "../feature-module/inventory/addproduct";
import SalesDashbaord from "../feature-module/dashboard/salesdashbaord";

const routes = all_routes;

import DepartmentGrid from "../feature-module/hrm/departmentgrid";
import Designation from "../feature-module/hrm/designation";
import Shift from "../feature-module/hrm/shift";
import AttendanceEmployee from "../feature-module/hrm/attendance-employee";
import ExpiredProduct from "../feature-module/inventory/expiredproduct";
import CategoryList from "../feature-module/inventory/categorylist";
import EditProduct from "../feature-module/inventory/editproduct";
import PurchasesList from "../feature-module/purchases/purchaseslist";
import Appearance from "../feature-module/settings/websitesettings/appearance";
import SocialAuthentication from "../feature-module/settings/websitesettings/socialauthentication";
import LanguageSettings from "../feature-module/settings/websitesettings/languagesettings";
import InvoiceSettings from "../feature-module/settings/appsetting/invoicesettings";
import PrinterSettings from "../feature-module/settings/appsetting/printersettings";
import PosSettings from "../feature-module/settings/websitesettings/possettings";
import EmailSettings from "../feature-module/settings/systemsettings/emailsettings";
import SmsGateway from "../feature-module/settings/systemsettings/smsgateway";
import OtpSettings from "../feature-module/settings/systemsettings/otpsettings";
import GdprSettings from "../feature-module/settings/systemsettings/gdprsettings";
import PaymentGateway from "../feature-module/settings/financialsettings/paymentgateway";
import Customers from "../feature-module/people/customers";
import Suppliers from "../feature-module/people/suppliers";
import StoreList from "../feature-module/people/storelist";
import Managestock from "../feature-module/stock/managestock";
import StockAdjustment from "../feature-module/stock/stockAdjustment";
import StockTransfer from "../feature-module/stock/stockTransfer";
import SalesReport from "../feature-module/Reports/salesreport";
import PurchaseReport from "../feature-module/Reports/purchasereport";
import InventoryReport from "../feature-module/Reports/inventoryreport";
import Invoicereport from "../feature-module/Reports/invoicereport";
import SupplierReport from "../feature-module/Reports/supplierreport";
import CustomerReport from "../feature-module/Reports/customerreport";
import ExpenseReport from "../feature-module/Reports/expensereport";
import IncomeReport from "../feature-module/Reports/incomereport";
import TaxReport from "../feature-module/Reports/taxreport";
import ProfitLoss from "../feature-module/Reports/profitloss";
import GeneralSettings from "../feature-module/settings/generalsettings/generalsettings";
import SecuritySettings from "../feature-module/settings/generalsettings/securitysettings";
import Notification from "../feature-module/settings/generalsettings/notification";
import ConnectedApps from "../feature-module/settings/generalsettings/connectedapps";
import SystemSettings from "../feature-module/settings/websitesettings/systemsettings";
import CompanySettings from "../feature-module/settings/websitesettings/companysettings";
import LocalizationSettings from "../feature-module/settings/websitesettings/localizationsettings";
import Prefixes from "../feature-module/settings/websitesettings/prefixes";
import Preference from "../feature-module/settings/websitesettings/preference";
import BanIpaddress from "../feature-module/settings/othersettings/ban-ipaddress";
import StorageSettings from "../feature-module/settings/othersettings/storagesettings";
import Pos from "../feature-module/sales/pos";
import Holidays from "../feature-module/hrm/holidays";
import SalesList from "../feature-module/sales/saleslist";
import InvoiceReport from "../feature-module/sales/invoicereport";
import Profile from "../feature-module/pages/profile";
import Signin from "../feature-module/pages/login/signin";
import Forgotpassword from "../feature-module/pages/forgotpassword/forgotpassword";
import Resetpassword from "../feature-module/pages/resetpassword/resetpassword";
import Error404 from "../feature-module/pages/errorpages/error404";
import Error500 from "../feature-module/pages/errorpages/error500";
import Users from "../feature-module/usermanagement/users";
import RolesPermissions from "../feature-module/usermanagement/rolespermissions";
import Permissions from "../feature-module/usermanagement/permissions";
import EmployeesGrid from "../feature-module/hrm/employeesgrid";
import EditEmployee from "../feature-module/hrm/editemployee";
import AddEmployee from "../feature-module/hrm/addemployee";
import LeavesEmployee from "../feature-module/hrm/leavesemployee";
import LeaveTypes from "../feature-module/hrm/leavetypes";
import ProductDetail from "../feature-module/inventory/productdetail";
import { Units } from "../feature-module/inventory/units";
import TaxRates from "../feature-module/settings/financialsettings/taxrates";
import { all_routes } from "./all_routes";
import AddCustomer from "../feature-module/people/addcustomer";
import EditCustomer from "../feature-module/people/editcustomer";
import EditSupplier from "../feature-module/people/editsupplier";
import AddSupplier from "../feature-module/people/addsupplier";
import CashBreak from "../feature-module/sales/cashbreak";
import RecipeBook from "../feature-module/pages/recipes/recipebook";
import RecipeGrid from "../feature-module/pages/recipes/recipegrid";
import Recipe from "../feature-module/pages/recipes/recipe";
import SignOut from "../feature-module/pages/login/signout";
import { InventoryOutflow } from "../feature-module/inventory/expiredtypes";
import PurchaseProductList from "../feature-module/purchases/purchaseProductList";
import MenuGrid from "../feature-module/pages/menu/menugrid";
import Menu from "../feature-module/pages/menu/menudetail";
import { CashOutflowList } from "../feature-module/sales/cashoutflowlist";
import PurchaseDetail from "../feature-module/purchases/purchasedetail";
import TransferDetail from "../feature-module/purchases/transferdetail";
import PackageGrid from "../feature-module/pages/packages/packagegrid";
import PackageDetail from "../feature-module/pages/packages/packagedetail";
import SimplePos from "../feature-module/sales/simplePos";
import SaleDetails from "../feature-module/sales/saleDetails";
import LeaveEmployeeDetails from "../feature-module/hrm/leaveEmployeDetails";
import CashBreakDetails from "../feature-module/sales/cashBreakDetails";

export const publicRoutes = [
  {
    id: 1,
    path: routes.home,
    name: "home",
    element: <Dashboard />,
    route: Route,
  },
  {
    id: 2,
    path: routes.productlist,
    name: "products",
    element: <ProductList />,
    route: Route,
  },
  {
    id: 3,
    path: routes.addproduct,
    name: "products",
    element: <AddProduct />,
    route: Route,
  },
  {
    id: 4,
    path: routes.salesdashboard,
    name: "salesdashboard",
    element: <SalesDashbaord />,
    route: Route,
  },
  {
    id: 6,
    path: routes.units,
    name: "unit",
    element: <Units />,
    route: Route,
  },
  {
    id: 38,
    path: routes.departmentgrid,
    name: "departmentgrid",
    element: <DepartmentGrid />,
    route: Route,
  },
  {
    id: 40,
    path: routes.designation,
    name: "designation",
    element: <Designation />,
    route: Route,
  },
  {
    id: 41,
    path: routes.shift,
    name: "shift",
    element: <Shift />,
    route: Route,
  },
  {
    id: 42,
    path: routes.attendanceemployee,
    name: "attendanceemployee",
    element: <AttendanceEmployee />,
    route: Route,
  },
  {
    id: 58,
    path: routes.expiredproduct,
    name: "expiredproduct",
    element: <ExpiredProduct />,
    route: Route,
  },
  {
    id: 60,
    path: routes.categorylist,
    name: "categorylist",
    element: <CategoryList />,
    route: Route,
  },
  {
    id: 65,
    path: routes.editproduct,
    name: "editproduct",
    element: <EditProduct />,
    route: Route,
  },
  {
    id: 68,
    path: routes.purchaselist,
    name: "purchaselist",
    element: <PurchasesList />,
    route: Route,
  },
  {
    id: 71,
    path: routes.appearance,
    name: "appearance",
    element: <Appearance />,
    route: Route,
  },
  {
    id: 72,
    path: routes.socialauthendication,
    name: "socialauthendication",
    element: <SocialAuthentication />,
    route: Route,
  },
  {
    id: 73,
    path: routes.languagesettings,
    name: "languagesettings",
    element: <LanguageSettings />,
    route: Route,
  },
  {
    id: 74,
    path: routes.invoicesettings,
    name: "invoicesettings",
    element: <InvoiceSettings />,
    route: Route,
  },
  {
    id: 75,
    path: routes.printersettings,
    name: "printersettings",
    element: <PrinterSettings />,
    route: Route,
  },
  {
    id: 76,
    path: routes.possettings,
    name: "possettings",
    element: <PosSettings />,
    route: Route,
  },
  {
    id: 78,
    path: routes.emailsettings,
    name: "emailsettings",
    element: <EmailSettings />,
    route: Route,
  },
  {
    id: 79,
    path: routes.smssettings,
    name: "smssettings",
    element: <SmsGateway />,
    route: Route,
  },
  {
    id: 80,
    path: routes.otpsettings,
    name: "otpsettings",
    element: <OtpSettings />,
    route: Route,
  },
  {
    id: 81,
    path: routes.gdbrsettings,
    name: "gdbrsettings",
    element: <GdprSettings />,
    route: Route,
  },
  {
    id: 82,
    path: routes.paymentgateway,
    name: "paymentgateway",
    element: <PaymentGateway />,
    route: Route,
  },
  {
    id: 84,
    path: routes.customers,
    name: "customers",
    element: <Customers />,
    route: Route,
  },
  {
    id: 85,
    path: routes.suppliers,
    name: "suppliers",
    element: <Suppliers />,
    route: Route,
  },
  {
    id: 86,
    path: routes.storelist,
    name: "storelist",
    element: <StoreList />,
    route: Route,
  },
  {
    id: 87,
    path: routes.managestock,
    name: "managestock",
    element: <Managestock />,
    route: Route,
  },
  {
    id: 88,
    path: routes.stockadjustment,
    name: "stockadjustment",
    element: <StockAdjustment />,
    route: Route,
  },
  {
    id: 89,
    path: routes.stocktransfer,
    name: "stocktransfer",
    element: <StockTransfer />,
    route: Route,
  },
  {
    id: 90,
    path: routes.salesreport,
    name: "salesreport",
    element: <SalesReport />,
    route: Route,
  },
  {
    id: 91,
    path: routes.purchasereport,
    name: "purchasereport",
    element: <PurchaseReport />,
    route: Route,
  },
  {
    id: 92,
    path: routes.inventoryreport,
    name: "inventoryreport",
    element: <InventoryReport />,
    route: Route,
  },
  {
    id: 93,
    path: routes.invoicereport,
    name: "invoicereport",
    element: <Invoicereport />,
    route: Route,
  },
  {
    id: 94,
    path: routes.supplierreport,
    name: "supplierreport",
    element: <SupplierReport />,
    route: Route,
  },
  {
    id: 95,
    path: routes.customerreport,
    name: "customerreport",
    element: <CustomerReport />,
    route: Route,
  },
  {
    id: 96,
    path: routes.expensereport,
    name: "expensereport",
    element: <ExpenseReport />,
    route: Route,
  },
  {
    id: 97,
    path: routes.incomereport,
    name: "incomereport",
    element: <IncomeReport />,
    route: Route,
  },
  {
    id: 98,
    path: routes.taxreport,
    name: "taxreport",
    element: <TaxReport />,
    route: Route,
  },
  {
    id: 99,
    path: routes.profitloss,
    name: "profitloss",
    element: <ProfitLoss />,
    route: Route,
  },
  {
    id: 89,
    path: routes.generalsettings,
    name: "generalsettings",
    element: <GeneralSettings />,
    route: Route,
  },
  {
    id: 90,
    path: routes.securitysettings,
    name: "securitysettings",
    element: <SecuritySettings />,
    route: Route,
  },
  {
    id: 91,
    path: routes.notification,
    name: "notification",
    element: <Notification />,
    route: Route,
  },
  {
    id: 92,
    path: routes.connectedapps,
    name: "connectedapps",
    element: <ConnectedApps />,
    route: Route,
  },
  {
    id: 93,
    path: routes.systemsettings,
    name: "systemsettings",
    element: <SystemSettings />,
    route: Route,
  },
  {
    id: 94,
    path: routes.companysettings,
    name: "companysettings",
    element: <CompanySettings />,
    route: Route,
  },
  {
    id: 94,
    path: routes.localizationsettings,
    name: "localizationsettings",
    element: <LocalizationSettings />,
    route: Route,
  },
  {
    id: 95,
    path: routes.prefixes,
    name: "prefixes",
    element: <Prefixes />,
    route: Route,
  },
  {
    id: 96,
    path: routes.preference,
    name: "preference",
    element: <Preference />,
    route: Route,
  },
  {
    id: 97,
    path: routes.banipaddress,
    name: "banipaddress",
    element: <BanIpaddress />,
    route: Route,
  },
  {
    id: 98,
    path: routes.storagesettings,
    name: "storagesettings",
    element: <StorageSettings />,
    route: Route,
  },
  {
    id: 99,
    path: routes.taxrates,
    name: "taxrates",
    element: <TaxRates />,
    route: Route,
  },
  {
    id: 100,
    path: routes.simplepos,
    name: "simplepos",
    element: <SimplePos />,
    route: Route,
  },
  {
    id: 101,
    path: routes.pos,
    name: "pos",
    element: <Pos />,
    route: Route,
  },
  {
    id: 102,
    path: routes.saleslist,
    name: "saleslist",
    element: <SalesList />,
    route: Route,
  },
  {
    id: 103,
    path: routes.invoicereport,
    name: "invoicereport",
    element: <InvoiceReport />,
    route: Route,
  },
  {
    id: 104,
    path: routes.holidays,
    name: "holidays",
    element: <Holidays />,
    route: Route,
  },
  {
    id: 105,
    path: routes.profile,
    name: "profile",
    element: <Profile />,
    route: Route,
  },
  {
    id: 106,
    path: routes.users,
    name: "users",
    element: <Users />,
    route: Route,
  },
  {
    id: 107,
    path: routes.rolespermission,
    name: "rolespermission",
    element: <RolesPermissions />,
    route: Route,
  },
  {
    id: 108,
    path: routes.permissions,
    name: "permissions",
    element: <Permissions />,
    route: Route,
  },
  {
    id: 109,
    path: routes.employeegrid,
    name: "employeegrid",
    element: <EmployeesGrid />,
    route: Route,
  },
  {
    id: 110,
    path: routes.addemployee,
    name: "addemployee",
    element: <AddEmployee />,
    route: Route,
  },
  {
    id: 111,
    path: routes.editemployee,
    name: "editemployee",
    element: <EditEmployee />,
    route: Route,
  },
  {
    id: 112,
    path: routes.leavesemployee,
    name: "leavesemployee",
    element: <LeavesEmployee />,
    route: Route,
  },
  {
    id: 113,
    path: routes.leavestype,
    name: "leavestype",
    element: <LeaveTypes />,
    route: Route,
  },
  {
    id: 114,
    path: routes.productdetails,
    name: "productdetails",
    element: <ProductDetail />,
    route: Route,
  },
  {
    id: 115,
    path: "/",
    name: "Root",
    element: <Navigate to="/" />,
    route: Route,
  },
  {
    id: 116,
    path: "*",
    name: "NotFound",
    element: <Navigate to="/" />,
    route: Route,
  },
  {
    id: 117,
    path: routes.expiredtypes,
    name: "expiredtypes",
    element: <InventoryOutflow />,
    route: Route,
  },
  {
    id: 118,
    path: routes.addcustomer,
    name: "addcustomer",
    element: <AddCustomer />,
    route: Route,
  },
  {
    id: 119,
    path: routes.editcustomer,
    name: "editcustomer",
    element: <EditCustomer />,
    route: Route,
  },
  {
    id: 120,
    path: routes.addsupplier,
    name: "addsupplier",
    element: <AddSupplier />,
    route: Route,
  },
  {
    id: 121,
    path: routes.editsupplier,
    name: "editsupplier",
    element: <EditSupplier />,
    route: Route,
  },
  {
    id: 122,
    path: routes.cashbreak,
    name: "cashbreak",
    element: <CashBreak />,
    route: Route,
  },
  {
    id: 123,
    path: routes.recipebook,
    name: "recipebook",
    element: <RecipeBook />,
    route: Route,
  },
  {
    id: 124,
    path: routes.recipegrid,
    name: "recipegrid",
    element: <RecipeGrid />,
    route: Route,
  },
  {
    id: 125,
    path: routes.recipe,
    name: "recipe",
    element: <Recipe />,
    route: Route,
  },
  {
    id: 126,
    path: routes.signout,
    name: "signout",
    element: <SignOut />,
    route: Route,
  },
  {
    id: 127,
    path: routes.purchaseProductList,
    name: "purchaseProductList",
    element: <PurchaseProductList />,
    route: Route,
  },
  {
    id: 129,
    path: routes.menugrid,
    name: "menugrid",
    element: <MenuGrid />,
    route: Route,
  },
  {
    id: 130,
    path: routes.menu,
    name: "menu",
    element: <Menu />,
    route: Route,
  },
  {
    id: 131,
    path: routes.cashoutflowlist,
    name: "cashoutflowlist",
    element: <CashOutflowList />,
    route: Route,
  },
  {
    id: 132,
    path: routes.purchasedetails,
    name: "purchasedetails",
    element: <PurchaseDetail />,
    route: Route,
  },
  {
    id: 133,
    path: routes.transferdetails,
    name: "transferdetails",
    element: <TransferDetail />,
    route: Route,
  },
  {
    id: 134,
    path: routes.packagegrid,
    name: "packagegrid",
    element: <PackageGrid />,
    route: Route,
  },
  {
    id: 135,
    path: routes.package,
    name: "package",
    element: <PackageDetail />,
    route: Route,
  },
  {
    id: 136,
    path: routes.saleDetails,
    name: "saleDetails",
    element: <SaleDetails />,
    route: Route,
  },
  {
    id: 137,
    path: routes.leaveEmployeeDetails,
    name: "leaveEmployeeDetails",
    element: <LeaveEmployeeDetails />,
    route: Route,
  },
  {
    id: 138,
    path: routes.cashBreakDetails,
    name: "cashBreakDetails",
    element: <CashBreakDetails />,
    route: Route,
  },
];
export const posRoutes = [
  {
    id: 1,
    path: routes.pos,
    name: "pos",
    element: <SimplePos />,
    route: Route,
  },
];

export const pagesRoute = [
  {
    id: 1,
    path: routes.signin,
    name: "signin",
    element: <Signin />,
    route: Route,
  },
  {
    id: 7,
    path: routes.forgotPassword,
    name: "forgotPassword",
    element: <Forgotpassword />,
    route: Route,
  },
  {
    id: 9,
    path: routes.resetpassword,
    name: "resetpassword",
    element: <Resetpassword />,
    route: Route,
  },
  {
    id: 18,
    path: routes.error404,
    name: "error404",
    element: <Error404 />,
    route: Route,
  },
  {
    id: 19,
    path: routes.error500,
    name: "error500",
    element: <Error500 />,
    route: Route,
  },
];
