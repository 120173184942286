import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import {
    ArrowLeft,
    ChevronDown,
    ChevronUp,
    Info,
    List,
    Mail,
    Hash,
    FileText,
    CheckCircle
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setCustomerList, setShowRemoteResponseToast, setToogleHeader } from "../../../core/redux/action";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import PropTypes from 'prop-types';
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import FormCreatedBy from "../createdByForm";
import { useForm } from "react-hook-form";
import { showInternalErrorAlert } from "../customAlert";
import { emailRegex, generarCodigo, phoneRegex, zipRegex } from "../../../core/utils";
import variables from "../../../style/scss/utils/_variables.scss";
import { Client } from "../../../services/internal/client";
import AvatarUpload from "../fileUpload/AvatarUpload";

registerLocale('es', es)

const FormCustomer = ({ isNew = false }) => {
    const { setValue, getValues, register, handleSubmit, reset, formState: { errors } } = useForm();
    const loading = useSelector((state) => state.loading);
    const token = useSelector((state) => state.token);
    const dispatch = useDispatch();
    const [imagePath, setImagePath] = useState("assets/img/customer/default.png");
    const [customerId, setCustomerId] = useState(null);

    const [searchParams] = useSearchParams();
    const paramCustomerId = searchParams.get('code');
    const route = all_routes;

    const data = useSelector((state) => state.toggle_header);

    const renderCollapseTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Colapsar
        </Tooltip>
    );

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure(err.response.data));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const calculateResponse = (isLoad, data) => {

        if (isNew) {
            return Client.postSingle(token, data);
        }

        if (isLoad) {
            return Client.getSingle(token, data.id);
        }

        return Client.putSingle(token, data);
    }

    const onLoadItem = async () => {
        try {
            let data = Client.requestData;
            data = {};
            data.id = paramCustomerId ? Number(paramCustomerId) : customerId;
            if (isNaN(data.id)) {
                return;
            }

            dispatch(fetchDataRequest());

            const response = await calculateResponse(true, data);

            setValue('code', response.data.data.code);
            setValue('name', response.data.data.name);
            setValue('lastName', response.data.data.lastName);
            setValue('seccondLastName', response.data.data.seccondLastName);
            setValue('email', response.data.data.email);
            setValue('phone', response.data.data.phone);
            setValue('address', response.data.data.address);
            setValue('businessName', response.data.data.businessName);
            setValue('taxId', response.data.data.taxId);
            setValue('taxZipCode', response.data.data.taxZipCode);
            if (response.data.data.imagePath) {
                setImagePath(response.data.data.imagePath);
            }
            setCustomerId(response.data.data.id);
            //console.log(response)
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onSaveItem = async (formData) => {
        try {
            let data = Client.requestData;
            data = {};
            data.stateId = 1;
            data.id = customerId;
            data.code = formData.code;
            data.name = formData.name;
            data.lastName = formData.lastName;
            data.seccondLastName = formData.seccondLastName;
            data.email = formData.email;
            data.phone = formData.phone;
            data.address = formData.address;
            data.businessName = formData.businessName;
            data.taxId = formData.taxId;
            data.taxZipCode = formData.taxZipCode;
            data.imagePath = imagePath;

            dispatch(fetchDataRequest());

            const response = await calculateResponse(false, data);
            //console.log(response)
            dispatch(fetchDataSuccess(response.data.success));
            dispatch(setShowRemoteResponseToast(true));
            clearForm();
            dispatch(setCustomerList([]));
        }
        catch (err) {
            handleException(err);
        }
    }

    const clearForm = async () => {
        if (isNew) {
            reset(formValues => ({
                ...formValues,
                code: null,
                name: null,
                lastName: null,
                seccondLastName: null,
                email: null,
                phone: null,
                address: null,
                businessName: null,
                taxId: null,
                taxZipCode: null
            }));
        } else {
            await onLoadItem();
        }
    }

    const onGenerateCode = (e) => {
        e?.preventDefault();
        setValue('code', generarCodigo());
    }

    const onChangedImage = async (newImagePath) => {
        setImagePath(newImagePath);
        try {
            dispatch(fetchDataRequest());

            const data = {
                id: customerId,
                code: getValues("code"),
                name: getValues("name"),
                stateId: 1,
                imagePath: newImagePath,
            }

            const response = await Client.putSingle(token, data);
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    useEffect(() => {
        if (isNew === false && paramCustomerId) {
            onLoadItem();
        }

        const { code } = getValues();
        if (!code) {
            onGenerateCode();
        }
    }, [paramCustomerId]);


    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>{isNew ? "Registrar cliente" : "Actualizar cliente"}</h4>
                            <h6>{isNew ? "Puedes registrar un cliente y su información." : "Puedes actualizar un cliente y su información."}</h6>
                        </div>
                    </div>
                    <ul className="table-top-head">
                        <li>
                            <div className="page-btn">
                                <Link to={route.customers} className="btn btn-secondary">
                                    <ArrowLeft className="me-2" />
                                    Lista de Clientes
                                </Link>
                            </div>
                        </li>
                        <li>
                            <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                                <a
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Colapsar"
                                    id="collapse-header"
                                    className={data ? "active" : ""}
                                    onClick={() => {
                                        dispatch(setToogleHeader(!data));
                                    }}
                                >
                                    <ChevronUp className="feather-chevron-up" />
                                </a>
                            </OverlayTrigger>
                        </li>
                    </ul>
                </div>
                {/* /add */}
                <form onSubmit={handleSubmit((data) => onSaveItem(data))}>
                    <div className="card">
                        <div className="card-body add-product pb-0">
                            <div
                                className="accordion-card-one accordion"
                                id="accordionExample"
                            >
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingOne">
                                        <div
                                            className="accordion-button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-controls="collapseOne"
                                        >
                                            <div className="addproduct-icon">
                                                <h5>
                                                    <Info className="add-info" />

                                                    <span>Información del cliente</span>
                                                </h5>
                                                <a>
                                                    <ChevronDown className="chevron-down-add" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <div className="profile-set">
                                                <div className="profile-head"></div>
                                                <div className="row profile-top">
                                                    <div className="col-lg-2 col-sm-12 col-12 d-flex justify-content-center mb-3">
                                                        <AvatarUpload
                                                            prefix="customer_image"
                                                            imageGrouperId="customers"
                                                            userId={`${customerId}`}
                                                            avatarPath={imagePath}
                                                            onImageUploaded={onChangedImage}
                                                        />
                                                    </div>
                                                    <div className="col-lg-4 col-sm-12 col-12 mb-3">
                                                        <label className="form-label">Código de sistema<span className="text-danger"> *</span></label>
                                                        <div className="input-group">
                                                            <input
                                                                type="text"
                                                                className={!errors.code ? "form-control" : "form-control is-invalid"}
                                                                placeholder="Genera un código"
                                                                {...register("code",
                                                                    {
                                                                        required: "Genera un código por favor.",
                                                                    }
                                                                )}
                                                                disabled
                                                            />
                                                            {
                                                                isNew ?
                                                                    <button
                                                                        className="btn btn-primary"
                                                                        onClick={(e) => onGenerateCode(e)}
                                                                        disabled={loading}
                                                                    >
                                                                        Generar
                                                                    </button>
                                                                    : null
                                                            }
                                                            {
                                                                errors.code ?
                                                                    <div className="invalid-feedback">
                                                                        {errors.code.message}
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4 col-sm-4 col-12 mb-3">
                                                    <label className="form-label">Nombre(s)<span className="text-danger"> *</span></label>
                                                    <input
                                                        type="text"
                                                        className={!errors.name ? "form-control" : "form-control is-invalid"}
                                                        {...register("name",
                                                            {
                                                                required: "Nombre del cliente es requerido"
                                                            }
                                                        )}
                                                        disabled={loading}
                                                        maxLength={300}
                                                    />
                                                    {
                                                        errors.name ?
                                                            <div className="invalid-feedback">
                                                                {errors.name.message}
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <div className="col-lg-4 col-sm-4 col-12 mb-3">
                                                    <label className="form-label">Apellido Paterno</label>
                                                    <input
                                                        type="text"
                                                        className={!errors.lastName ? "form-control" : "form-control is-invalid"}
                                                        {...register("lastName")}
                                                        disabled={loading}
                                                        maxLength={300}
                                                    />
                                                    {
                                                        errors.lastName ?
                                                            <div className="invalid-feedback">
                                                                {errors.lastName.message}
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <div className="col-lg-4 col-sm-4 col-12 mb-3">
                                                    <label className="form-label">Apellido Materno</label>
                                                    <input
                                                        type="text"
                                                        className={!errors.seccondLastName ? "form-control" : "form-control is-invalid"}
                                                        {...register("seccondLastName")}
                                                        disabled={loading}
                                                        maxLength={300}
                                                    />
                                                    {
                                                        errors.seccondLastName ?
                                                            <div className="invalid-feedback">
                                                                {errors.seccondLastName.message}
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="accordion-card-one accordion"
                                id="accordionExample2"
                            >
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingTwo">
                                        <div
                                            className="accordion-button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-controls="collapseTwo"
                                        >
                                            <div className="text-editor add-list">
                                                <div className="addproduct-icon list icon">
                                                    <h5>
                                                        <CheckCircle className="add-info" />
                                                        <span>Información de contacto</span>
                                                    </h5>
                                                    <a>
                                                        <ChevronDown className="chevron-down-add" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        id="collapseTwo"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample2"
                                    >
                                        <div className="accordion-body">
                                            <div className="row">
                                                <div className="col-lg-6 col-sm-6 col-12 mb-3">
                                                    <label className="form-label">Correo electrónico</label>
                                                    <div className="input-group flex-wrap">
                                                        <span className="input-group-text"><Mail size={15} color={variables.primaryColor} /></span>
                                                        <input
                                                            type="text"
                                                            placeholder="Escribe el email"
                                                            className={!errors.email ? "form-control" : "form-control is-invalid"}
                                                            {...register("email",
                                                                {
                                                                    pattern: {
                                                                        value: emailRegex,
                                                                        message: "No tiene estructura de un email válido"
                                                                    },
                                                                }
                                                            )}
                                                            disabled={loading}
                                                            maxLength={300}
                                                        />
                                                        {
                                                            errors.email ?
                                                                <div className="invalid-feedback">
                                                                    {errors.email.message}
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-sm-6 col-12 mb-3">
                                                    <label className="form-label">Teléfono</label>
                                                    <div className="input-group flex-wrap">
                                                        <ImageWithBasePath
                                                            src="assets/img/flags/mx.png"
                                                            alt="img"
                                                            id="mx"
                                                            style={{ height: 40 }}
                                                            className={"input-group-text"}
                                                        />
                                                        <span className="input-group-text">+52</span>
                                                        <input
                                                            type="text"
                                                            className={!errors.phone ? "form-control" : "form-control is-invalid"}
                                                            maxLength={10}
                                                            placeholder="Escribe el teléfono a 10 dígitos"
                                                            {...register("phone", {
                                                                pattern: {
                                                                    value: phoneRegex,
                                                                    message: "No tiene estructura de un teléfono válido"
                                                                },
                                                            })}
                                                            disabled={loading}
                                                        />
                                                        {
                                                            errors.phone ?
                                                                <div className="invalid-feedback">
                                                                    {errors.phone.message}
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12 col-12 mb-3">
                                                    <label className="form-label">Dirección</label>
                                                    <textarea
                                                        maxLength={800}
                                                        rows={3}
                                                        className={!errors.address ? "form-control" : "form-control is-invalid"}
                                                        placeholder="Calle, Numero, Colonia, Localidad, Estado"
                                                        {...register("address")}
                                                        disabled={loading}
                                                    />
                                                    {
                                                        errors.address ?
                                                            <div className="invalid-feedback">
                                                                {errors.address.message}
                                                            </div>
                                                            : null
                                                    }
                                                    <p>Máximo 800 caracteres</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="accordion-card-one accordion"
                                id="accordionExample3"
                            >
                                <div className="accordion-item">
                                    <div className="accordion-header" id="heading3">
                                        <div
                                            className="accordion-button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapse3"
                                            aria-controls="collapse3"
                                        >
                                            <div className="text-editor add-list">
                                                <div className="addproduct-icon list icon">
                                                    <h5>
                                                        <FileText className="add-info" />
                                                        <span>Información de facturación</span>
                                                    </h5>
                                                    <a>
                                                        <ChevronDown className="chevron-down-add" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        id="collapse3"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="heading3"
                                        data-bs-parent="#accordionExample2"
                                    >
                                        <div className="accordion-body">
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12 col-12 mb-3">
                                                    <label className="form-label">Razón social</label>
                                                    <input
                                                        type="text"
                                                        className={!errors.businessName ? "form-control" : "form-control is-invalid"}
                                                        maxLength={300}
                                                        {...register("businessName")}
                                                        disabled={loading}
                                                    />
                                                    {
                                                        errors.businessName ?
                                                            <div className="invalid-feedback">
                                                                {errors.businessName.message}
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <div className="col-lg-6 col-sm-6 col-12">
                                                    <div className="mb-3">
                                                        <label className="form-label">RFC</label>
                                                        <input
                                                            type="text"
                                                            className={!errors.taxId ? "form-control" : "form-control is-invalid"}
                                                            maxLength={300}
                                                            {...register("taxId")}
                                                            disabled={loading}
                                                        />
                                                        {
                                                            errors.taxId ?
                                                                <div className="invalid-feedback">
                                                                    {errors.taxId.message}
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-sm-6 col-12 mb-3">
                                                    <label className="form-label">Código Postal</label>
                                                    <div className="input-group flex-wrap">
                                                        <span className="input-group-text"><Hash size={15} color={variables.primaryColor} /></span>
                                                        <input
                                                            type="text"
                                                            placeholder="Escribe el C.P."
                                                            className={!errors.taxZipCode ? "form-control" : "form-control is-invalid"}
                                                            maxLength={5}
                                                            {...register("taxZipCode",
                                                                {
                                                                    pattern: {
                                                                        value: zipRegex,
                                                                        message: "No es un código postal con estructura válido."
                                                                    }
                                                                }
                                                            )}
                                                            disabled={loading}
                                                        />
                                                        {
                                                            errors.taxZipCode ?
                                                                <div className="invalid-feedback">
                                                                    {errors.taxZipCode.message}
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="accordion-card-one accordion"
                                id="accordionExample4"
                            >
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingFour">
                                        <div
                                            className="accordion-button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour"
                                            aria-controls="collapseFour"
                                        >
                                            <div className="text-editor add-list">
                                                <div className="addproduct-icon list">
                                                    <h5>
                                                        <List className="add-info" />
                                                        <span>Otros datos</span>
                                                    </h5>
                                                    <a>
                                                        <ChevronDown className="chevron-down-add" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        id="collapseFour"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingFour"
                                        data-bs-parent="#accordionExample4"
                                    >
                                        <div className="accordion-body">
                                            <FormCreatedBy isNew={isNew} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="d-flex justify-content-center mb-4">
                            {
                                loading ?
                                    <button disabled={loading} className="btn btn-submit">
                                        <Spinner animation="border" role="status" size="sm" />
                                    </button>
                                    :
                                    <input type="submit" value={isNew ? "Registrar" : "Actualizar"} className="btn btn-submit" />
                            }
                        </div>
                    </div>
                </form>
                {/* /add */}
            </div>
        </div>
    );
};

FormCustomer.propTypes = {
    isNew: PropTypes.bool
};

export default FormCustomer;
