import React, { useEffect, useState } from 'react'
import ImageWithBasePath from '../../core/img/imagewithbasebath';
import { HelpCircle, PlusCircle, Users } from 'feather-icons-react/build/IconComponents';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import ToolsHead from '../components/toolsHead';
import AddCatalog from '../../core/modals/inventory/addcatalog';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Table } from 'antd';
import { Edit, Trash2 } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setDesignationList, setShowRemoteResponseToast } from '../../core/redux/action';
import { Designations } from '../../services/internal/designations';
import { showInternalErrorAlert, showSuccessAlert } from '../components/customAlert';
import { calculateSum } from '../../core/utils';

const Designation = () => {
  const dataSource = useSelector((state) => state.designationList);
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading);
  const token = useSelector((state) => state.token);
  const [filterName, setFilterName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [newItem, setNewItem] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  const renderSearchTooltip = (props) => (
    <Tooltip id="search-tooltip" {...props}>
      Ingresa el nombre del puesto
    </Tooltip>
  );

  const onChangeName = (e) => {
    setFilterName(e.target.value.trim())
  }

  const handleException = (err) => {
    console.log(err);
    if (err.response) {
      dispatch(fetchDataFailure(err.response.data));
      if (err.response.status >= 400 && err.response.status < 500) {
        dispatch(fetchDataErrors(err.response.data.errors));
        dispatch(setShowRemoteResponseToast(true));
        return;
      } else {
        showInternalErrorAlert(false, err.response.statusText);
        return;
      }
    }
    dispatch(fetchDataFailure(err));
    showInternalErrorAlert(true, err.code);
  }

  const onLoadDesignation = async () => {

    let filters = {};
    filters.enabled = true;
    if (filterName) {
      filters.name = filterName;
    }

    try {
      dispatch(fetchDataRequest());
      const response = await Designations.getList(token, filters);
      //console.log(response)
      dispatch(setDesignationList(response.data.data));
      dispatch(fetchDataSuccess(response.data.success));
    }
    catch (err) {
      handleException(err);
    }
  }

  const onDeleteItem = async (id) => {
    try {
      dispatch(fetchDataRequest());
      const response = await Designations.deleteSingle(token, id);
      //console.log(response)
      dispatch(fetchDataSuccess(response.data.success));
      showSuccessAlert("Hemos eliminado el puesto.");
    }
    catch (err) {
      handleException(err);
    }
    await onLoadDesignation();
  }

  const showDeleteItemAlert = async (index, text) => {
    const result = await MySwal.fire({
      title: "¿Estás seguro?",
      html: `Vamos a eliminar ${text}, esta acción es <strong>irreversible</strong>.`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
      customClass: {
        confirmButton: "btn btn-warning",
      },
    });

    if (result.isConfirmed) {
      await onDeleteItem(index.id);
    } else {
      MySwal.close();
    }
  };

  const onNewItem = () => {
    setCurrentId(null);
    setNewItem(true);
    setShowModal(true);
  }

  const onEditItem = (index) => {
    setCurrentId(index.id);
    setNewItem(false);
    setShowModal(true);
  }

  const onHideModal = async (refresh) => {
    setCurrentId(null);
    setShowModal(false);
    if (refresh) {
      await onLoadDesignation();
    }
  }

  useEffect(() => {
    if (dataSource.length === 0) {
      onLoadDesignation();
    }
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id
    },
    {
      title: "Puesto",
      dataIndex: "name",
      render: (text) => (
        <span className="text-secondary fw-semibold">{text}</span>
      ),
      sorter: (a, b) => a.name?.localeCompare(b.name)
    },
    {
      title: "Empleados",
      dataIndex: "usersCount",
      render: (text, record) => (
        <ul key={record.id} className="team-members">
          <li >
            <ul>
              {
                [1].map((i) =>
                (<li key={`avatar-${i}`}>
                  <a>
                    <ImageWithBasePath src={i.image ? i.image : "assets/img/profiles/profile.png"} alt="avatar" />
                  </a>
                </li>)
                )
              }
              {
                record.usersCount > 5 ?
                  (<li>
                    <a>
                      <span>+{record.usersCount - 5}</span>
                    </a>
                  </li>)
                  : null
              }
            </ul>
          </li>
        </ul>
      )
    },
    {
      title: "Cantidad de Empleados",
      dataIndex: "usersCount",
      align: "center",
      render: (text) => (
        <span className="badge badges-info">{text}</span>
      ),
      sorter: (a, b) => a.usersCount - b.usersCount
    },
    {
      title: "Acción",
      align: "center",
      dataIndex: "actions",
      key: "actions",
      render: (record, index) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <a className="me-2 p-2" onClick={() => onEditItem(index)}>
              <Edit className="feather-edit" />
            </a>
            <a className="confirm-text p-2" onClick={() => showDeleteItemAlert(index, "un puesto")} >
              <Trash2 className="feather-trash-2" />
            </a>
          </div>
        </div>
      )
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Puestos</h4>
              <h6>Puedes gestionar la información de los puestos registrados en el sistema.</h6>
            </div>
          </div>
          <ToolsHead showExportData={true} showExcel={true} onReload={onLoadDesignation} />
          <div className="page-btn">
            <a
              className="btn btn-added"
              onClick={onNewItem}
            >
              <PlusCircle className="me-2" />
              Nuevo Puesto
            </a>
          </div>
        </div>
        {/* /product list */}
        <div className="card">
          <div className="card-body pb-0">
            <div className="table-top table-top-new">
              <div className="search-set mb-0">
                <div className="total-employees">
                  <h6>
                    <Users />
                    Total de Empleados <span>{calculateSum(dataSource, "usersCount")}</span>
                  </h6>
                </div>
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Búsqueda rápida"
                    className="form-control form-control-sm formsearch"
                    onChange={e => onChangeName(e)}
                    onBlur={onLoadDesignation}
                    disabled={loading}
                  />
                  <a className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </a>
                </div>
                <OverlayTrigger placement="top" overlay={renderSearchTooltip}>
                  <a className="link-primary">
                    <HelpCircle />
                  </a>
                </OverlayTrigger>
              </div>
            </div>
            <div className="table-responsive">
              <Table
                className="table"
                rowKey={(record) => record?.id}
                columns={columns}
                loading={loading}
                dataSource={dataSource}
                size="small"
                pagination={
                  {
                    position: ["topRight"],
                    total: dataSource.length,
                    showTotal: (total) => `Total: ${total}`,
                    showSizeChanger: true
                  }
                }
              />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
      {newItem
        ?
        showModal
          ? <AddCatalog id="add-designation" show={showModal} onHide={onHideModal} type='designation' isNew={true} />
          : null
        :
        showModal
          ? <AddCatalog id="edit-designation" show={showModal} onHide={onHideModal} type='designation' currentId={currentId} />
          : null
      }
    </div>
  )
}

export default Designation

